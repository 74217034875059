@import '../../scss/mixins';
@import '../../scss/variables';

#TabsAndGridCui {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding: 56px 20px;
  text-align: center;

  @include mobile {
    padding: 0 20px 56px;
  }

  .cui-link {
    margin-top: 46px;
  }

  .container-cui-row {
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    margin-bottom: 36px;

    @include mobile {
      max-width: 100%;
    }
  }

  .skeletonRow {
    background: url(/assets/images/our-menu/circle-transparent.gif) no-repeat
      center center;
    background-size: 300px;
    width: 100%;
    height: 440px;
  }

  .containerGrid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    min-height: 440px;
    max-width: 1400px;

    @media (min-width: 768px) and (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1024px) and (max-width: 1030px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include mobile {
      grid-template-columns: 1fr;
      touch-action: pan-y pan-x pinch-zoom;
    }
  }

  .with-cursor {
    .tabListElement {
      cursor: pointer;
    }
  }

  .tabList {
    display: flex;
    gap: 57px;
    padding: 0;
    margin: 0;

    @include mobile {
      margin-top: 0px;
      gap: 10px;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
    }

    .tabListElement {
      list-style: none;
      height: 32px;
      margin: 0;
      color: $cookunity-gray-2;
      text-transform: uppercase;

      @include mobile {
        font-size: 14px;
        line-height: 20px;
        height: unset;
        text-align: center;
      }
    }

    .tabListElement:last-child {
      @include mobile {
        margin-right: 0px;
      }
    }

    .selected {
      font-weight: bold;
      border-bottom: 3px solid $cookunity-black;
      color: $cookunity-black;
    }
  }

  &.with-tabs {
    padding: 0 !important;

    .containerGrid {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .mealcard-new {
        min-height: 390px;
      }
    }

    .container-cui-row {
      margin-bottom: 0;
    }
    .tab-list {
      list-style: none;
      width: 100%;
      margin: 0 0 34px;
      padding-left: 20px;

      @include mobile {
        margin: 0;
      }

      .slick-slider {
        width: 100%;
      }

      .slick-track {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
      }

      .tab-list-element {
        cursor: pointer;
        padding: 14px 24px;
        background-color: $cui-black-color-100;
        border-radius: 200px;
        color: $cui-black-color-400;
        text-align: center;
        font-family: $cui-font-family-tt-norms;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        text-transform: uppercase;

        &.selected {
          border: 1px solid $cui-black-color;
          background-color: $cookunity-white;
        }
      }
    }
  }

  .meals-slider-loader-container {
    margin: 0 auto;
    height: 500px;
    display: flex;
    align-items: center;

    .circular-loader {
      margin: 200px auto;
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.price-plan-info-box {
  background: $cui-black-color-250;
  padding: 96px 0;
  .price-plan-info-box-container {
    max-width: 842px;
    margin: 0 auto;

    p,
    h3 {
      font-family: $cui-font-family-tt-norms;
      font-style: normal;
    }

    p {
      font-weight: 500;
      line-height: 150%;
    }
    .pre-title {
      color: $cui-black-color-600;
      text-align: center;
      font-size: 16px;
      letter-spacing: -0.32px;
      margin: 0px;
    }

    h2 {
      color: $cui-black-color-850;
      text-align: center;
      font-family: $cui-font-family-rossanova;
      font-size: 64px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: -1.92px;
      margin: 20px 0 64px;

      @include mobile {
        font-size: 32px;
        letter-spacing: -0.96px;
        width: 90%;
        margin: 20px auto 40px;
      }
    }

    h3 {
      font-size: 24px;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: -0.48px;
      text-align: left;

      @include mobile {
        font-size: 20px;
        letter-spacing: -0.4px;
      }
    }

    .boxes-container {
      display: flex;

      @include mobile {
        flex-direction: column-reverse;
        max-width: 90%;
        margin: 0 auto;
      }

      .content-wrapper {
        display: flex;
        padding: 46px 56px 24px 40px;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 0 0;
        border-radius: 16px 0 0 16px;
        border-top: 1px solid $cui-black-color-150;
        border-left: 1px solid $cui-black-color-150;
        border-bottom: 1px solid $cui-black-color-150;
        background: $cui-black-color-100;

        @include mobile {
          border-radius: 0px 0px 16px 16px;
          border-right: 1px solid $cui-black-color-150;
          border-left: 1px solid $cui-black-color-150;
          border-bottom: 1px solid $cui-black-color-150;
          background: $cui-black-color-100;
          padding: 48px 56px 24px 40px;
        }

        ul {
          padding: 4px 0px 0px 0px;
          margin: 0;

          @include mobile {
            padding-top: 24px;
            margin: 0 auto;
          }
          li {
            list-style: none;
            display: flex;
            align-items: center;
            margin-top: 20px;
            gap: 16px;

            @include mobile {
              flex-direction: column;
              width: 80%;
              text-align: center;
              margin: 0 auto;
              gap: 8px;
              margin-bottom: 32px;
            }

            .image-container {
              max-width: 48px;
              height: 48px;
              width: 100%;
              display: flex;
              justify-content: center;
            }

            p {
              margin: 0;
            }
          }
        }

        h3 {
          color: rgba(0, 0, 0, 0.6);
          margin: 0 0 8px;

          @include mobile {
            margin: 8px 0px;
            text-align: center;
            width: 100%;
          }
        }

        .info-img {
          display: flex;
        }
      }

      .content-info {
        width: 400px;
        height: 572px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 16px;
        border: 1px solid $cui-black-color-150;
        background: $cookunity-white;
        box-shadow: 77px 85px 32px 0px rgba(0, 0, 0, 0),
          49px 54px 29px 0px rgba(0, 0, 0, 0.01),
          28px 30px 25px 0px rgba(0, 0, 0, 0.02),
          12px 14px 18px 0px rgba(0, 0, 0, 0.03),
          3px 3px 10px 0px rgba(0, 0, 0, 0.04);
        margin-left: -24px;
        position: relative;

        @include mobile {
          margin: 0 auto -24px;
          width: 100%;
          z-index: 2;
          height: 100%;
        }

        .info-price {
          padding: 46px 32px 0;
        }

        .green-tag {
          margin: 10px 0 0;
          width: 100%;
          display: flex;
          justify-content: center;
          bottom: 0;
          height: 28px;
          border-radius: 8px;
        }

        h3 {
          color: $cui-black-color-850;
          margin: 0 0 16px;
        }

        .price-p {
          margin: 0 0 32px;
          color: rgba(0, 0, 0, 0.6);
          font-size: 16px;
          font-weight: 450;
          letter-spacing: -0.32px;

          @include mobile {
            font-size: 14px;
            letter-spacing: -0.28px;
          }
        }

        .pre-title {
          color: $cui-black-color-850;
          font-size: 12px;
          letter-spacing: -0.12px;
          margin: 0 0 8px;
          text-align: left;
        }

        p.price-box {
          font-size: 32px;
          font-weight: 600;
          line-height: 130%;
          letter-spacing: -0.64px;
          margin: 0;

          span {
            color: $cui-olive-green-color-500;
            margin-left: 8px;
          }

          span.line {
            text-decoration-line: line-through;
            color: $cui-black-color-300;
          }
        }

        .info-bottom {
          padding: 113px 32px 64px;

          @include mobile {
            padding: 32px 32px 48px;
          }

          .title-bottom {
            margin: 12px 0 8px;
          }

          .info-promo {
            margin: 8px 0 20px;
            text-align: left;
            color: rgba(0, 0, 0, 0.6);
            font-size: 14px;
            font-weight: 450;
            letter-spacing: -0.14px;

            @include mobile {
              margin: 0 0 12px;
            }
          }
        }
      }
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

@keyframes mealPulse {
  0% {
    transform: scale(0);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

#browseOurMenuCUI {
  // ligth theme

  &:not(.isBlackMode) {
    background-color: $cookunity-white;
    color: $cui-black-color-600;

    .categoriesContainer ul li {
      color: $cui-black-color-600;
    }

    .slideContent {
      .chefCard {
        background: $cookunity-white;
        color: $cui-black-color-600;
        box-shadow: 0px 4px 56px rgba($cui-black-color-600, 0.25);

        p.rebrand,
        p.avallon {
          color: $cui-black-color-600;
        }
        .meal {
          .mealName {
            color: $cui-black-color-600;
          }
        }
      }
      .meal {
        .chefName {
          color: $cui-black-color-600;
        }
        .mealName {
          color: $cui-black-color-600;
        }
      }
    }
    .slick-dots li button:before {
      color: $cui-black-color-600;
    }
  }

  // end ligth theme

  margin: 0px;
  background-color: $cui-black-color-600;
  color: $cookunity-white;
  overflow: hidden;

  h2.rebrand {
    margin: 68px auto 30px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;

    @include mobile {
      font-size: 36px;
      line-height: 40px;
    }
  }

  .categoriesContainer {
    padding: 15px;

    ul {
      list-style: none;
      padding: 0;
      display: flex;
      align-items: center;
      width: 710px;
      justify-content: space-evenly;
      margin: 0 auto;
      gap: 10px;

      li {
        text-align: center;
        text-transform: uppercase;
        color: $cookunity-white;
        padding-bottom: 10px;
        width: 22%;

        &.active {
          border-bottom: 1px solid;
        }

        a {
          text-decoration: none;
        }
      }
    }
  }

  .slideContent {
    margin: 60px 0;
    position: relative;

    .chefName {
      text-align: center;
      color: $cookunity-white;
      margin: 10px 0;
    }

    .meal {
      text-align: center;
      position: relative;

      .mealImage {
        width: 400px;
        height: 400px;
        border-radius: 100%;
        background: linear-gradient(
          133deg,
          $cui-wine-color-500 -103.84%,
          $cui-black-color 88.11%
        );
        display: inline-block;
        margin: 20px 0;

        .mealImageContainer {
          padding: 34px;
          border-radius: 100%;
          width: 100%;
          height: 100%;
          object-fit: contain;
          transform: scale(1.1);
        }
      }

      .mealName {
        color: $cookunity-white;
        max-width: 300px;
        margin: 0 auto;
      }

      .circles {
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translateX(-50px);
        z-index: 1;
      }
    }

    .chefCard {
      position: absolute;
      left: 50%;
      top: 0px;
      width: 310px;
      padding: 20px;
      transform: translateX(-570px);
      max-width: 360px;

      .chefName {
        display: none;
      }

      .chefImage {
        border-radius: 6px;
        margin: 18px 0;
        overflow: hidden;
        display: flex;
      }

      p.rebrand {
        color: $cookunity-white;
      }

      h3 {
        margin-bottom: 50px;
      }

      .line {
        width: 500px;
        height: 1px;
        background-color: $cui-yellow-color-600;
        position: absolute;
        top: 60px;
      }
    }
  }

  .ctaContainer {
    text-align: center;
    margin: 60px 0 100px 0;
  }

  ul.slick-dots {
    bottom: 20px;
    position: relative;
  }

  .slick-dots li {
    margin: 0;
  }

  .slick-dots li button:before {
    color: $cookunity-white;
    font-size: 36px;
  }

  .slick-dots li.slick-active button:before {
    color: $cui-yellow-color-600;
    opacity: 0.75;
    font-size: 40px;
  }

  .slick-prev {
    left: 400px;
    top: auto;
    bottom: 78px;
    width: 39px;
    height: 39px;
    z-index: 1;
  }

  .slick-next {
    right: 399px;
    top: auto;
    bottom: 78px;
    width: 39px;
    height: 39px;
    z-index: 1;
  }

  .slick-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  @media (max-width: 1136px) {
    background-image: none;

    .categoriesContainer {
      ul {
        width: 100%;

        li {
          font-size: 12px;
          width: unset;
        }
      }
    }

    h2.decoration {
      width: 150px;
      font-size: 30px;
      margin: 44px auto;

      &:before,
      &:after {
        height: 60px;
      }
    }

    .slick-track {
      width: 19200px !important;
    }

    .slideContent {
      margin-top: 30px;
      width: 250px;

      @media (min-width: 1025px) and (max-width: 1366px) {
        width: 216px;
      }

      @media (min-width: 921px) and (max-width: 1024px) {
        width: 190px;
        overflow: hidden;
      }

      @media (min-width: 768px) and (max-width: 920px) {
        width: 174px;
      }

      @include mobile {
        width: 100%;
        padding: 0;
        margin: 0 0 20px 0;
      }

      .meal {
        width: 100%;

        @media (max-width: 350px) {
          width: 200px;
        }

        .mealImage {
          width: 283px;
          height: 283px;

          @media (max-width: 350px) {
            width: 200px;
            height: 200px;
          }

          .mealImageContainer {
            padding: 9px;
            width: 100%;
            transform: scale(0.97);
            height: 100%;
            border-radius: 100%;
          }

          img {
            border-radius: 100%;
            transform: scale(0.97);
          }
        }
      }

      .chefCard {
        display: none;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 0px;
        width: 100%;
        padding: 20px;
        transform: translateX(-50%);
        background: $cookunity-white;
        color: $cui-black-color;
        box-shadow: 0px 4px 56px rgba($cui-black-color, 0.25);
        border-radius: 12px;
        padding-top: 24px;

        @media (max-width: 350px) {
          padding: 10px;
        }

        &.show {
          display: block;
          z-index: 2;

          h3 {
            margin: 0;
          }

          p.rebrand,
          p.avallon {
            color: $cui-black-color;
            margin: 6px 0px;
          }

          .chefName {
            font-size: 28px;
            line-height: unset;
          }
        }

        .line {
          display: none;
        }

        h3 {
          margin-bottom: 20px;
        }

        .chefName {
          display: block;
          font-size: 40px;
          text-align: center;
          width: 100%;
          margin: 0;
        }

        .meal {
          text-align: center;
          position: relative;

          .mealImage {
            width: 400px;
            height: 400px;
            border-radius: 100%;
            background: linear-gradient(
              133deg,
              $cui-wine-color-500 -103.84%,
              $cui-black-color-600 88.11%
            );
            display: inline-block;
            margin: 20px 0;

            .mealImageContainer {
              padding: 34px;
              border-radius: 100%;
              width: 100%;
              height: 100%;
              object-fit: contain;
              transform: scale(1.1);
            }
          }

          .mealName {
            color: $cookunity-white;
            max-width: 300px;
            margin: 0 auto;
          }

          .circles {
            position: absolute;
            top: 40px;
            left: 50%;
            transform: translateX(-50px);
            z-index: 1;
          }
        }

        .chefCard {
          position: absolute;
          left: 50%;
          top: 0px;
          width: 310px;
          padding: 20px;
          transform: translateX(-570px);
          max-width: 360px;

          .chefName {
            display: none;
          }

          .chefImage {
            border-radius: 6px;
            margin: 18px 0;
            overflow: hidden;
            display: flex;
          }

          p.rebrand {
            color: $cookunity-white;
          }

          h3 {
            margin-bottom: 50px;
          }

          .line {
            width: 500px;
            height: 1px;
            background-color: $cui-yellow-color-600;
            position: absolute;
            top: 60px;
          }
        }
      }

      .ctaContainer {
        text-align: center;
        margin: 60px 0 100px 0;

        .button {
          padding: 15px 40px;
          border-radius: 50px;
          font-size: 16px;
          font-weight: 300;
          font-family: $cui-font-family-tt-norms;
          text-decoration: none;
        }
      }

      ul.slick-dots {
        bottom: 20px;
        position: relative;
      }

      .slick-dots li {
        margin: 0;
      }

      .slick-dots li button:before {
        color: $cookunity-white;
        font-size: 36px;
      }

      .slick-dots li.slick-active button:before {
        color: $cui-yellow-color-600;
        opacity: 0.75;
        font-size: 40px;
      }

      .slick-prev {
        left: 400px;
        top: auto;
        bottom: 78px;
        width: 39px;
        height: 39px;
        z-index: 1;
      }

      .slick-prev,
      .slick-next {
        display: none !important;
      }
    }
  }
}

@import '../../../scss/variables.scss';
@import '../../../scss/frontLib/mixins/tags.scss';

.cui-tag {
  appearance: none;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-family: $cui-font-family-tt-norms;
  font-weight: $cui-font-bold;
  font-size: $cui-font-base;
  line-height: $cui-line-height-xl;

  // Sizes
  &-xsmall {
    font-size: $cui-font-tag-xsmall;
    padding: $cui-space-tag-xsmall $cui-space-tag-small;
  }
  &-small {
    font-size: $cui-font-tag-small;
    padding: $cui-space-tag-small $cui-space-tag-large;
  }
  &-medium {
    font-size: $cui-font-tag-medium;
    padding: $cui-space-tag-medium $cui-space-tag-large;
  }
  &-large {
    font-size: $cui-font-tag-large;
    padding: $cui-space-tag-large;
  }

  // Colors
  &-white {
    &-solid {
      @include build-tag(
        // default
        $cookunity-white,
        $cui-black-color-150,
        $cui-black-color
      );
    }
  }

  &-wine {
    &-solid {
      @include build-tag(
        // default
        $cui-wine-color-500,
        $cui-wine-color-500,
        $cookunity-white
      );
    }
  }

  &-olive {
    &-solid {
      @include build-tag(
        // default
        $cui-olive-green-color-500,
        $cui-olive-green-color-500,
        $cookunity-white
      );
    }
  }

  &-dark {
    &-solid {
      @include build-tag(
        // default
        $cui-black-color,
        $cui-black-color,
        $cookunity-white
      );
    }
  }

  &-grey {
    &-solid {
      @include build-tag(
        // default
        $cui-black-color-350,
        $cui-black-color-350,
        $cookunity-white
      );
    }
  }

  &-yellow {
    &-solid {
      @include build-tag(
        // default
        $cui-yellow-color-500,
        $cui-yellow-color-500,
        $cui-black-color
      );
    }
  }

  &-red {
    &-solid {
      @include build-tag(
        // default
        $cookunity-red,
        $cookunity-red,
        $cookunity-white
      );
    }
  }

  &-blue {
    &-solid {
      @include build-tag(
        // default
        $cui-light-blue-700,
        $cui-light-blue-700,
        $cookunity-white
      );
    }
  }

  .cui-icon {
    display: flex;
  }
}

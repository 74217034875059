@import '../../scss/mixins';
@import '../../scss/variables';

.meals-slider-cui {
  min-height: 380px;
  max-width: 1080px;
  margin: 0 auto 50px;

  &__black-mode {
    background-color: $cui-black-color-600;
    max-width: 1130px;

    ul.slick-dots {
      bottom: 20px;
      position: relative;
    }

    .slick-dots li {
      margin: 0;
    }

    .slick-dots li button:before {
      color: $cookunity-white;
      font-size: 36px;
    }

    .slick-dots li.slick-active button:before {
      color: $cui-yellow-color-600;
      opacity: 0.75;
      font-size: 40px;
    }

    .container-button-bottom {
      margin-top: 16px;
    }

    .slick-list {
      padding: 0 0 36px 0 !important;
    }

    .cui-color-dark {
      @include mobile {
        max-width: 90%;
        margin: 0 auto;
      }
    }

    .slick-prev,
    .slick-next {
      width: 48px;
      height: 48px;
      border-radius: 200px;
      background: $cookunity-white;
      opacity: 0.75;
      border: $cookunity-white;

      @include mobile {
        display: none;
      }
    }

    .slick-arrow {
      display: flex;
      justify-content: center;
      align-items: center;

      &.slick-prev:before,
      &.slick-next:before {
        content: '';
      }

      &.slick-next {
        img {
          transform: rotate(180deg);
        }
      }

      &:hover {
        opacity: 1;
        color: $cookunity-white;
        background-color: $cookunity-white;
      }

      &.slick-next:focus,
      &.slick-next:hover,
      &.slick-prev:focus,
      &.slick-prev:hover {
        opacity: 1;
        color: $cookunity-white;
        background-color: $cookunity-white;
      }

      &.slick-disabled {
        display: none;
      }
    }

    .mealcard-new {
      background: $cookunity-white;
    }
  }

  .slick-slider {
    touch-action: pan-x pan-y;
  }

  .slick-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    opacity: 0.75;
    z-index: 11;
    border-radius: 200px;
    transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;

    &.slick-prev:before,
    &.slick-next:before {
      content: '';
    }

    &.slick-next {
      img {
        transform: rotate(180deg);
      }
    }

    &:hover {
      opacity: 0.9;
      background-color: $cui-black-color-150;
    }
  }

  .meals-slider-loader-container {
    width: 100%;
    height: 476px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .meal-card {
    cursor: pointer;
  }

  .slick-dots {
    bottom: -20px;
  }

  .slick-dots li.slick-active button:before {
    color: $cui-yellow-color-600;
  }

  .slick-dots li {
    margin: 0 0;
  }

  .slick-slide {
    padding: 0 15px;

    > div > div > div:nth-child(2) > div:nth-child(2) {
      line-height: 1.3;
    }
  }

  .slick-track {
    width: 20060px !important;
  }

  .slick-list {
    padding: 20px 0;
  }

  .container-button-bottom {
    .cui-link {
      padding: 12px 32px;
      border: none;
    }
  }

  &.green-lp-slider {
    ul.slick-dots {
      @include mobile {
        display: none !important;
      }
    }
  }
}

.meals-slider-cui .slick-slide {
  padding: 0 8px;

  .cui-card {
    .cui-meal-image {
      img {
        height: 190px;
      }
    }

    .cui-meal-description {
      height: 74px;
      -webkit-box-orient: vertical !important;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 3;
    }

    .cui-item {
      height: 54px;
    }
  }
}

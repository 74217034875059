@import '../../scss/mixins';
@import '../../scss/variables';

.chefs-cards-slider {
  padding: 0;
  p.pre-title {
    color: $cui-black-color-100;
    text-align: center;
    font-family: $cui-font-family-tt-norms;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.32px;
    margin: 0;
  }
  h2 {
    color: $cui-black-color-100;
    font-family: $cui-font-family-rossanova;
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -1.92px;
    margin: 20px auto 32px;
    max-width: 620px;
    width: 100%;
    text-align: center;

    @include mobile {
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: -0.96px;
    }
    span {
      color: $cui-yellow-color-500;
    }
  }

  p.text {
    max-width: 620px;
    width: 100%;
    margin: 0 auto;
    color: $cui-black-color-300;
    text-align: center;
    font-family: $cui-font-family-tt-norms;
    font-size: 18px;
    font-style: normal;
    font-weight: 450;
    line-height: 150%;
    letter-spacing: -0.18px;
  }

  .cta-container {
    width: 100%;

    a {
      width: fit-content;
      margin: 32px auto 0;
    }
  }
}

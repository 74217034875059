@import '../../../../../scss/variables';

.input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 8px;

  label {
    font-family: $cui-font-family-tt-norms;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    color: $cookunity-black;
    text-align: left;
    margin-bottom: 8px;
  }

  input,
  textarea {
    width: 100%;
    padding: 12px 34px;
    border: 1px solid $cui-black-color-350;
    font-family: $cui-font-family-tt-norms;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    &:invalid {
      border-color: $cookunity-red;

      &:invalid {
        border-color: $cookunity-red;
      }
    }
  }

  input {
    height: 44px;
    border-radius: 200px;
  }

  input:invalid {
    input {
      height: 200px;
    }
  }

  textarea {
    height: 130px;
    resize: none;
    font-family: $cui-font-family-tt-norms;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
    color: $cui-black-color-400;
    padding-top: 15px;
    border-radius: 20px;
  }

  .bottom-label {
    height: 14px;
    margin-top: 4px;
    font-family: $cui-font-family-tt-norms;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
  }

  &.with-error {
    input,
    textarea {
      border-color: $cookunity-red;
    }

    .bottom-label {
      color: $cookunity-red;
    }
  }
}

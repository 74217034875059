@import '../../scss/mixins';
@import '../../scss/variables';

#giftCardBuying {
  .gift-cards-hero {
    &--title {
      font-size: 60px;
      line-height: 68px;
      font-weight: 700;
      font-family: $cui-font-family-rossanova;
      display: grid;

      @include mobile {
        font-size: 32px;
        line-height: 44px;
      }

      span {
        font-size: 93px;
        font-family: $cui-font-family-avallon;
        line-height: 68px;

        @include mobile {
          font-size: 50px;
          line-height: 44px;
        }
      }
    }

    &--text {
      width: 349px;
      margin-top: 8px;
      margin-bottom: 28px;
      font-size: 16px;
      line-height: 24px;

      @include mobile {
        margin-bottom: 32px;
      }
    }

    &--button {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      font-family: $cui-font-family-tt-norms;
    }
  }
}

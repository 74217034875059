@import '../../scss/mixins';
@import '../../scss/variables';

#form-chef {
  display: flex;
  justify-content: center;
  padding: 68px 0 93px;
  background: $cui-wine-color-500;

  .container-cui {
    text-align: left;
    padding: 0;
    max-width: 1180px;

    @media (max-width: 1200px) {
      width: 100%;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
    }

    h3,
    p {
      color: $cookunity-white;
    }

    h2 {
      margin: 0 0 16px;
    }

    form {
      display: flex;
      width: 100%;
      align-items: flex-end;
      justify-content: center;
      margin-top: 42px;
      gap: 41px;

      @media (max-width: 920px) {
        margin: 42px auto 0;
        flex-direction: column;
        align-self: center;
      }

      input.rebrand,
      button {
        width: 366px;

        @media (max-width: 920px) {
          width: 100%;
        }
      }
    }
  }
}

@import '../../scss/variables';

.rating-meal-container {
  border-radius: 4px;
  border: 1px solid $cui-black-color-150;
  padding: 8px;
  display: flex;
  align-items: center;
  width: fit-content;
  height: 32px;
  background-color: $cookunity-white;

  img {
    width: 16px;
    height: 16px;
    margin-right: 3px;
  }

  p.rating {
    color: $cui-black-color;
    font-family: $cui-font-family-tt-norms;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: unset;
    margin: 0 1px;
  }
}

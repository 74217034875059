@import '../../../scss/variables';

.navbar-menu-video {
  height: auto;
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 81px;
  border-radius: 0px 0px 32px 32px;
  background: $cui-black-color-50;
  overflow-y: auto;

  @include mobile {
    height: calc(100dvh - 81px);
  }

  &.hidden {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }

  &.slide-in {
    opacity: 1;
    transform: translateX(0);

    .black-mode-container {
      width: 100%;
      top: -1px;
    }

    .header-full {
      width: 100%;
    }
  }

  .container-menu {
    max-width: 1441px;
    width: 100%;
    margin: 0 auto;
    padding: 32px 74px 9px;

    @include mobile {
      padding: 32px 20px;
    }

    .menu-video {
      display: flex;
      justify-content: space-between;
      padding-bottom: 48px;
      border-bottom: 1px solid $cui-black-color-shadow;

      @media (max-width: 1230px) {
        flex-direction: column;
        border-bottom: none;
      }

      nav {
        max-width: 840px;

        @media (max-width: 1300px) {
          max-width: 798px;
        }

        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 4px;

          li {
            margin-bottom: 16px;
            width: 400px;

            p {
              display: none;
            }
            a,
            p {
              color: $cui-black-color-850;
              font-family: $cui-font-family-tt-norms;
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: 110%;
              letter-spacing: -0.64px;
            }
          }

          li:hover {
            a {
              text-decoration: none;
              color: $cui-yellow-color-550;
            }
            p {
              display: inline;
              color: $cui-yellow-color-550;
              margin-left: 4px;
            }
          }
        }
      }
      .video-background {
        max-width: 285px;
        height: 160px;

        video {
          border-radius: 11.419px;
          width: 100%;
          height: 100%;
        }
      }
    }

    .menu-promo {
      display: flex;
      align-items: center;
      padding: 20px 0 23px;

      @include mobile {
        flex-direction: column;
        width: 100%;
        padding: 16px;
        align-items: flex-start;
        gap: 8px;
        border-radius: 12px;
        border: 1px solid $cui-black-color-shadow;
        background: $cui-black-color-50;
      }

      p.promo {
        color: $cui-black-color-850;
        font-family: $cui-font-family-tt-norms;
        font-size: $cui-font-sm;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: -0.14px;
        margin: 0 12px 0 0;
        text-transform: lowercase;

        @include mobile {
          margin: 0;
        }
      }

      p.promo:first-letter {
        text-transform: uppercase;
      }

      .started-button {
        display: flex;
        .cui-button-lp {
          color: $cui-yellow-color-550;
          text-align: center;
          text-decoration-line: underline;
          background-color: transparent;
          border: none;
          padding: 0;
        }
      }
    }
  }

  &.black-mode {
    background-color: $cui-black-color;
    border-radius: 0px 0px 32px 32px;

    .black-mode-container {
      position: absolute;

      background: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(10px);
    }

    .container-menu {
      .menu-video {
        .navbar-container {
          ul {
            li {
              a,
              p {
                color: $cookunity-white;
              }
            }

            li:hover {
              a {
                color: $cui-yellow-color-550;
              }
              p {
                color: $cui-yellow-color-550;
              }
            }
          }
        }
      }
      .menu-promo {
        p.promo {
          color: $cookunity-white;
          @include mobile {
            color: $cui-black-color-850;
          }
        }
      }
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.cards-hover-link {
  display: flex;
  max-width: 1306px;
  width: 100%;
  justify-content: center;
  display: flex;
  margin: 0 auto;
  gap: 24px;
  flex-wrap: wrap;
  text-decoration: none;

  &.padding {
    padding: 63px 0;

    @include mobile {
      padding: 52px 20px;
    }
  }

  &.padding-bottom {
    padding: 0 0 63px;

    @include mobile {
      padding: 0 20px 63px;
    }
  }

  @media (max-width: 920px) {
    flex-direction: row;
  }

  .card-hover-container {
    height: 324px;

    .card-hover {
      max-width: 215px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (min-width: 768px) and (max-width: 1078px) {
        max-width: 240px;
        width: 100%;
      }

      @include mobile {
        width: 100%;
      }

      &:hover {
        text-decoration: none;
        box-shadow: 0px 0px 12px rgba(35, 31, 32, 0.1),
          0px 12px 12px rgba(0, 0, 0, 0.07);
        -webkit-transition: 0.25s linear 0s;
        -moz-transition: 0.25s linear 0s;
        -o-transition: 0.25s linear 0s;
        transition: 0.25s linear 0s;

        border-radius: 8px;

        .card-text img.linkedin {
          margin: 12px auto 0;
          display: block;
        }
      }

      .container-img-icon.container-link {
        width: 215px;
        height: 200px;

        .img-card {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 8px 8px 0px 0px;
        }
      }

      .card-text {
        background-color: $cookunity-white;
        max-width: 215px;
        padding: 16px 18px;
        border-radius: 0px 0px 8px 8px;
        width: 100%;

        img.linkedin {
          display: none;

          @include mobile {
            display: block;
            margin: 12px auto 0;
          }
        }
      }

      p.rebrand.lead {
        color: $cui-black-color;
        font-size: 16px;
        line-height: 24px;
      }

      p.rebrand {
        color: $cui-black-color-400;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .image-value-container.column-mobile {
      @include mobile {
        flex-direction: column;
        padding: 0 20px;

        p.rebrand {
          text-align: center;
        }
      }
    }

    &.center {
      justify-content: center;
    }
  }
}

.column-mobile {
  @include mobile {
    display: flex;
    flex-direction: column;

    .image-value-container {
      width: 100%;
      display: flex;
      margin-bottom: 38px;
      align-items: center;

      .icon-value-overflow {
        width: 60%;
        height: 100%;
        margin: 0 0 0 -55px;
      }
    }

    h3,
    p {
      text-align: left;
    }
  }
}

.container-cui.value-container-cui.enjoy-rebrand {
  justify-content: flex-start;
  margin-top: 0;
  display: flex;
  margin-bottom: 37px;
  align-items: baseline;
  padding: 0;

  @media (min-width: 600px) and (max-width: 920px) {
    padding: 0 20px;
    flex-wrap: nowrap;
  }

  .image-value-container {
    max-width: 299px;
    margin-right: 22px;

    @include mobile {
      flex-direction: column;
      max-width: 265px;
      margin: 0 auto 38px;
    }

    .container-img-icon {
      justify-content: flex-start;

      @include mobile {
        justify-content: center;
      }
    }

    @media (min-width: 768px) and (max-width: 1078px) {
      max-width: 265px;
    }

    img {
      margin: 0;
    }

    h3,
    p {
      text-align: left;

      @include mobile {
        text-align: center;
      }
    }

    p.rebrand.lead {
      color: $cui-black-color;
    }
  }

  .image-value-container:last-child {
    .icon-value {
      height: 69px;
    }
  }
}

.container-cui.value-container-cui.center {
  justify-content: center;
}

.container-cui.value-container-cui.padding-top {
  padding-top: 0;
}

.container-cui.value-container-cui.with-mobile {
  @include mobile {
    gap: 10px;

    .image-value-container {
      width: 105px;
    }

    .container-img-icon {
      width: 105px;
      height: 105px;
    }
  }
}

.hero-cui
  .half-container-row
  .container-cui.value-container-cui.hero-our-value {
  position: absolute;
  right: 0px;
  z-index: 1;
  bottom: 0;
  width: 100%;
  background: $cui-yellow-color-500;
  border-radius: 0px;
  height: 114px;
  padding: 30px 20px;
  display: flex;
  align-items: center;
  max-width: 100%;
  gap: 20px;
  flex-wrap: nowrap;
  flex-direction: row;

  @media (max-width: 920px) {
    position: absolute;
    right: 0px;
    bottom: 0;
    width: 640px;
    background: $cui-yellow-color-500;
    border-radius: 0px;
    width: 100%;
    text-align: center;
  }

  .image-value-container {
    display: flex;
    width: 33%;
    align-items: center;

    @include mobile {
      flex-direction: column;
      width: 29%;
    }

    .container-img-icon {
      margin-bottom: 0;
    }

    img {
      width: 35px;
      height: auto;
    }

    p.rebrand {
      color: $cui-black-color;
      text-align: left;
      margin-left: 8px;

      @include mobile {
        text-align: center;
        margin-left: 0;
        margin-top: 8px;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
      }
    }

    p.rebrand.small.normal {
      @include mobile {
        display: none;
      }
    }
  }
}

.width {
  .container-img-icon {
    width: 105px;
    height: 105px;
  }
}

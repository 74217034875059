@import '../../../../scss/mixins';
@import '../../../../scss/_variables';

#hero-with-sections {
  .container-cui {
    display: flex;
    gap: 21px;
    max-width: 1245px;
    width: 100%;
    height: 340px;
    position: relative;

    @media (max-width: 1162px) {
      flex-direction: column;
      margin: 0 auto;
      width: 90%;
      height: auto;
    }

    .column {
      position: relative;
      width: 611px;

      @media (max-width: 1162px) {
        margin: 0 auto;
        width: 100%;
      }

      &.column1 {
        border-radius: 8px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right;
        position: relative;

        @media (max-width: 1162px) {
          min-height: 445px;
          background-position: top;
          flex-direction: column;
          padding: 204px 21px 45px;
          text-align: center;
        }

        &::before {
          content: '';
          position: absolute;
          right: 0;
          bottom: 0;
          border-radius: 12px;
          height: 100%;
          width: 100%;
          background: linear-gradient(
            90deg,
            rgba(28, 28, 28, 1),
            rgba(28, 28, 28, 0)
          );

          @media (max-width: 1162px) {
            background: linear-gradient(
              360deg,
              rgba(28, 28, 28, 1),
              rgba(28, 28, 28, 0)
            );
          }
        }

        .content-hero {
          position: absolute;
          padding: 72px 0 0 40px;
          max-width: 380px;
          width: 100%;
          margin: 0;
          box-sizing: border-box;

          @media (max-width: 1162px) {
            position: relative;
            max-width: 100%;
            padding: 0;
          }

          h1 {
            color: $cookunity-white;
            font-family: $cui-font-family-rossanova;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 36px;
            margin: 0;

            @media (max-width: 1162px) {
              font-size: 32px;
              line-height: 38px;
              margin-bottom: 21px;
            }

            span {
              color: $cui-yellow-color-500;
              display: block;
            }
          }

          p {
            color: $cui-black-color-300;
            font-family: $cui-font-family-tt-norms;
            font-size: 16px;
            font-style: normal;
            font-weight: 450;
            line-height: 24px;
            margin: 0 0 27px;
          }

          form {
            width: 375px;

            @media (max-width: 1162px) {
              width: 100%;

              .input-button {
                margin: 0 auto;
              }
            }
          }
        }
      }

      &.column2 {
        border-radius: 12px;
        border: 1px solid $cui-black-color-150;

        @media (max-width: 1162px) {
          padding-bottom: 21px;
        }

        .slick-title-cui {
          padding: 0;
          h2 {
            padding: 57px 0 24px;
            text-align: center;
            font-family: $cui-font-family-rossanova;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            margin: 0;
          }
        }

        #tags-meals-options-map {
          height: auto;
          .options-map-container {
            padding: 0;
            gap: 11px;
            margin: 0;
            max-width: 613px;
            height: 181px;
            @media (max-width: 1162px) {
              flex-direction: row;
              max-width: 100%;
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
}

@import '../../scss/variables';

.view-more {
  text-decoration: none;
  border: 0;
  width: fit-content;
  height: 48px;
  padding: 14px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .arrow-icon {
    position: relative;
    top: -1px;
    -webkit-transition: -webkit-transform 0.5s ease;
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.3s ease;
    vertical-align: middle;
    width: 16px;
    height: 14px;
    margin-left: 12px;
  }

  &:hover {
    .arrow-icon {
      transform: translate3d(10px, 0, 0);
    }
  }

  .label {
    font-family: $cui-font-family-tt-norms;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    flex: 1;
    color: $cookunity-black;
    margin: 0;
  }
}

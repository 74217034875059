@import '../../../../scss/mixins';
@import '../../../../scss/variables';

.mainContainer {
  display: flex;
  flex-direction: column;
  margin: 50px 0;

  > div:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3%;
    padding: 0 5%;

    video {
      display: flex;
      width: 265px;
      height: 470px;
      object-fit: cover;
      border-radius: 6px;
    }

    img {
      display: flex;
      border-radius: 6px;
      height: 470px;
      object-fit: fill;
    }

    .slick-slide {
      padding: 0 15px;

      > div > div > div:nth-child(2) > div:nth-child(2) {
        line-height: 1.3;
      }
    }

    .slick-track {
      width: 40000px !important;
    }

    .slick-list {
      padding: 20px 0;
    }

    @include mobile {
      margin: 0 10%;
      margin-bottom: 20px;

      .slick-arrow {
        width: 30px;
        height: 30px;
      }

      .slick-dots {
        li {
          width: 8px;
          height: 8px;

          button {
            width: 8px;
            height: 8px;
            background-color: $cookunity-gray-2;

            &::before {
              display: none;
            }
          }
        }

        .slick-active {
          button {
            background-color: $cui-yellow-color-500;
          }
        }
      }
    }
  }

  > div:last-child {
    display: flex;
    justify-content: center;
    padding-top: 50px;

    a {
      text-align: center;
      font-size: 15px;
      height: 50px;
    }
  }
}

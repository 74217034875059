@import '../../scss/mixins';
@import '../../scss/variables';

.container-cui-cards {
  max-width: 1305px;
  justify-content: space-evenly;

  @include mobile {
    max-width: 100%;
  }
}

.card-pack-cui {
  max-width: 360px;
  width: 100%;

  @include mobile {
    margin: 0 auto 32px;
  }

  @media (min-width: 768px) and (max-width: 1040px) {
    max-width: 31%;
    margin: 0 auto;
  }

  &__title {
    background: $cui-black-color;
    border-radius: 6px 6px 0px 0px;
    padding: 30px 18px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: contain;

    h3 {
      color: $cookunity-white;
    }
  }

  &__startedPackBestSeller {
    background-image: url('/assets/images/cui/starter-pack/bg_bestsellers.png');
  }

  &__startedPackVeggie {
    background-image: url('/assets/images/cui/starter-pack/bg_veggie.png');
  }

  &__startedPackHealthy {
    background-image: url('/assets/images/cui/starter-pack/bg_healthy.png');
  }

  &__dishes {
    display: flex;
    flex-wrap: wrap;
    padding: 19px;
    gap: 12px;
    background: $cui-black-color-100;
    justify-content: center;

    img {
      width: 142px;
      height: 142px;
      border-radius: 6px;
      object-fit: cover;

      @media (min-width: 810px) and (max-width: 1040px) {
        width: 98px;
        height: 98px;
      }

      @media (min-width: 768px) and (max-width: 810px) {
        width: 91px;
        height: 91px;
      }
    }
  }

  &__content {
    padding: 24px 18px 16px;
    box-shadow: 0px 0px 2px rgba(35, 31, 32, 0.1),
      0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 6px 6px;

    @media (min-width: 768px) and (max-width: 1024px) {
      padding: 24px 8px 8px;
    }

    p {
      margin: 0;
    }

    .grey-price {
      color: $cui-black-color-300;
      text-decoration-line: line-through;
    }

    p.card-promo-text {
      @media (min-width: 768px) and (max-width: 1024px) {
        width: 80%;
        margin: 0 auto;
        text-align: center;
      }
    }
  }

  &__row-price {
    display: flex;
    margin: 4px auto 20px;
    justify-content: center;

    .green-tag {
      margin-right: 8px;

      @media (min-width: 768px) and (max-width: 810px) {
        margin-right: 2px;
      }
    }

    p {
      strong {
        font-size: 24px;
        line-height: 22px;
      }
    }
  }

  .cui-link,
  .cui-button {
    width: 100%;
    margin-bottom: 12px;
    justify-content: center;
  }
}

.packs-modal {
  margin-top: 25%;
  padding: 0;
  width: 600px;

  @include mobile {
    width: 100%;
    margin-top: 0;
    padding: 0 !important;
  }

  @media (min-width: 768px) and (max-width: 920px) {
    margin-top: 45%;
  }

  .topContainer {
    padding: 24px 20px 0 0;
  }

  &__container {
    height: 380px;
    overflow-y: scroll;

    @include mobile {
      height: calc(100vh - 198px);
    }

    &__meals {
      display: flex;
      padding: 8px 0px 8px 40px;
      align-items: center;

      @include mobile {
        padding: 8px 20px 8px 20px;
      }

      img.meal-image-modal {
        border-radius: 8px;
        margin-right: 18px;
        object-fit: cover;
      }

      .meal-data {
        p {
          margin: 0;
        }

        .name-meal {
          font-weight: bold;
        }
      }
    }
  }

  &__button-container {
    background: $cookunity-white;
    padding: 26px;
    box-shadow: 0px 0px 12px rgba(35, 31, 32, 0.1),
      0px 12px 12px rgba(0, 0, 0, 0.07);
    border-radius: 0px 0px 8px 8px;

    @include mobile {
      position: absolute;
      width: 100%;
      bottom: 0;
    }

    button {
      width: 100%;
    }
  }

  h3.packs-title {
    color: $cui-black-color;
    margin-bottom: 23px;
    margin-left: 30px;
    font-weight: bold;
  }

  img.meal-image-modal {
    width: 80px;
    height: 80px;
    border-radius: 8px;
  }
}

h3.packs-title {
  font-family: $cui-font-family-tt-norms;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  margin: 0;
}

.packs-modal-zip {
  margin-top: 200px;
  padding: 24px;
  width: 432px;

  @include mobile {
    width: 80%;
    border-radius: 8px;
    height: 214px;
  }

  h3 {
    margin-bottom: 51px;
    font-weight: bold;

    @include mobile {
      margin-bottom: 23px;
    }
  }

  form.zip-form-cui.rebrand .container-input input::-webkit-input-placeholder {
    color: $cui-black-color-200;
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

#MeetVideoSeoCUI {
  background-color: $cui-black-color;

  @include mobile {
    padding-bottom: 40px;
  }

  .container-seo {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    background-image: url('/assets/images/cui/home-seo/us-background.png');
    background-repeat: no-repeat;
    display: flex;
    justify-content: flex-end;
    padding-top: 50px;
    background-size: 36%;
    background-position-y: -162px;
    background-position-x: 0px;

    @media screen and (max-width: 1200px) {
      background-position-y: -94px;
    }

    @include mobile {
      padding: 0;
      background-position-y: unset;
      background-position-x: unset;
      background-size: 100%;
      background-image: url('/assets/images/cui/home-seo/us-background-mobile.png');
    }

    .container-row {
      display: flex;
      max-width: 1290px;
      width: 100%;
      justify-content: space-between;

      @include mobile {
        flex-direction: column;
      }

      .content {
        color: $cookunity-white;
        margin-left: 0px;
        max-width: 90%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (min-width: 768px) and (max-width: 1200px) {
          margin: 0 0 0 20px !important;
        }

        @include mobile {
          text-align: center;
          max-width: 100%;
          width: 100%;
          margin: 0;
        }

        h2.rebrand {
          position: relative;
          margin: 0 0 16px;
          font-weight: 300;
          line-height: 40px;
          font-size: 36px;
          display: flex;
          gap: 16px;
          align-items: center;
          width: 100%;
          font-family: $cui-font-family-rossanova;

          @media screen and (max-width: 1200px) {
            margin-top: 0;
          }

          @include mobile {
            justify-content: center;
            font-size: 36px;
            width: 100%;
            margin: 44px auto 16px;
            display: block;

            &:before {
              height: 45px;
              top: -25px;
              left: -25px;
            }

            &:after {
              width: 45px;
              top: -25px;
              left: -25px;
            }

            > br {
              display: none;
            }
          }

          .yellow {
            color: $cui-yellow-color-500;

            @include mobile {
              display: block;
            }
          }
        }

        p.rebrand {
          margin-bottom: 70px;
          color: $cookunity-white;
          max-width: 90%;
          width: 100%;

          @include mobile {
            margin-bottom: 50px;
            margin-top: 0;
          }
        }

        .cui-link {
          color: $cookunity-white;
          padding: 12px 55px;
        }
      }

      .home-seo-text {
        color: $cookunity-white;
        max-width: 80%;
        margin: 0 auto;
      }

      .video-player {
        width: 712px;
        position: relative;

        @include mobile {
          width: 100%;
        }

        @media screen and (max-width: 1200px) {
          width: 100%;
        }

        video {
          width: 100%;
        }
      }

      .ctaContainer {
        margin: 60px 0 40px;
        text-align: center;

        @include mobile {
          display: block;
        }

        .cui-link {
          color: $cookunity-white;
          padding: 12px 55px;
        }
      }
    }
  }
}

#MeetOurChefsVideo.with-padding-top {
  padding-top: 92px;
}

.our-approach {
  background: $cui-yellow-color-500;
  padding: 60px 0px;

  @include mobile {
    padding: 60px 0 60px 16px;
  }

  &__container {
    max-width: 1448px;
    margin: 0 auto;
    padding-left: 76px;
    display: flex;
    gap: 51px;
    width: 100%;

    @include mobile {
      flex-direction: column;
      padding-left: 0;
    }
  }

  &__content {
    width: 30%;
    flex: 1 1;

    @include mobile {
      width: 100%;
    }

    h2,
    p {
      font-style: normal;
      font-weight: 500;
    }

    p {
      font-size: $cui-font-base;
    }

    .pre-title {
      color: rgba(0, 0, 0, 0.6);
      font-family: $cui-font-family-tt-norms;
      line-height: 150%;
      letter-spacing: -0.32px;
      margin: 0;
    }

    p.main {
      color: rgba(0, 0, 0, 0.6);
      font-family: $cui-font-family-tt-norms;
      font-weight: 450;
      line-height: 150%;
      letter-spacing: -0.16px;
      margin: 0 0 40px;
      max-width: 389px;
      width: 100%;

      @include mobile {
        max-width: 100%;
      }
    }
    .cui-link-lp {
      width: fit-content;
    }

    h2 {
      margin: 20px 0 40px;
      color: $cui-black-color-850;
      font-family: $cui-font-family-rossanova;
      font-size: 88px;
      line-height: 100%;
      letter-spacing: -2.64px;
      max-width: 389px;
      width: 100%;

      @media (max-width: 1400px) {
        font-size: 48px;
        margin-bottom: 24px;
      }

      @include mobile {
        font-size: 40px;
        margin-bottom: 24px;
        max-width: 100%;
      }
    }
  }

  &__slider {
    width: 60%;

    @include mobile {
      width: 100%;
    }
    .slick-track {
      width: 19200px !important;
    }

    .slick-arrow {
      width: 32px;
      height: 32px;
      z-index: 1;
      border-radius: 100px;
      border: 1px solid rgba(255, 255, 255, 0.15);
      background: rgba(255, 255, 255, 0.15);
      backdrop-filter: blur(10px);
    }

    .slick-next {
      left: 40px;
      top: 470px;
      transform: rotate(180deg);
    }

    .slick-prev {
      top: 486px;
      left: 0;
    }

    .cards {
      border-radius: 16px;
      border: 1px solid rgba(0, 0, 0, 0.08);
      background: #fff;
      padding: 8px;
      width: 326px !important;
      margin-right: 20px;
      height: 464px;

      @include mobile {
        margin-right: 10px;
      }

      img {
        width: 100%;
        height: 284px;
        border-radius: 8px;
      }

      p.card-title {
        color: $cui-black-color-850;
        font-size: $cui-font-base;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.32px;
        margin: 16px auto 0;
        padding: 0 8px;
      }

      p.card-text {
        color: rgba(0, 0, 0, 0.6);
        font-size: $cui-font-sm;
        line-height: 150%;
        letter-spacing: -0.14px;
        padding: 0 8px 16px;
        margin: 0;
      }
    }
  }
}

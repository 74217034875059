@import '../../../../scss/mixins';
@import '../../../../scss/variables';

.shef-hero-container {
  display: flex;
  flex-direction: column;

  .image-section-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 600px;

    .content-box-wrapper {
      display: flex;
      width: 717px;
      background: rgba(0, 0, 0, 0.95);
      border-radius: 12px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 30px;

      > h2 {
        font-family: $cui-font-family-rossanova;
        font-weight: 700;
        font-size: 48px;
        line-height: 49px;
        color: $cookunity-white;
        text-align: center;
        margin-bottom: 0;

        > span {
          color: $cui-yellow-color-500;
        }
      }

      > p {
        width: 441px;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: $cookunity-white;
      }

      .container-input {
        > input {
          color: $cookunity-white;
        }
      }

      @include mobile {
        width: 100%;
        height: auto;
        padding-bottom: 20px;

        > h2 {
          text-align: center;
        }

        > p {
          width: 90%;
        }
      }
    }
  }

  .logos-section-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: $cui-black-color;
    height: 130px;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > img {
        width: 39px;
        height: 39px;
      }

      > span {
        width: 115px;
        font-size: 14px;
        text-align: center;
        color: $cookunity-white;
      }
    }
  }
}

@import '../../scss/variables';

.chefs-showcase-container {
  position: relative;
  background-color: $cookunity-black;
  border-radius: 20px;
  color: $cookunity-white;
  padding: 80px 0 56px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  @media (min-width: 920px) {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 300px;
      z-index: 1;
    }

    &::before {
      left: 0;
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0)
      );
    }

    &::after {
      right: 0;
      background: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0)
      );
    }
  }

  h2,
  > p {
    padding: 0 40px;
  }

  h2 {
    position: relative;
    z-index: 111;
    text-align: center;
    font-family: $cui-font-family-rossanova;
  }

  > p {
    position: relative;
    z-index: 111;
    color: $cookunity-white;
    font-size: $cui-font-xl;
    font-weight: 700;
    text-align: center;
    margin-bottom: 35px;

    span {
      color: $cui-yellow-color-500;
    }
  }

  .cui-link.chefs-link {
    width: fit-content;
    margin-top: 45px;
  }

  .chefs-marquee {
    width: 100%;
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.heroRadiusImageContainer {
  display: flex;
  flex-direction: column;

  &.darkBackground {
    background-color: $cui-black-color-600;

    .contentWrapper {
      background-color: $cui-black-color-600;
    }
  }

  &.container-hero {
    @include mobile {
      margin: 0 24px;
    }
  }

  &.withPadding {
    .contentWrapper {
      max-width: 1320px;
      padding: 72px 20px 0px;

      @media (max-width: 767px) {
        padding-top: 20px;
      }

      .imageContainer {
        margin: 20px 0 54px;

        @include mobile {
          margin-top: 60px;
          margin-bottom: 5%;
        }
      }
    }
  }

  .contentWrapper {
    display: flex;
    position: relative;
    max-width: 1280px;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;

    align-items: center;
    justify-content: center;

    margin: 0 auto;
    padding: 24px;

    @include mobile {
      width: 100%;
      flex-direction: column;
      height: 100%;
      margin: 0;
      padding: 16px;

      .textContainer {
        order: 2;
        width: 100% !important;
      }
    }

    &.with-video {
      border-radius: 20px;
      overflow: hidden;
      color: $cookunity-white;
      height: 556px;
      position: relative;
      padding: 0;
      margin: 96px auto 0;

      @include mobile {
        margin: 0;
        padding: 70px 20px !important;
        height: 570px !important;
      }

      @media (min-width: 768px) and (max-width: 1200px) {
        width: 96%;
      }

      @include mobile {
        margin: 0;
        padding: 70px 20px !important;
        height: 570px !important;
      }

      @media (min-width: 768px) and (max-width: 1200px) {
        width: 96%;
      }

      .imageContainer {
        background: none;
        margin: 0;

        @include mobile {
          height: 0;
        }
      }

      .textContainer {
        position: absolute;
        z-index: 1;
        background: linear-gradient(
          90deg,
          rgba($cui-black-color-600, 2.5) 20%,
          rgba($cui-black-color-600, 0) 100%
        );
        height: 100%;

        @include mobile {
          position: relative;
          padding: 20px !important;
          margin-top: 0% !important;
          background: linear-gradient(
            360deg,
            rgba($cui-black-color-600, 2.5) 20%,
            rgba($cui-black-color-600, 0) 100%
          );
          height: 526px;
          width: 100% !important;
        }

        p.heroTitle {
          font-size: 52px;
          font-style: normal;
          font-weight: 700;
          line-height: 56px;
          width: 512px;

          @include mobile {
            width: 100%;
            font-size: 31px;
            line-height: 40px;

            span {
              font-size: 31px;
              font-style: normal;
              font-weight: 700;
            }
          }
        }
      }

      @include mobile {
        display: flex;
        flex-flow: wrap-reverse;
        padding: 0;
        background-color: $cui-black-color-600;
        color: $cookunity-black;
        height: 100%;
      }

      &:after {
        content: ' ';
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0.35 !important;
        top: 0;

        @include mobile {
          content: none;
        }
      }

      .video-background {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        @include mobile {
          height: 430px;
          border-radius: 20px;
          position: relative;
        }

        video {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .video-controller {
          position: relative;
          top: calc(100% - 70px);
          z-index: 1;
          margin: 0 auto;
          width: 100%;
          max-width: 1200px;
          padding-left: 15px;
          padding-right: 15px;
          text-align: center;

          @include mobile {
            position: absolute;
            text-align: left;
          }

          img {
            width: 42px;
            cursor: pointer;
            display: inline-block;
            opacity: 0;
            animation-name: delayDisplay;
            animation-delay: 3s;
            animation-duration: 0.5s;
            animation-fill-mode: forwards;
          }
        }
      }
    }

    .imageContainer {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 556px;
      border-radius: 12px;
      background: var(--backgroundImg) no-repeat center center/cover;

      &.with-gradient {
        background: linear-gradient(
            to right,
            $cui-black-color -10%,
            transparent 95%
          ),
          var(--backgroundImg) no-repeat center center/cover;
        @include mobile {
          background: linear-gradient(
              to top,
              $cui-black-color 20%,
              transparent 80%
            ),
            var(--backgroundImgMobile) no-repeat center center/cover;
        }
      }

      @include mobile {
        background: var(--backgroundImgMobile) no-repeat center center/cover;
      }

      .textContainer {
        display: flex;
        width: 65%;
        flex-direction: column;
        justify-content: center;
        padding: 3%;
        padding-left: 5%;

        @include mobile {
          align-items: center;
          justify-content: flex-end;
          margin: 0;
          padding-bottom: 8%;
          margin-top: 74%;
        }

        .heroTitle {
          font-family: $cui-font-family-rossanova;
          font-style: normal;
          color: $cookunity-white;
          font-weight: bold;
          font-size: 50px;
          line-height: 1em;
          margin-bottom: 0px;

          span {
            font-family: $cui-font-family-rossanova;
            color: $cui-yellow-color-500;
            display: inline;
            font-size: 50px;
            line-height: 100%;
            letter-spacing: -0.02em;
          }

          @include mobile {
            display: flex;
            flex-direction: column;
            text-align: center;
            font-size: 32px;
            width: 100%;

            span {
              font-size: 32px;
            }
          }
        }

        p {
          font-family: $cui-font-family-tt-norms;
          font-style: normal;
          font-weight: 450;
          font-size: 16px;
          line-height: 24px;
          color: white;
          width: 490px;
          margin: 20px 0 44px;

          @include mobile {
            margin: 14px 0 38px;
            width: 100%;
            text-align: center;
          }
        }

        p.without-description {
          margin-bottom: 60px;

          @include mobile {
            margin-bottom: 32px;
          }
        }

        h4 {
          margin-top: 16px;
          text-transform: uppercase;

          @include mobile {
            width: 100%;
            text-align: center;
          }
        }

        h1 {
          margin-top: 0;
        }

        .container-input input::-webkit-input-placeholder {
          color: $cui-black-color-300;
        }

        input::-webkit-input-placeholder {
          color: $cui-black-color-300;
        }

        .container-input input {
          color: $cookunity-white;
        }

        button {
          height: 48px;
          max-width: 200px;
          color: $cui-black-color;
          background: $cui-yellow-color-500;
          font-family: $cui-font-family-tt-norms;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
        }

        .cta-container {
          @include mobile {
            width: 100%;
          }
        }
      }
    }
  }
}

.heroRadiusImageContainer.referral-hero {
  .container-input {
    > input {
      color: $cookunity-white;
    }
  }

  @media (max-width: 767px) {
    .contentWrapper {
      .imageContainer {
        align-items: flex-end;
        margin: 0;
        position: relative;
        margin-bottom: 200px;
      }
    }
  }
}

.referral_row {
  > .container-cui-row {
    max-width: 1100px !important;
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.what-are-you-craving {
  background-color: $cui-black-color-250;
  .container-cui {
    margin: 0 auto;
    text-align: center;

    h2,
    p.text {
      font-family: $cui-font-family-tt-norms;
      font-style: normal;
      line-height: 150%;
      margin: 0 0 16px;

      @include mobile {
        padding: 0 16px;
      }
    }

    h2 {
      color: $cui-black-color-850;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.48px;
    }

    p.text {
      color: rgba(0, 0, 0, 0.6);
      font-size: 18px;
      font-weight: 450;
      letter-spacing: -0.18px;
    }

    p.promo {
      color: $cui-black-color-300;
      font-size: 16px;
      line-height: 24px;
      text-transform: lowercase;
      @include mobile {
        padding: 0 16px;
      }
    }

    p.promo:first-letter {
      text-transform: uppercase;
    }

    .started-button {
      .cui-button-lp {
        margin: 0 auto;
      }
    }

    .options-map-container {
      height: 120px;
      margin-bottom: 32px !important;

      @include mobile {
        height: auto;
      }
    }
  }
}

@import '../../scss/variables';

.why-use-container {
  background-color: $cui-black-color-100;

  .why-use-section {
    text-align: center;
    padding-bottom: 50px;

    .first-row {
      display: flex;
      flex-direction: column;
      gap: 20px;

      p.rebrand.lead {
        font-size: $cui-font-2xl;
        @media screen and (max-width: 767px) {
          font-size: $cui-font-xl;
        }
      }

      p.why-text {
        text-align: center;
      }
    }

    .cards-slider-cui.why-use-reasons {
      padding-bottom: 0;

      .figure-image.four-card {
        height: 300px;
      }

      .rebrand.title {
        font-size: $cui-font-2xl;
        @media screen and (max-width: 767px) {
          font-size: $cui-font-xl;
        }
      }
    }
  }
}

@import '../../../../scss/mixins';
@import '../../../../scss/variables';

.why-cook-image-text {
  h2 {
    font-family: $cui-font-family-rossanova;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    margin: 54px 0 0;
    text-align: center;
  }
  .row-container {
    display: flex;
    justify-content: center;
    gap: 120px;
    padding: 32px 0 99px;

    @media screen and (max-width: 1200px) {
      padding: 32px 20px 99px;
    }

    @media screen and (max-width: 920px) {
      padding: 32px 0px 99px 20px;
    }

    .slick-slider {
      width: 1160px;
      margin: 0 auto;

      @media screen and (max-width: 1200px) {
        width: 100%;
      }

      .slick-track {
        display: flex;
        justify-content: center;
      }

      .slick-slide {
        margin: 0 60px;

        @media screen and (max-width: 1200px) {
          margin: 0 36px;
        }
      }
    }

    figure {
      width: 265px !important;
      box-sizing: border-box;
      margin: 0;

      img {
        width: 265px;
        height: 289px;
        border-radius: 6px;
        object-fit: cover;
      }

      figcaption {
        margin: 24px 0 0;
        width: 100%;
        font-family: $cui-font-family-tt-norms;
        font-style: normal;

        h3 {
          text-align: left;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          margin: 0 0 16px;
        }

        p {
          font-size: 16px;
          font-weight: 450;
          line-height: 24px;
          color: $cui-black-color-300;
          margin: 0;
        }
      }
    }
  }
}

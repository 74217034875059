@import '../../scss/mixins';
@import '../../scss/variables';

div.Select {
  position: relative;
  @include mobile {
    width: 100%;
  }

  button.selection {
    padding: 16px 24px;
    max-height: 60px;
    width: 335px;
    color: $cookunity-black;
    background-color: $cookunity-white;
    text-decoration: none;
    background: $cookunity-white;
    border: 1px solid $cui-black-color-350;
    border-radius: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      width: 100%;
    }
  }

  button.selection:after {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    background: url('/assets/icons/chevron-down.svg') no-repeat center center;
    margin-left: 16px;
  }

  ul {
    position: absolute;
    opacity: 0;
    padding: 20px;
    margin: 0;
    width: 100vw;
    max-width: 50vw;
    list-style: none;
    background: $cookunity-white;
    border-radius: 8px;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    z-index: 1;
    pointer-events: none;

    @include mobile {
      max-width: 80vw;
    }

    &.show {
      opacity: 1;
      pointer-events: auto;
      display: flex;
      flex-direction: column;
      max-width: 100%;
      z-index: 11;
    }

    li {
      text-align: center;
      font-weight: 450;
    }

    button {
      background: none;
      color: $cui-black-color;
      padding: 8px 0 4px;
      font-size: 16px;
      border-radius: 0;
      border-bottom: 3px inset transparent;

      &:hover,
      &.active {
        cursor: pointer;
        border-bottom-color: $cui-black-color;
      }
    }
  }
}

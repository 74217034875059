@import '../../../../scss/mixins';
@import '../../../../scss/variables';

.a-brand {
  margin: 0 auto;
  flex: 1;

  @include mobile {
    flex: unset;
    display: flex;
    align-items: center;

    .brand-logo {
      width: 131px;
      height: 28px;
    }
  }
}

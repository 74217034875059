@import '../../scss/variables';

.circular-loader {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: transparent;
  border: 4px solid $cui-black-color-400;
  border-top-color: $cui-yellow-color-500;
  animation: 1s circularSpin linear infinite;
}

@keyframes circularSpin {
  to {
    transform: rotate(360deg);
  }
}

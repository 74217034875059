@import '../../../../scss/mixins';
@import '../../../../scss/variables';

.menu-cui {
  background-color: $cui-black-color-100;
  padding: 35px;
  @include mobile {
    width: 100%;
  }

  .container-grid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);

    @media (min-width: 768px) and (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1024px) and (max-width: 1030px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  ul {
    display: flex;
    padding: 0;

    @include mobile {
      margin-top: 50px;
    }

    li {
      list-style: none;
      margin-right: 47px;
      padding-bottom: 8px;
      color: $cookunity-gray-2;
      font-style: normal;
      font-size: 16px;
      line-height: 24px;

      @include mobile {
        margin-right: 24px;
      }
    }

    li:last-child {
      @include mobile {
        margin-right: 0px;
      }
    }

    .selected {
      border-bottom: 3px solid $cookunity-black;
      color: $cookunity-black;
      font-weight: 800;
    }
  }

  button {
    margin: 64px auto 0;
  }
}

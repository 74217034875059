@import '../../scss/mixins';
@import '../../scss/variables';

#banner-black {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 0 50px 0;

  .banner-black-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1162px;
    height: auto;
    padding: 36px 108px 36px 42px;
    margin: 0 24px;
    border-radius: 8px;
    background-color: $cookunity-black;

    @media (max-width: 1162px) {
      flex-direction: column;
      padding: 27px 21px;
      text-align: center;
      justify-content: center;
    }

    @include mobile {
      padding: 12px 0 32px;
    }

    form {
      input {
        color: $cookunity-white;
      }

      @media (max-width: 1162px) {
        .input-button {
          margin: 0 auto;
        }
      }
    }
  }

  &.with-image {
    .banner-black-container {
      padding: 0;
      background-image: var(--banner-image);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 12px;

      @media (max-width: 1162px) {
        background-image: var(--banner-image-mobile);
      }

      .img-column {
        width: 454px;
        height: 265px;
      }
    }
  }
}

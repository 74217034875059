.flex-twocols {
  width: 100%;

  .flex-container {
    padding: 15px;
    max-width: 1200px;
    margin: 15px auto;
    display: flex;
    justify-content: flex-end;
    column-gap: 30px;
    flex-wrap: wrap;
    align-items: center;

    &.row-reverse {
      flex-direction: row-reverse;
    }
  }

  .content {
    max-width: 94%;

    h3.rebrand {
      text-align: left;
    }
  }

  .content,
  .media {
    flex-basis: calc(50% - 30px);
    /* Subtracts half of the column-gap */
  }

  @media (max-width: 768px) {
    .flex-container {
      flex-direction: column-reverse;

      &.row-reverse {
        flex-direction: column-reverse;
      }
    }

    .content,
    .media {
      flex-basis: 100%;
    }
  }

  &.isBlackMode {
    background-color: $cui-black-color-600;

    .content {
      h3,
      p,
      form {
        color: $cookunity-white;
      }
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.link-with-arrow {
  border: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  font-family: $cui-font-family-tt-norms;
  font-style: normal;
  font-weight: 800;

  color: $cookunity-black;

  .arrow-icon {
    position: relative;
    top: -1px;
    -webkit-transition: -webkit-transform 0.5s ease;
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.3s ease;
    vertical-align: middle;
    width: 16px;
    height: 14px;
    margin-left: 12px;
  }

  &:hover {
    text-decoration: none;
    .arrow-icon {
      transform: translate3d(10px, 0, 0);
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

@keyframes delayDisplay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.hero-cui {
  background-color: $cui-black-color-600;
  position: relative;

  .container-cui {
    padding: 124px 0 124px;

    @media (max-width: 920px) {
      padding: 82px 20px;
    }
  }

  .half-container-row {
    display: flex;
    width: 100%;
    max-width: 1440px;
    height: 462px;
    justify-content: space-between;
    margin: 0 auto;
    flex-direction: row;

    @media (max-width: 920px) {
      flex-direction: column-reverse;
      height: 100%;
      margin: 0;
    }

    .container-cui {
      max-width: 50%;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0;

      @media (max-width: 920px) {
        max-width: 100%;
        padding: 35px 20px;
      }
    }

    &.align-center {
      .half-video-background {
        video {
          top: 0;
        }
      }
    }

    .half-video-background {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      max-width: 653px;

      @media (max-width: 920px) {
        position: relative;
        padding: 0;
        width: 100%;
        max-width: 100%;
        height: 303px;
      }

      .video-mask {
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
      }

      video {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media (max-width: 920px) {
          width: 100%;
        }
      }

      .video-controller {
        position: relative;
        top: calc(100% - 70px);
        z-index: 1;
        margin: 0 auto;
        width: 100%;
        max-width: 1200px;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;

        @media (max-width: 920px) {
          position: absolute;
          text-align: left;
        }

        img {
          width: 42px;
          cursor: pointer;
          display: inline-block;
        }
      }
    }

    .cui-link {
      margin-top: 27px;
    }
  }

  .content-left {
    max-width: 530px;
    margin: 0;
    text-align: left;
    margin-left: 98px;
    position: relative;

    @media (max-width: 920px) {
      max-width: 100%;
      margin: 0 auto;
      text-align: center;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media (min-width: 920px) and (max-width: 1200px) {
      margin-left: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    h1.rebrand,
    h2.rebrand,
    h3.rebrand {
      margin: 0;
      color: $cookunity-white;

      span {
        color: $cui-yellow-color-500;
      }
    }

    h3.chef-reviews {
      text-align: start;
    }

    h1.rebrand span.rossanova {
      font-family: $cui-font-family-rossanova;
      font-size: 64px;

      @include mobile {
        font-size: $cui-font-4xl;
        line-height: 44px;
      }
    }

    p.rebrand {
      color: $cookunity-white;
    }

    p.rebrand.small.normal {
      margin-top: 6px;
    }

    .value-container {
      margin-top: 32px;

      p.rebrand,
      p.rebrand.lead {
        color: $cookunity-white;
        text-align: left;
      }

      .image-value-container {
        .icon-value {
          margin: 0 0 16px;
          height: 35px;
          object-fit: scale-down;
        }
      }
    }

    h2.rebrand.avallon {
      font-family: $cui-font-family-avallon;
      font-style: normal;
      font-weight: 400;
      font-size: 90px;
      line-height: 50%;
      display: flex;
      align-items: center;
      letter-spacing: 1px;
      color: $cui-black-color-100;
      margin-bottom: 25px;

      @include mobile {
        font-size: 50px;
        line-height: 25px;
      }

      p {
        margin-right: 20px;
      }
    }

    form {
      margin-top: 20px;

      @media (max-width: 920px) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }

      input {
        color: $cookunity-white;
      }

      input::-webkit-input-placeholder {
        color: $cookunity-white;
      }
    }

    img {
      width: unset;
    }

    .chef-image {
      width: 128px;
      height: 128px;
      border-radius: 11px;
      margin-bottom: 30px;
    }

    div.chef-image {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    img.chef-image {
      object-fit: cover;
    }

    .chefDescription p {
      margin-right: 12px;

      @include mobile {
        margin-right: 0;
      }
    }

    .cui-button-lp {
      max-width: 375px;
      width: 100%;
      margin-top: 20px;
    }
  }

  &.with-video {
    background-image: none !important;
    overflow: hidden;
    color: $cookunity-white;
    height: 460px;
    position: relative;

    .content-left {
      position: absolute;
      z-index: 1;

      @media (max-width: 920px) {
        padding: 0;
        position: relative;
      }
    }

    @media (max-width: 920px) {
      display: flex;
      flex-flow: wrap-reverse;
      padding: 0;
      background-color: $cui-black-color;
      color: $cookunity-black;
      height: 100%;

      .with-video-row {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
      }
    }

    &:after {
      content: ' ';
      width: 100%;
      height: 100%;
      position: absolute;
      background: transparent !important;
      background: $cui-black-color !important;
      opacity: 0.35 !important;
      top: 0;

      @media (max-width: 920px) {
        content: none;
      }
    }

    .video-background {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;

      @media (max-width: 920px) {
        position: relative;
      }

      @media (max-width: 920px) {
        height: 303px;
      }

      video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .video-controller {
        position: relative;
        top: calc(100% - 70px);
        z-index: 1;
        margin: 0 auto;
        width: 100%;
        max-width: 1200px;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;

        @media (max-width: 920px) {
          position: absolute;
          text-align: left;
        }

        img {
          width: 42px;
          cursor: pointer;
          display: inline-block;
          opacity: 0;
          animation-name: delayDisplay;
          animation-delay: 3s;
          animation-duration: 0.5s;
          animation-fill-mode: forwards;
        }
      }
    }
  }

  &.with-half-video,
  &.no-background {
    background-image: none;
    padding: 0;
  }

  .carrousel-container-row {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;
    justify-content: center;

    @media (max-width: 920px) {
      flex-direction: column-reverse;
    }

    .container-cui {
      max-width: 50%;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0;

      @media (max-width: 920px) {
        max-width: 100%;
        padding: 0px 20px 28px;
      }
    }

    .hero-cui-carrousel {
      width: 50%;

      @media (max-width: 920px) {
        width: 100%;
        margin-top: 0px;
      }

      .slick-slider {
        @media screen and (min-width: 1400px) {
          width: 100%;
        }

        .slick-dots {
          position: relative;
          top: -20px;
          left: -10px;
          text-align: left;
          margin-left: 6px;

          @media screen and (max-width: 920px) {
            top: 0;
            text-align: center;
            margin: 18px 0;
          }

          ul li {
            background: $cui-yellow-color-600;

            &:after {
              content: ' ';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }

            button {
              width: 100%;
              position: relative;
              top: -5px;

              &:before {
                content: none;
              }
            }
          }

          li.slick-active {
            position: relative;
            background: $cookunity-white;
            border-radius: 6px;

            &:after {
              animation: dotsTimer 4s linear;
              background: $cui-yellow-color-600;
            }
          }

          li.slick-active ~ li {
            background: $cookunity-white;
            border-radius: 6px;
          }

          ul {
            margin: 0px;
            padding: 0px;

            @media (max-width: 920px) {
              margin: 18px 0 0;
            }
          }

          li {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 2px;
            padding: 0;
            cursor: pointer;
            margin: 0 5px;
            border-radius: 6px;
          }
        }
      }

      .image-quote-carrousel {
        position: relative;

        .image-hero-carrousel {
          width: 100%;
          height: 428px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          margin-bottom: 20px;

          @media (max-width: 920px) {
            height: 285px;
            max-width: unset;
            margin-bottom: 0;
          }
        }

        .image-hero-carrousel.component {
          height: 350px;
        }

        .quote-hero-container {
          width: 334px;
          height: 135px;
          background: $cookunity-white;
          border-radius: 0px;
          padding: 22px 36px;
          z-index: 2;
          margin-top: -155px;
          margin-left: 386px;
          margin-bottom: 30px;

          p {
            text-align: left;

            @media (min-width: 1024px) and (max-width: 1400px) {
              text-align: left;
              font-size: 14px;
              line-height: 125%;
            }

            @media (max-width: 1024px) {
              text-align: center;
            }
          }

          @media (min-width: 1024px) and (max-width: 1400px) {
            width: 55%;
            margin: -72px 42% 22px;
            padding: 12px 20px 12px;
            height: 68px;
            text-align: left;
          }

          @media (min-width: 920px) and (max-width: 1024px) {
            width: 55%;
            margin: -72px auto 22px;
            padding: 12px 20px 12px;
            min-height: 68px;
            height: 100%;
            text-align: center;
          }

          @media (max-width: 920px) {
            width: 100%;
            margin: -72px auto 22px;
            padding: 12px 20px 12px;
            min-height: 68px;
            height: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .quote-hero-carrousel {
            width: auto;
            height: 56px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            display: inline-block;
          }
        }
      }
    }

    .value-container-cui {
      max-width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin-top: 40px;
      gap: 20px;

      .image-value-container {
        width: 33%;

        .container-img-icon {
          width: unset;
          height: unset;
        }
      }
    }

    &.component {
      .content-left {
        padding: 20px 0;
      }

      .value-container-cui {
        margin-top: 30px;
      }
    }
  }

  .half-container-mobile {
    @media (max-width: 920px) {
      display: flex;
      width: 100%;
      flex-direction: column-reverse;

      .container-cui {
        height: 100%;
        padding: 35px 20px;
      }
    }
  }

  .mobile-reverse {
    @include mobile {
      flex-direction: column;
    }
  }

  .half-container-image {
    max-width: 653px;
    width: 100%;

    @media (max-width: 920px) {
      max-width: 100%;
      height: 303px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
  }

  .text-link {
    color: $cookunity-white;
    font-weight: 700;
    text-decoration-line: underline;
  }

  &.break-delivery {
    @media (max-width: 920px) {
      .half-container-image img {
        object-fit: contain;
      }
    }
  }

  &.contain {
    .half-video-background {
      @include mobile {
        height: 211px;
      }

      video {
        object-fit: contain;
      }
    }
  }

  &.chefs-lp-image {
    .half-container-image {
      margin-right: 50px;

      @media (max-width: 1200px) {
        margin-right: 0;

        @include mobile {
          object-fit: cover;
        }
      }

      img {
        object-fit: cover;
        height: 100%;

        @include mobile {
          object-fit: cover;
        }
      }

      @include mobile {
        margin-right: 0;
      }
    }
  }

  &.referral {
    @include mobile {
      background-color: $cui-black-color;
      background-image: none;
    }

    .content-left {
      max-width: 70%;

      @include mobile {
        max-width: 100%;
      }
    }
  }

  &.b2b-lp {
    padding-top: 72px;

    @include mobile {
      padding-top: 48px;
    }

    .cui-button {
      margin-top: 24px;

      @include mobile {
        margin-top: 14px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    h1.rebrand,
    h1.rebrand span.rossanova {
      font-size: 48px;
      line-height: 53px;
    }
  }

  &.green-hero {
    .half-container-row {
      max-width: 1300px;
      width: 100%;

      .container-cui {
        .content-left {
          margin: 0 auto;
          width: 100%;
          @media (min-width: 920px) and (max-width: 1200px) {
            padding: 20px;
          }
        }
      }
      .half-container-image {
        position: relative;
        width: 100%;
        height: auto;
        overflow: hidden;
      }

      .image-hero-cui {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    h1 {
      color: $cookunity-white;
      font-family: $cui-font-family-rossanova;
      font-size: '37px';
      font-style: normal;
      font-weight: 700;
      line-height: 43px;
      margin-top: 10px;

      @include mobile {
        line-height: 35px;
      }
    }

    h4 {
      margin: 0;
      color: $cookunity-white;
      font-style: normal;
      font-weight: 400;
      font-family: $cui-font-family-tt-norms;
    }

    br {
      @include mobile {
        display: none;
      }
    }

    span {
      color: $cui-olive-green-color-500;

      @include mobile {
        display: block;
      }
    }

    p {
      max-width: 441px;
      width: 100%;
      margin-bottom: 12px;
    }
  }

  &.become-chef-component {
    @include mobile {
      .value-container-cui {
        flex-direction: column;
        align-items: center;

        .image-value-container {
          width: 100%;
        }
      }
    }
  }

  &.with-border-radius {
    border-radius: 20px;
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.promobar-cui {
  background: $cui-olive-green-color-500;
  width: 100%;
  height: 40px;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile {
    padding: 8px 12px;
  }

  p.rebrand {
    color: $cookunity-white;

    @include mobile {
      font-size: 12px;
    }

    &::first-letter {
      text-transform: uppercase;
    }

    &.uppercase {
      text-transform: uppercase;
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.promobar {
  background-color: $cookunity-green;

  &.hide {
    display: none !important;
  }

  .content {
    color: $cookunity-white;
    text-transform: uppercase;
    font-size: 16px;
    text-align: center;
    padding: 10px 10px;
    font-family: $cui-font-family-tt-norms;
    font-weight: bold;

    @include mobile {
      font-size: 12px;
    }
  }
}

.promobar-black {
  background: $cui-black-color-400;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.12);
  .content {
    font-weight: normal;
  }
}

.promobar-warning {
  background: $cookunity-red;
  .content {
    font-weight: normal;
    text-transform: none;
  }
}

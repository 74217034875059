@import '../../../scss/variables';

.input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px;

  label {
    font-family: $cui-font-family-tt-norms;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    color: $cookunity-black;
    text-align: left;
    margin-bottom: 8px;
  }

  input,
  textarea {
    width: 100%;
    padding: 10px 12px 12px;
    border: 1px solid $cui-black-color-200;
    border-radius: 4px;
    font-family: $cui-font-family-tt-norms;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $cookunity-gray-2;
      font-weight: 400;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $cookunity-gray-2;
      font-weight: 400;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $cookunity-gray-2;
      font-weight: 400;
    }

    &:invalid {
      border-color: $cookunity-red;

      &:invalid {
        border-color: $cookunity-red;
      }
    }
  }

  input {
    height: 40px;
  }

  input:invalid {
    input {
      height: 200px;
    }
  }

  textarea {
    height: 130px;
    resize: none;
    font-family: $cui-font-family-tt-norms;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
    color: $cui-black-color-400;
  }

  .bottom-label {
    height: 14px;
    margin-top: 8px;
    font-family: $cui-font-family-tt-norms;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
  }

  &.with-error {
    input,
    textarea {
      border-color: $cookunity-red;
    }

    .bottom-label {
      color: $cookunity-red;
    }
  }
}

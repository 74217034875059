.filters-carrousel-slider {
  padding: 0 0 0 10px;
  margin: 0 auto;

  .slick-track {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .tab-list-element {
    color: $cui-black-color;
    border: 1px solid $cui-black-color-shadow;
    padding: 12px 24px;
    background: $cookunity-white;
    border-radius: 12px;
    cursor: pointer;
  }
}

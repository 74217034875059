@import '../../scss/variables';

.articleMeta {
  font-family: $cui-font-family-tt-norms;
  font-size: 15px;
  margin-bottom: 20px;
  color: $cui-black-color-300;

  span {
    margin: 0 10px;
  }

  img.authorAvatar {
    vertical-align: middle;
    display: inline-block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    object-fit: cover;
    margin-right: 8px;
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.login-link {
  font-family: $cui-font-family-tt-norms;
  background: $cui-black-color;
  border-radius: 50px;
  border: none;
  color: $cookunity-white;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  font-size: 14px;
}

.login-link-home {
  min-width: 150px;
  border: 1px solid $cui-olive-green-color-500;
  border-radius: 66px;
  background-color: transparent;
  color: $cui-olive-green-color-500;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  height: 48px;

  @include mobile {
    display: none;
  }
}

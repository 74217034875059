@import '../../scss/mixins';
@import '../../scss/variables';

.zipbar-static-cui {
  padding: 40px 0 32px;
  background: $cui-wine-color-500;
  position: relative;
  width: 100%;
  bottom: 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: flex;

  @include mobile {
    padding: 60px 20px 72px;
  }

  h2.rebrand {
    margin: 10px;
    flex: 1;
    font-size: 36px;
    line-height: 44px;
    color: $cookunity-white;

    .second-line {
      color: $cookunity-black;
      display: block;
      font-weight: 800;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      margin: 0 20px 0 0;
    }

    @include mobile {
      padding: 0px;
      width: 100%;
      font-size: 30px;
      line-height: 1.3em;
      margin-right: 0px;
      margin-bottom: 19px;
      text-align: center;
    }

    @media (min-width: 768px) and (max-width: 920px) {
      margin-right: 20px;
    }

    &.uppercase {
      text-transform: uppercase;
    }
  }

  .container {
    max-width: 1200px;
    justify-content: space-between;
    display: flex;
    align-items: center;

    @include mobile {
      flex-direction: column;
      padding: 0;
    }

    .avallon {
      font-size: 30px;
      line-height: 104px;
      margin-right: 40px;
      color: $cookunity-white;

      @include mobile {
        line-height: unset;
      }
    }
  }

  .container-text {
    display: flex;
    justify-content: center;
    align-items: center;
    display: grid;
    grid-template-columns: 2fr 1fr;
    max-width: 1100px;
    margin: 0 auto;

    @include mobile {
      grid-template-columns: 1fr;
      padding: 0px 20px;
    }

    h4 {
      font-family: $cui-font-family-tt-norms;
      font-style: normal;
      font-weight: 800;
      font-size: 38px;
      line-height: 47px;
      color: $cookunity-white;
      max-width: 490px;

      @include mobile {
        font-size: 28px;
        line-height: 32px;
      }
    }
  }

  form.zip-form-cui.rebrand {
    max-width: 460px;

    @include mobile {
      max-width: 100%;
    }

    @media (min-width: 768px) and (max-width: 920px) {
      max-width: 360px;
    }
  }

  form.zip-form-cui .container-input input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  form.zip-form-cui .container-input input,
  form.-cui .container-input input::-webkit-input-placeholder {
    color: $cui-black-color;
  }
}

.zipbar-static.only-mobile {
  display: none;

  @include mobile {
    display: block;
  }
}

@import '../../scss/variables';

#SingleB2BLayout {
  .hero-cui {
    padding-top: 50px;
    h1.rebrand {
      font-size: 42px;
      @media screen and (max-width: 767px) {
        font-size: 36px;
      }
    }

    p.rebrand {
      font-size: 20px;
      margin-top: 50px;

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
  }
  .meals-container {
    .meals-slider-cui .container-button-bottom .cui-link {
      background: $cui-yellow-color-500;
    }
  }
  .how-to-order-container {
    padding-bottom: 20px;
  }
  .input-zipcode {
    padding: 20px 0 20px 0;
    display: flex;
    justify-content: center;
    background-color: $cookunity-white;
  }
  .input-zipcode.black-mode {
    background-color: $cui-black-color-600;
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

#special-dishes-carrousel.break-delivery {
  background: $cui-black-color-600;
  padding: 53px 0 40px;

  .slick-track {
    width: 9000px !important;
  }

  .container-cui {
    padding: 0;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1200px) {
      flex-direction: column-reverse;
    }
  }

  h2,
  .view-more {
    color: $cookunity-white;

    span.label {
      text-decoration-line: underline;
    }
  }

  .avallon {
    color: $cui-yellow-color-500;
  }

  p {
    margin: 0 0 22px;
    color: $cui-black-color-150;
  }

  .col {
    margin: 0;
    max-width: 653px;

    @include mobile {
      max-width: 100%;
      margin: 0 auto;
    }

    @media (min-width: 768px) and (max-width: 1200px) {
      max-width: 100%;
      margin: 0;
    }
  }

  .column-left {
    max-width: 530px;
    margin: 0;
    text-align: left;
    margin-left: 98px;
    position: relative;

    @include mobile {
      max-width: 100%;
      margin-right: 0px;
      padding: 0 45px 57px 34px;
      text-align: center;
    }

    @media (min-width: 768px) and (max-width: 1200px) {
      padding: 0 20px 20px;
      margin: 0;
      max-width: 50%;
      text-align: center;
    }
  }

  .slick-slider {
    width: 100%;

    .slick-slide {
      width: 320px !important;
    }

    .meal-container {
      max-width: 302px;
      width: 100%;

      @include mobile {
        max-width: 100%;
      }

      .p-lead {
        font-family: $cui-font-family-tt-norms;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 28px;
        color: $cookunity-white;
        margin: 12px 0 25px;
      }

      p.sign {
        font-family: $cui-font-family-reinata;
        font-style: normal;
        font-weight: 400;
        font-size: 75px;
        line-height: 24px;
        color: $cookunity-white;

        @include mobile {
          font-size: 50px;
        }

        span {
          font-family: $cui-font-family-tt-norms;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: $cui-black-color-300;
          margin: 0 8px 0 0;
        }
      }
    }
  }
}

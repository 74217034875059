@import '../../scss/mixins';
@import '../../scss/variables';

.mealsCarrouselChef {
  background: $cui-black-color-100;
  .meal-card {
    cursor: pointer;
  }

  .slick-dots {
    bottom: -40px;
  }
  .slick-dots li {
    margin: 0 0;
  }

  .slick-slide {
    padding: 0 15px;
    > div > div > div:nth-child(2) > div:nth-child(2) {
      line-height: 1.3;
    }
  }

  .slick-track {
    width: 40000px !important;
  }

  .slick-list {
    padding: 20px 0;
  }

  .slick-prev,
  .slick-next {
    width: 48px;
    height: 48px;
    border-radius: 200px;
    background: $cookunity-white;
    opacity: 0.75;
    border: $cookunity-white;

    @include mobile {
      display: none;
    }
  }

  .slick-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 2px 0px rgba(35, 31, 32, 0.05),
      0px 0px 2px 0px rgba(35, 31, 32, 0.1);
    z-index: 9;

    &.slick-prev:before,
    &.slick-next:before {
      content: '';
    }

    &.slick-next {
      img {
        transform: rotate(180deg);
      }
    }

    &:hover {
      opacity: 1;
      color: $cookunity-white;
      background-color: $cookunity-white;
    }

    &.slick-next:focus,
    &.slick-next:hover,
    &.slick-prev:focus,
    &.slick-prev:hover {
      opacity: 1;
      color: $cookunity-white;
      background-color: $cookunity-white;
    }
  }

  &.withMealHighlight {
    padding-bottom: 40px;
    overflow: hidden;
    .container {
      @media (max-width: 1200px) {
        padding: 0;
      }
    }
    .slick-slide {
      width: auto !important;
    }

    .slick-list {
      padding: 20px 0px !important;
      @include mobile {
        margin-right: 0px;
      }
    }

    .slick-dots li.slick-active button:before {
      color: $cui-yellow-color-600;
    }
  }

  .mealHighlight {
    padding: 24px;
    background-color: $cui-black-color-700;
    border-radius: 12px;
    display: flex !important;
    align-items: flex-start;
    gap: 20px;
    width: 554px !important;
    box-sizing: border-box;

    .cui-meal-card {
      height: 396px;
      width: 262px;
    }
    .text {
      font-family: $cui-font-family-tt-norms;
      width: 224px;

      h3 {
        color: $cookunity-white;
        text-align: left;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        margin: 0 0 12px;
      }

      p {
        color: $cui-black-color-300;
        font-size: 16px;
        font-style: normal;
        font-weight: 450;
        line-height: 24px;
        margin: 0;
      }
    }
  }

  .noMealHighlight {
    padding: 24px 0;
    width: 262px !important;
    .cui-meal-card {
      height: 396px;
      width: 262px;
    }
    .text {
      display: none;
    }
  }
}

.title-center {
  .container {
    .title,
    .subtitle {
      text-align: center;
      max-width: 414px;
      width: 100%;
    }

    .title {
      margin: 0 auto 10px;
    }

    .subtitle {
      margin: 0 auto 60px;
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

#steps-cui {
  max-width: 1200px;
  margin: 52px auto 44px;
  padding: 0;

  @include mobile {
    margin: 0;
    max-width: 100%;
    padding: 0;
  }

  .col-row {
    flex-direction: row;
    text-align: left;

    @media (max-width: 920px) {
      flex-direction: column-reverse;
    }
  }

  .col-row-reverse {
    flex-direction: row-reverse;
    text-align: right;

    @media (max-width: 920px) {
      flex-direction: column-reverse;
    }
  }

  .container-step {
    padding: 20px;
    display: flex;
    justify-content: space-between;

    @include mobile {
      padding: 20px 0;
    }

    .col-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 500px;
      text-align: left;

      @media (min-width: 920px) and (max-width: 1200px) {
        max-width: 40%;
        width: 100%;
        margin-right: 10px;
        padding: 0 20px;
        margin-top: 32px;
      }

      @media (max-width: 920px) {
        margin-top: 32px;
        max-width: 100%;
        width: 100%;
        padding: 0 20px;
        margin-right: 0;
      }

      p.rebrand.lead {
        color: $cui-black-color;
      }

      p.rebrand {
        margin-bottom: 16px;
      }
    }

    .step-col-image {
      width: 500px;
      height: 300px;
      object-fit: cover;

      @include mobile {
        max-width: 100%;
        height: 300px;
        width: 100%;
      }

      @media (min-width: 768px) and (max-width: 920px) {
        margin: 0 auto;
      }
    }

    .video-background {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      max-width: 500px;
      height: 267px;

      @media (max-width: 920px) {
        position: relative;
        padding: 0;
        width: 100%;
        max-width: 100%;
        height: 303px;
      }

      .video-mask {
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
      }

      video {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media (max-width: 920px) {
          width: 100%;
          left: 0;
        }
      }

      .video-controller {
        position: relative;
        top: calc(100% - 70px);
        z-index: 1;
        margin: 0 auto;
        width: 100%;
        max-width: 1200px;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;

        @media (max-width: 920px) {
          position: absolute;
          text-align: left;
        }

        img {
          width: 42px;
          cursor: pointer;
          display: inline-block;
        }
      }
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

#tags-meals-options-map {
  .tag-list {
    margin: 0 auto 24px;
    @media screen and (max-width: 1200px) {
      max-width: 436px;
    }

    .slick-track {
      display: flex;
      justify-content: center;
    }
    .slick-slide {
      .tag-list-element {
        font-family: $cui-font-family-tt-norms;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        text-align: center;
        border-bottom: 2px solid $cui-black-color-200;
        padding: 0 16px 8px;
        cursor: pointer;
        img {
          width: 48px;
          height: 48px;
          margin: 0 auto;
        }
        &.selected {
          border-bottom: 2px solid $cui-black-color-600;
        }
      }
    }
  }
}
.options-map-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  padding: 0 80px;
  max-width: 1200px;
  margin: 0 auto 90px;
  width: 100%;

  @media screen and (max-width: 1200px) {
    padding: 0;
    max-width: 90%;
  }

  @include mobile {
    padding: 0 16px;
  }

  .option-element {
    background-color: $cookunity-white;
    width: fit-content;
    padding: 6px 16px 6px 18px;
    gap: 4;
    display: flex;
    align-items: center;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    height: 53px;
    cursor: pointer;
    color: #26272b;
    text-decoration: none;

    &:hover {
      background-color: $cui-black-color-100;
    }

    p {
      margin: 0;
    }
  }
}

h3 {
  text-align: center;
}

@import '../../scss/mixins';
@import '../../scss/variables';

.heroBottomBarContainer {
  display: flex;
  overflow: hidden;
  width: 100%;
  .bottomBar {
    position: relative;
    width: 100%;
    height: 50px;
    overflow-x: hidden;
    .scrollContainer {
      width: inherit;
      height: inherit;
      position: absolute;
      left: 0%;
      top: 0%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      > div {
        @include mobile {
          min-width: 300px;
        }
      }
    }
    .primary {
      animation: primary 25s linear infinite;
      @include mobile {
        animation: primary 20s linear infinite;
      }
    }
    .secondary {
      animation: secondary 25s linear infinite;
      @include mobile {
        animation: secondary 20s linear infinite;
      }
    }
    @keyframes primary {
      from {
        left: 0%;
      }
      to {
        left: -100%;
      }
    }
    @keyframes secondary {
      from {
        left: 100%;
      }
      to {
        left: 0%;
      }
    }
    @include mobile {
      @keyframes primary {
        from {
          left: 0%;
        }
        to {
          left: -400%;
        }
      }
      @keyframes secondary {
        from {
          left: 400%;
        }
        to {
          left: 0%;
        }
      }
    }
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
      }
      span {
        font-size: 14px;
      }
    }
  }
}

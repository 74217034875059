.cui-icon {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;

  &.button:hover {
    cursor: pointer;
  }

  svg {
    width: 100%;
  }
}

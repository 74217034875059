.cui-image {
  display: flex;
  max-width: 100%;
  object-fit: cover;
  width: 100%;

  &.image-error {
    height: 100%;
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

#boxes-with-mobile-slider {
  padding: 38px 0 71px;

  @include mobile {
    padding-top: 0;
  }

  .container {
    text-align: center;
    padding: 0;

    @include mobile {
      width: 100%;
      padding: 0px;
    }

    h3 {
      color: $cookunity-white;
      margin: 0 0 50px;
      text-align: left;

      @include mobile {
        color: $cui-black-color;
        text-align: center;
        margin: 0;
      }
    }

    .row {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 24px;
      margin: 0;

      @include mobile {
        display: none;
      }
    }

    .box {
      max-width: 342px;
      width: 100%;
      background: $cookunity-white;
      box-shadow: 0px 2px 2px 0px #0000000d;
      box-shadow: 0px 0px 2px 0px #231f201a;
      border-radius: 12px;
      padding: 40px 32px;
      box-sizing: border-box;
      margin: 0;

      @include mobile {
        max-width: 90%;
      }

      @media (min-width: 768px) and (max-width: 1090px) {
        max-width: 240px;
        padding: 22px 20px;
      }

      img {
        margin: 0 auto;
      }

      p.rebrand.lead {
        margin: 0px 0 12px;
        color: $cui-black-color;
        font-family: $cui-font-family-tt-norms;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        text-align: left;
      }

      p {
        color: $cui-black-color-300;
        text-align: left;
      }
    }

    .slick-slider {
      .slick-slide {
        margin: 42px 0px;
      }

      button {
        display: none !important;
      }
    }

    .slick-slide {
      @include mobile {
        margin-bottom: 20px;
      }
    }

    .slick-dots {
      bottom: -20px;
    }

    .slick-dots li.slick-active button:before {
      color: $cui-yellow-color-600;
    }

    .slick-dots li {
      margin: 0 0;
    }

    p.text-boxes {
      max-width: 55%;
      margin: 0 auto;
      width: 100%;
      margin-top: 46px;

      @include mobile {
        max-width: 80%;
      }
    }

    .cui-link {
      margin: 30px auto 0;
    }
  }
}

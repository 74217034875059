@import '../../scss/mixins';
@import '../../scss/variables';

.top20-carrousel {
  min-height: 380px;
  width: 100%;

  .top20-carrousel-title {
    text-align: center;
    margin-bottom: 30px;

    @include mobile {
      padding: 0 15px;
    }

    h2 {
      margin: 0;
      color: $cookunity-black;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      font-family: $cui-font-family-rossanova;
    }
  }

  .slick-list {
    // gives room to shadows
    margin: -10px;
    padding: 10px;

    @media (max-width: 900px) {
      overflow: visible;
    }
  }

  .slick-track {
    display: flex;
  }

  .slick-dots {
    bottom: -40px;
  }

  .slick-dots li.slick-active button:before {
    color: $cui-yellow-color-600;
  }

  .slick-dots li {
    margin: 0 0;
  }

  .slick-slide {
    margin-right: 14px;

    > div > div > div:nth-child(2) > div:nth-child(2) {
      line-height: 1.3;
    }
  }

  .slick-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 2px 0px rgba(35, 31, 32, 0.05),
      0px 0px 2px 0px rgba(35, 31, 32, 0.1);
    z-index: 9;
    width: 40px;
    height: 40px;
    border-radius: 200px;
    background-color: $cookunity-white;
    opacity: 0.7;

    &.slick-prev:before,
    &.slick-next:before {
      content: '';
    }

    &.slick-next {
      img {
        transform: rotate(270deg);
      }
    }

    &.slick-prev {
      img {
        transform: rotate(90deg);
      }
    }

    &:hover {
      color: $cookunity-white;
      background-color: $cookunity-white;
      opacity: 1;
    }

    &.slick-next:focus,
    &.slick-next:hover,
    &.slick-prev:focus,
    &.slick-prev:hover {
      color: $cookunity-white;
      background-color: $cookunity-white;
    }
  }

  form.zip-form-cui.rebrand .input-button {
    margin: 64px auto 0;

    @include mobile {
      width: 95%;
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.Toastify__toast-container {
  @include mobile {
    top: 20px;
    width: 90%;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
  }
  .Toastify__toast {
    padding: 0px 24px;
    gap: 10px;
    border-radius: 8px;
    .Toastify__toast-body {
      .toast-send-mail {
        color: $cui-black-color-600;
        font-family: $cui-font-family-tt-norms;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
    }

    .Toastify__close-button {
      padding: 24px 0;
      width: 24px;
      height: 16px;
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

#how-it-works-cards {
  .how-it-works-cards-container {
    .hiw-items {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 64px;
      gap: 32px;

      .hiw-step {
        width: 296px;
        padding: 8px;
        background: $cookunity-white;

        &--with-border {
          border-radius: 16px;
          border: 1px solid rgba(0, 0, 0, 0.08);
        }

        img {
          width: fit-content;
          max-width: 280px;
          height: fit-content;
          max-height: 248px;
          border-radius: 8px;
        }

        .hiw-step-text {
          margin: 16px auto 0;
          padding: 0 8px 16px;

          .step-text {
            padding-top: 8px;
            color: rgba(0, 0, 0, 0.6);
            font-size: $cui-font-sm;
            line-height: 150%;
            letter-spacing: -0.14px;
            margin: 0;
          }
        }
      }
    }

    p {
      color: $cui-black-color-850;
      font-size: $cui-font-base;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: -0.32px;
    }

    .bottom-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin: 16px auto;
      gap: 12px;
    }

    .promo-text:first-letter {
      text-transform: uppercase;
      color: $cui-black-color-850;
      text-align: center;
      font-size: $cui-font-sm;
    }
  }
}

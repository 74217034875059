@import '../../scss/mixins';
@import '../../scss/variables';

#testimonialsCarrouselCui {
  position: relative;
  font-family: $cui-font-family-tt-norms;
  background: $cui-black-color;
  padding: 54px 0 19px;
  overflow-x: clip;

  h3 {
    color: $cookunity-white;
    width: 383px;
    margin-right: 110px;

    @include mobile {
      width: 100%;
      margin-right: 0;
      padding: 0 20px;
    }

    span {
      color: $cui-yellow-color-500;
      display: block;
    }
  }

  .container {
    display: flex;

    @include mobile {
      flex-direction: column;
    }
  }

  .testimonial-text {
    color: $cui-black-color-200;
    @include mobile {
      margin: 0 auto;
    }

    &:after,
    &:before {
      content: '"';
    }
  }

  .testimonial-sign {
    margin: 0;
    text-align: left;
    color: $cookunity-gray-2;
  }

  .testimonial-content {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;

    @include mobile {
      max-width: 100%;
    }
  }

  .testimonial-name {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
  }

  .testimonial-section {
    max-width: 600px;
    padding: 20px;

    @include mobile {
      max-width: 100%;
    }
  }

  .slick-slider {
    max-width: 500px;

    @include mobile {
      max-width: 100%;
    }
  }

  ul.slick-dots {
    bottom: 20px;
  }

  .slick-dots li button:before {
    color: $cookunity-white;
    font-size: 36px;
  }

  .slick-dots li.slick-active button:before {
    color: $cookunity-white;
    opacity: 0.75;
    font-size: 40px;
  }

  .slick-prev {
    left: 376px;
    top: auto;

    z-index: 1;
    width: 39px;
    height: 39px;
    bottom: 0px;

    @include mobile {
      left: 60%;
    }
  }

  .slick-next {
    right: 12px;
    top: auto;

    z-index: 1;
    position: absolute;
    width: 39px;
    height: 39px;
    bottom: 20px;
    transform: rotate(180deg);
  }

  .stars {
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    .ratingStar {
      width: 16.63px;
      height: 15.84px;
      margin-right: 6px;
    }
  }
}

@import '../../../../scss/mixins';
@import '../../../../scss/variables';

#hero-meal-lp {
  padding: 44px 0 74px;

  @include mobile {
    padding: 0 0 74px;
  }

  .container {
    @include mobile {
      padding: 0 24px;
    }
    .row {
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      margin: 0;

      img.meal-lp-hero-img {
        max-width: 48%;
        width: 100%;

        @include mobile {
          display: none;
        }
        @media (min-width: 767px) and (max-width: 1024px) {
          max-width: 50%;
        }
      }
    }

    .buttons-container {
      justify-content: space-between;
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      button {
        color: $cui-black-color;
        display: flex;
        align-items: center;
        border-radius: 200px;
        background: transparent;
        padding: 7px 20px 7px 16px;

        img {
          width: 20px;
          height: 20px;
          margin-right: 4px;
        }
      }
      button.back {
        background: $cui-black-color-100;
      }
    }

    .container-cui {
      .column-image {
        max-width: 600px;
        width: 100%;

        @media (min-width: 768px) and (max-width: 1200px) {
          max-width: 50%;
        }
        img {
          max-width: 100%;
        }
      }
    }

    #meal-basic-info-cui {
      max-width: 48%;
      @media (min-width: 768px) and (max-width: 1200px) {
        max-width: 50%;
      }

      @include mobile {
        max-width: 100%;
      }

      .meal_story {
        margin: 20px 0;
      }
    }

    .row-name-stars {
      @include mobile {
        flex-direction: row;
      }
    }

    .meal-input-row {
      flex-direction: column;
    }

    @include mobile {
      flex-direction: column;
    }

    .container-promo.only-mobile {
      form.zip-form {
        @include mobile {
          flex-direction: row;
          justify-content: space-between;
        }
      }

      .zip-form .container-input {
        margin-right: 19px;
        width: 375px;

        @include mobile {
          width: 100%;
        }
      }

      .zip-form .zip-btn-cui {
        width: 75%;
      }

      .promo-text {
        margin: 24px 0 12px;
      }
    }

    .container-promo.only-desktop {
      .container-input .input-zip {
        margin-right: 24px;
      }
    }

    .with-fixed-image {
      justify-content: space-between;
      position: relative;
      min-height: 400px;
      align-items: flex-start;

      #meal-basic-info-cui {
        position: absolute;
        right: 0;
        top: 0px;
        background-color: white;

        @include mobile {
          position: relative;
        }

        .info-meal {
          .meal-lp-hero-img {
            display: none !important;
          }
        }
      }

      .column-image {
        position: fixed;
        max-width: 580px;
        width: 100%;
        border-radius: 8px;
        border: 1px solid $cui-black-color-100;

        @media (min-width: 768px) and (max-width: 1200px) {
          max-width: 45%;
        }
        img {
          max-width: 100%;
        }
      }
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.slick-title-cui {
  text-align: center;
  padding: 60px 0;

  @include mobile {
    padding: 52px 20px;
  }

  p.rebrand {
    max-width: 1110px;
    width: 100%;
    margin: 12px auto 0;

    @media (min-width: 768px) and (max-width: 1024px) {
      margin: 24px auto 0;
    }
  }

  p.rebrand.upper {
    font-weight: 700;
  }

  h1.rebrand.text-slick,
  h2.rebrand.text-slick {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: -40px;
    margin-bottom: 0;

    @media (max-width: 1300px) {
      margin-left: 0;
    }

    @include mobile {
      justify-content: center;
      margin-left: 0;
    }

    span {
      color: $cui-black-color;
      position: relative;
      margin: 0;

      @include mobile {
        margin-left: 0;
        text-align: center;
        margin-bottom: 20px;
        padding: 0 10px;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 20px;
      }
    }

    img {
      width: 96px;
      height: 56px;
      position: absolute;
    }
  }

  h1.rebrand.text-slick.center,
  h2.rebrand.text-slick.center {
    justify-content: center;

    span {
      margin-left: 0;
      text-align: center;
      color: $cui-black-color;
      font-family: $cui-font-family-rossanova;
      height: unset;
      width: 100%;
    }
  }

  h1.rebrand.text-slick span,
  h2.rebrand.text-slick.small {
    justify-content: center;
    font-size: 36px;
    line-height: 60px;
    font-weight: 700;
    margin-top: 0;

    @include mobile {
      line-height: 32px;
    }
  }

  &.black-mode {
    h1.rebrand.text-slick span,
    h2.rebrand.text-slick span {
      color: $cookunity-white;

      @include mobile {
        font-size: 28px;
        line-height: 32px;
      }
    }
  }

  .cui-link {
    margin-top: 20px;
  }

  &.two-columns {
    max-width: 432px;
    width: 100%;
    margin: 0 auto;

    @include mobile {
      max-width: 100%;
    }
  }

  &.black-mode,
  &.home-seo {
    padding: 60px 0 28px 0;

    h1,
    h2 {
      padding: 0;
      margin: 0;
    }
  }

  &.two-lines {
    h2.rebrand span {
      line-height: 40px;
      margin: 0 auto;
      width: 740px;

      @include mobile {
        font-size: 24px;
        width: 100%;
      }
    }
  }

  &.carving-component {
    padding: 60px 0 24px 0px;
    @include mobile {
      margin-top: 40px;
      padding: 20px 24px 20px;
    }

    p.rebrand {
      color: $cui-black-color-300;
      @include mobile {
        margin-top: 30px;
      }
    }
  }

  &.green-slick {
    padding: 0px;
    max-width: 800px;
    margin: 0 auto;

    @include mobile {
      padding: 22px 0;
    }

    span {
      line-height: 36px;
      @include mobile {
        margin-bottom: 0px !important;
      }
    }

    p {
      margin: 0 auto 20px;
      max-width: 847px;

      @include mobile {
        padding: 0 8px;
      }
    }

    h2 {
      max-width: 1100px;
      width: 100%;
      margin: 130px auto 20px !important;

      @include mobile {
        margin: 40px auto !important;
      }

      &.small {
        @include mobile {
          line-height: 40px !important;
        }
      }
    }
  }
}

@import '../../../../scss/mixins';
@import '../../../../scss/variables';

#carrousel-team {
  background: $cui-black-color-100;
  padding: 98px 0 110px;

  @include mobile {
    padding: 48px 0;
  }

  .container-cui {
    @include mobile {
      padding: 0;
    }

    h2,
    p {
      text-align: center;
    }

    figure {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0;
      box-shadow: none;

      .team-image {
        height: 118px;
        width: 118px;
        object-fit: cover;
        margin: 0 auto 32px;
      }

      a:hover {
        text-decoration: none;
      }

      figcaption {
        .row-tags {
          margin: 0 0 8px 0;
          flex-direction: row;
          display: flex;
          justify-content: center;
          .tags {
            display: flex;
            align-items: center;

            span {
              margin: 0;
            }
          }
        }

        .link-with-arrow {
          text-decoration-line: underline;
          color: $cui-black-color;
          display: flex;
          justify-content: center;
        }
      }
    }

    div {
      .slick-slider {
        .slick-list {
          .slick-track {
            width: 22410px !important;
            .slick-slide {
              margin: 0px 32px 0px 0;
              width: 255px !important;
            }
          }
        }
      }
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.container-video {
  max-width: 1080px;
  padding: 76px 0;
  margin: 0 auto;
  text-align: center;


  .video {
    aspect-ratio: 16 / 9;
    width: 100%;
  }
}
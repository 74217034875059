@import '../../scss/mixins';
@import '../../scss/variables';

#testimonialsCarrousel {
  .testimonial-section {
    padding: 72px 0 48px;

    @include mobile {
      padding: 72px 0;
    }

    .testimonial-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .rebrand.testimonial-text {
        font-style: italic;
        margin: 0 auto;
        max-width: 487px;
        width: 100%;
        color: $cui-black-color-400;
        margin-bottom: 36px;
        text-align: center;

        @include mobile {
          max-width: 85%;
          margin: 0 auto;
        }

        &:after,
        &:before {
          content: '"';
        }
      }

      .avallon.testimonial-sign {
        color: $cui-black-color;
      }
    }
  }

  ul.slick-dots {
    bottom: 20px;

    li {
      margin: 0;
    }
  }

  .slick-dots li button:before {
    font-size: 40px;
    color: $cookunity-gray-2;
    opacity: 0.5;
  }

  .slick-dots li.slick-active button:before {
    color: $cui-yellow-color-600;
    font-size: 48px;
  }

  .slick-prev {
    left: 20%;
    top: auto;
    z-index: 1;
    width: 39px;
    height: 39px;
    bottom: 40px;
    position: absolute;
  }

  .slick-next {
    right: 20%;
    top: auto;
    z-index: 1;
    position: absolute;
    width: 39px;
    height: 39px;
    bottom: 60px;
    transform: rotate(180deg);
  }
}

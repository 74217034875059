@import '../../../scss/variables';
@import '../../../scss/frontLib/mixins/buttons';

.cui-link {
  align-items: center;
  appearance: none;
  border-radius: 200px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  gap: 6px;
  text-decoration: none;
  font-family: $cui-font-family-tt-norms;
  font-size: $cui-font-button-medium;
  font-weight: $cui-font-bold;
  line-height: $cui-line-height-xl;

  // Sizes
  &-small {
    font-size: $cui-font-button-small;
    padding: $cui-space-button-small;
    max-height: $cui-height-button-small;
  }
  &-medium {
    padding: $cui-space-button-medium;
    max-height: $cui-height-button-medium;
  }
  &-large {
    font-size: $cui-font-button-large;
    padding: $cui-space-button-large;
    max-height: $cui-height-button-large;
  }

  // Colors
  &-primary {
    &-solid {
      @include build-button-state(
        hover,
        $cui-yellow-color-600,
        $cui-yellow-color-600,
        $cui-black-color
      );
      @include build-button-state(
        focus,
        $cui-yellow-color-600,
        $cui-yellow-color-500,
        $cui-black-color
      );
      @include build-button-state(
        disabled,
        $cui-black-color-300,
        $cui-black-color-300,
        $cookunity-white
      );

      @include build-button(
        // default
        $cui-yellow-color-500,
        $cui-yellow-color-500,
        $cui-black-color,
        // loading
        $cui-yellow-color-500,
        $cui-yellow-color-500,
        $cui-black-color
      );
    }

    &-outline {
      @include build-button-state(
        hover,
        $cookunity-white,
        $cui-black-color-400,
        $cui-black-color-400
      );
      @include build-button-state(
        focus,
        $cui-black-color-150,
        $cui-yellow-color-500,
        $cui-black-color-400
      );
      @include build-button-state(
        disabled,
        $cookunity-white,
        $cui-black-color-300,
        $cui-black-color-300
      );

      @include build-button(
        // default
        $cookunity-white,
        $cui-black-color,
        $cui-black-color,
        // loading
        $cookunity-white,
        $cui-black-color,
        $cui-black-color
      );
    }

    &-clear {
      @include build-button-state(
        hover,
        $cui-black-color-150,
        $cui-black-color-150,
        $cui-black-color-400
      );
      @include build-button-state(
        focus,
        $cui-black-color-150,
        $cui-yellow-color-500,
        $cui-black-color-400
      );
      @include build-button-state(
        disabled,
        transparent,
        transparent,
        $cui-black-color-300
      );

      @include build-button(
        // default
        transparent,
        transparent,
        $cui-black-color,
        // loading
        transparent,
        transparent,
        $cui-black-color
      );
    }
  }
  &-secondary {
    &-solid {
      @include build-button-state(
        hover,
        $cui-wine-color-700,
        $cui-wine-color-700,
        $cookunity-white
      );
      @include build-button-state(
        focus,
        $cui-wine-color-500,
        $cui-wine-color-700,
        $cookunity-white
      );
      @include build-button-state(
        disabled,
        $cui-black-color-300,
        $cui-black-color-300,
        $cookunity-white
      );

      @include build-button(
        // default
        $cui-wine-color-500,
        $cui-wine-color-500,
        $cookunity-white,
        // loading
        $cui-black-color-400,
        $cui-black-color-400,
        $cookunity-white
      );
    }

    &-outline {
      @include build-button-state(
        hover,
        $cookunity-white,
        $cui-black-color-400,
        $cui-black-color-400
      );
      @include build-button-state(
        focus,
        $cui-black-color-150,
        $cui-wine-color-700,
        $cui-black-color-400
      );
      @include build-button-state(
        disabled,
        $cookunity-white,
        $cui-black-color-300,
        $cui-black-color-300
      );

      @include build-button(
        // default
        $cookunity-white,
        $cui-black-color,
        $cui-black-color,
        // loading
        $cookunity-white,
        $cui-black-color,
        $cui-black-color
      );
    }

    &-clear {
      @include build-button-state(
        hover,
        $cui-black-color-150,
        $cui-black-color-150,
        $cui-black-color-400
      );
      @include build-button-state(
        focus,
        $cui-black-color-150,
        $cui-wine-color-700,
        $cui-black-color-400
      );
      @include build-button-state(
        disabled,
        transparent,
        transparent,
        $cui-black-color-300
      );

      @include build-button(
        // default
        transparent,
        transparent,
        $cui-black-color,
        // loading
        transparent,
        transparent,
        $cui-black-color
      );
    }
  }
  &-dark {
    &-solid {
      @include build-button-state(
        hover,
        $cui-black-color-400,
        $cui-black-color-400,
        $cookunity-white
      );
      @include build-button-state(
        focus,
        $cui-black-color-400,
        $cui-black-color,
        $cookunity-white
      );
      @include build-button-state(
        disabled,
        $cui-black-color-300,
        $cui-black-color-300,
        $cookunity-white
      );

      @include build-button(
        // default
        $cui-black-color,
        $cui-black-color,
        $cookunity-white,
        // loading
        $cui-black-color-400,
        $cui-black-color-400,
        $cookunity-white
      );
    }

    &-outline {
      @include build-button-state(
        hover,
        $cookunity-white,
        $cui-black-color-400,
        $cui-black-color-400
      );
      @include build-button-state(
        focus,
        $cui-black-color-150,
        $cui-black-color-400,
        $cui-black-color
      );
      @include build-button-state(
        disabled,
        $cookunity-white,
        $cui-black-color-300,
        $cui-black-color-300
      );

      @include build-button(
        // default
        $cookunity-white,
        $cui-black-color,
        $cui-black-color,
        // loading
        $cookunity-white,
        $cui-black-color,
        $cui-black-color
      );
    }

    &-clear {
      @include build-button-state(
        hover,
        $cui-black-color-150,
        $cui-black-color-150,
        $cui-black-color-400
      );
      @include build-button-state(
        focus,
        $cui-black-color-150,
        $cui-black-color-400,
        $cui-black-color-400
      );
      @include build-button-state(
        disabled,
        transparent,
        transparent,
        $cui-black-color-300
      );

      @include build-button(
        // default
        transparent,
        transparent,
        $cui-black-color,
        // loading
        transparent,
        transparent,
        $cui-black-color
      );
    }
  }
  &-light {
    &-solid {
      @include build-button-state(
        hover,
        $cui-black-color-100,
        $cui-black-color-100,
        $cui-black-color
      );
      @include build-button-state(
        focus,
        $cui-black-color-100,
        $cui-black-color-200,
        $cui-black-color
      );
      @include build-button-state(
        disabled,
        $cui-black-color-300,
        $cui-black-color-300,
        $cookunity-white
      );

      @include build-button(
        // default
        $cookunity-white,
        $cookunity-white,
        $cui-black-color,
        // loading
        $cookunity-white,
        $cookunity-white,
        $cui-black-color-400
      );
    }

    &-outline {
      @include build-button-state(
        hover,
        transparent,
        $cookunity-white,
        $cookunity-white,
      );
      @include build-button-state(
        focus,
        transparent,
        $cookunity-white,
        $cookunity-white,
      );
      @include build-button-state(
        disabled,
        transparent,
        $cui-black-color-300,
        $cui-black-color-300
      );

      @include build-button(
        // default
        transparent,
        $cookunity-white,
        $cookunity-white,
        // loading
        transparent,
        $cookunity-white,
        $cookunity-white
      );
    }

    &-clear {
      @include build-button-state(
        hover,
        transparent,
        transparent,
        $cookunity-white
      );
      @include build-button-state(
        focus,
        transparent,
        $cui-black-color-100,
        $cui-black-color-100
      );
      @include build-button-state(
        disabled,
        transparent,
        transparent,
        $cui-black-color-300
      );

      @include build-button(
        // default
        transparent,
        transparent,
        $cookunity-white,
        // loading
        transparent,
        transparent,
        $cookunity-white
      );
    }
  }

  &:disabled {
    @include build-disabled-button();
  }

  &-underline {
    text-decoration: underline;
  }

  &-border {
    border-style: none;
  }
}

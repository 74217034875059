@import '../../scss/variables';

#cookunity-for-org {
  .b2b-lp {
    p.rebrand {
      margin-top: 10px;
    }
  }

  .b2b-form-container {
    max-width: 1080px;
    padding: 0 5%;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  #how-it-works-video-cui.isBlackMode .step-col-50 p.rebrand.step-text {
    text-align: left;
  }

  #how-it-works-video-cui.isBlackMode .step-col-50 p.rebrand.lead {
    text-align: left;
  }

  .our-commitment-container {
    padding-bottom: 20px;
    background-color: $cui-black-color-100;
  }

  .trust-pilot-container {
    padding-bottom: 50px;
    background: $cui-black-color-600;
    color: $cookunity-white;

    .button {
      margin-top: 50px;
    }
  }

  .how-to-order-container {
    background-color: $cui-black-color-600;
    padding-bottom: 50px;

    .how-to-order {
      .slick-slide {
        padding: 0 10px 0 10px;
      }
    }
  }

  .serving-container {
    p.rebrand.lead,
    p.subtitle {
      color: $cookunity-white;
    }
    .cui-link {
      border: none;
      font-size: $cui-font-base;
    }
  }

  .list-benefits .figure-image.four-card {
    @media screen and (max-width: 767px) {
      height: 520px;
    }
  }
}

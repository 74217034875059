@import '../../scss/variables';

.no-img-container {
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;
}

.txt-noimage {
  font-family: $cui-font-family-tt-norms;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  color: $cookunity-black;
  position: absolute;
  z-index: 1;
  top: 50%;
  margin: 0;
}

@media screen and (max-width: 767px) {
  .txt-noimage {
    font-size: 14px;
    top: 38%;
  }
}

@import '../../../scss/variables';

.benefits-wrapper {
  .image-value-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 100px;
    margin-bottom: 90px;
    max-width: 1082px;
    margin: 0 auto 90px;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    @media (max-width: 1200px) {
      justify-content: center;
    }

    .container-benefits {
      max-width: 294px;
      width: 100%;

      @media (max-width: 767px) {
        max-width: 100%;
        padding: 20px;
      }

      .chef-text {
        font-size: 20px;
        line-height: 24px;
        height: 96px;
        margin-bottom: 0;

        span {
          font-size: 16px;
          font-style: italic;
        }
      }

      p {
        text-align: center;
      }

      .text {
        min-height: 96px;
        margin: 0;
        margin-bottom: 12px;

        @media (max-width: 767px) {
          height: unset;
        }
      }

      img {
        margin: 0 auto;
        max-width: 250px;
        max-height: 198px;
      }

      .container-button-bottom {
        margin-top: 0;

        .link-cui {
          width: 120px;
          max-width: 100%;
          padding: 9px 0px;
          text-decoration: none;
          margin: 0 auto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }

  .bottom-text {
    text-align: center;
    font-style: italic;
    font-weight: 450;
    line-height: 24px;
    color: $cui-black-color-400;
  }
}

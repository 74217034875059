@import '../../scss/mixins';
@import '../../scss/variables';

#MeetVideoCUI {
  padding-top: 0;
  background-color: $cui-black-color;

  &.home-seo {
    @include mobile {
      padding-bottom: 40px;
    }

    .container {
      @include mobile {
        padding: 0;
      }
    }

    .content {
      max-width: 500px;

      p.rebrand {
        max-width: 400px;
        width: 100%;
      }
    }

    .home-seo-text {
      color: $cookunity-white;
      max-width: 80%;
      margin: 0 auto;
    }
  }

  .content {
    color: $cookunity-white;
    margin-left: 0px;
    max-width: 100%;

    @include mobile {
      margin-left: 0;
      margin-bottom: 0px;
      text-align: center;
    }

    h2.rebrand {
      position: relative;
      margin: 64px 0 30px;
      font-weight: 300;
      display: flex;
      gap: 16px;
      align-items: center;
      width: 300px;
      font-family: $cui-font-family-rossanova;
      font-size: 60px;

      @include mobile {
        justify-content: center;
        font-size: 36px;
        width: 220px;
        margin: 44px auto 16px;

        &:before {
          height: 45px;
          top: -25px;
          left: -25px;
        }

        &:after {
          width: 45px;
          top: -25px;
          left: -25px;
        }

        > br {
          display: none;
        }
      }

      .avallon {
        color: $cui-yellow-color-500;
        font-family: $cui-font-family-avallon;
        font-size: 100px;
        margin-top: 18px;

        @include mobile {
          font-size: 50px;
          margin-top: 12px;
        }
      }
    }

    p.rebrand {
      margin-bottom: 70px;
      color: $cookunity-white;
      max-width: 340px;

      @include mobile {
        margin-bottom: 50px;
        margin-top: 0;
      }
    }

    .cui-link {
      color: $cookunity-white;
      padding: 12px 55px;
    }
  }

  .video-player {
    width: 120%;
    position: relative;
    left: -10%;

    @include mobile {
      width: 100%;
      left: 0;
    }

    @media screen and (max-width: 1200px) {
      width: 100%;
      left: 0;
    }

    video {
      width: 100%;
    }
  }

  .ctaContainer {
    margin: 60px 0 40px;
    text-align: center;

    @include mobile {
      display: block;
    }

    .cui-link {
      color: $cookunity-white;
      padding: 12px 55px;
    }
  }
}

#MeetOurChefsVideo.with-padding-top {
  padding-top: 92px;
}

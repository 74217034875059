@import '../../scss/variables';

.meal_widget {
  overflow: hidden;
  background-color: $cookunity-black;
  border-radius: 12px;
  padding: 14px;
  display: grid;
  grid-template-columns: 110px 1fr;
  gap: 14px;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 137px;
  max-width: 515px;
  min-width: 290px;

  .meal_name {
    -webkit-line-clamp: 2;
  }

  @media (max-width: 370px) {
    height: 172px;

    .meal_name {
      -webkit-line-clamp: 3;
    }
  }

  .meal_image {
    aspect-ratio: 1 / 1;
    width: 110px;
    height: 110px;
    min-height: 110px;
    object-fit: cover;
    border-radius: 12px;
    cursor: pointer;
  }

  .meal_info {
    padding: 7px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;

    .shareable_link {
      text-decoration: none;
    }

    .cui_logo {
      height: auto;
      @import '../../scss/variables';

      .meal_widget {
        overflow: hidden;
        background-color: $cookunity-black;
        border-radius: 12px;
        padding: 14px;
        display: grid;
        grid-template-columns: 110px 1fr;
        gap: 14px;
        align-items: center;

        &.size_large {
          width: 515px;
          height: 137px;

          .meal_name {
            -webkit-line-clamp: 2;
          }
        }

        &.size_small {
          width: 290px;
          height: 172px;
          .meal_name {
            -webkit-line-clamp: 3;
          }
        }

        .meal_image {
          aspect-ratio: 1 / 1;
          width: 110px;
          height: 110px;
          min-height: 110px;
          object-fit: cover;
          border-radius: 12px;
          cursor: pointer;
        }

        .meal_info {
          padding: 7px 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-self: stretch;

          .shareable_link {
            text-decoration: none;
          }

          .cui_logo {
            height: auto;
            width: 75px;
            margin-bottom: 9px;
            cursor: pointer;
          }

          .meal_name {
            font-weight: 700;
            font-size: 14px;
            color: $cookunity-white;
            line-height: 18px;
            margin: 0;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
          small {
            font-size: 12px;
            color: $cui-black-color-300;
            line-height: 14px;
          }

          .cui_link {
            color: $cookunity-white;
            font-size: 10px;
            font-weight: regular;
            cursor: pointer;
          }
        }
      }

      width: 75px;
      margin-bottom: 9px;
      cursor: pointer;
    }

    .meal_name {
      font-weight: 700;
      font-size: 14px;
      color: $cookunity-white;
      line-height: 18px;
      margin: 0;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
    small {
      font-size: 12px;
      color: $cui-black-color-300;
      line-height: 14px;
    }

    .cui_link {
      color: $cookunity-white;
      font-size: 10px;
      font-weight: regular;
      cursor: pointer;
    }
  }
}

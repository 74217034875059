@import '../../scss/mixins';
@import '../../scss/variables';

.filters {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  font-family: $cui-font-family-tt-norms;
  font-style: normal;

  @include mobile {
    justify-content: flex-start;
    margin-top: 48px;
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1030px) {
    width: 100%;
  }

  .button-filters,
  .filters-list {
    padding: 7px 12px;
    background: $cookunity-white;
    border: 1px solid $cui-black-color-200;
    box-sizing: border-box;
    border-radius: 6px;
    width: 242px;
  }

  .with-cursor {
    cursor: pointer;
  }

  .filtersRow {
    align-items: center;
    display: flex;

    @include mobile {
      flex-direction: row;
    }

    .button-filters,
    p {
      font-weight: normal;
      font-size: 14px;
    }

    p {
      display: inline-block;
      line-height: 18px;
    }

    .button-filters {
      display: flex;
      justify-content: space-between;
      line-height: 24px;
      margin-left: 20px;

      .arrow-filters {
        transform: rotate(180deg);
      }
    }
  }

  .selected-filter {
    border: 1px solid $cui-black-color-600;
  }

  .filters-list {
    position: absolute;
    z-index: 2;
    top: 24px;
    padding: 25px 20px 9px;
    right: 0px;

    @include mobile {
      left: 76px;
      top: 0px;
    }

    .section-filters {
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
      color: $cookunity-gray-2;
      padding: 24px 0 14px;
      border-top: 1px solid $cui-black-color-200;
      margin: 0;
    }

    li {
      margin: 0px -20px 16px;
      padding: 0px 20px;
      display: flex;
      align-items: center;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $cui-black-color-400;
      height: 24px;
      cursor: pointer;
    }

    li.selected,
    li:hover {
      color: $cookunity-black;
      font-weight: 800;
    }
  }

  ul.filters-list {
    display: flex;
    flex-direction: column;
    .selected {
      border-bottom: none !important;
    }
  }

  &.with-tabs {
    @media (min-width: 768px) and (max-width: 1300px) {
      width: 100% !important;
    }
    .sub-tab-list {
      list-style: none;
      width: 100%;
      margin: 0 0 34px;
      display: block;
      padding: 0;
      @media (min-width: 768px) and (max-width: 1300px) {
        padding: 0 20px;
      }

      @include mobile {
        padding: 0;
      }

      .hcw-list {
        width: 100%;
        background-color: $cui-black-color-100;
        padding: 17px 40px;
        border-radius: 12px;

        @include mobile {
          border-radius: 0;
          padding: 17px;
        }

        .slick-disabled {
          display: none !important;
        }

        .slick-prev {
          left: 14px;
        }

        .slick-next {
          right: 14px;
        }

        .slick-arrow {
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0px 2px 2px 0px rgba(35, 31, 32, 0.05),
            0px 0px 2px 0px rgba(35, 31, 32, 0.1);
          z-index: 9;

          &.slick-prev:before,
          &.slick-next:before {
            content: '';
          }

          &.slick-next {
            img {
              transform: rotate(180deg);
            }
          }

          &:hover {
            opacity: 1;
            color: $cookunity-white;
            background-color: $cookunity-white;
          }

          &.slick-next:focus,
          &.slick-next:hover,
          &.slick-prev:focus,
          &.slick-prev:hover {
            opacity: 1;
            color: $cookunity-white;
            background-color: $cookunity-white;
          }
        }

        .slick-prev,
        .slick-next {
          width: 34px;
          height: 34px;
          border-radius: 200px;
          background: $cookunity-white;
          opacity: 1;
          border: $cookunity-white;

          @include mobile {
            display: none;
          }
        }
      }

      .slick-track {
        display: flex;
        justify-content: center;
      }

      .slick-slide {
        margin-right: 20px;
      }

      .tab-list-element {
        border-radius: 200px;
        color: $cui-black-color-400;
        padding: 0px 16px;
        width: max-content !important;
        font-family: $cui-font-family-tt-norms;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 0;
        display: flex !important;
        align-items: center;
        cursor: pointer;
        margin: 0;
        box-sizing: border-box;
        height: 44px;

        img {
          display: block;
          width: 35px;
          height: 35px;
        }

        &.selected {
          border: 1px solid $cui-black-color;
          background-color: $cookunity-white;
        }
      }
    }
  }
}

#common-questions {
  .section-title {
    margin: 30px 0 20px;
  }

  @media (max-width: 768px) {
    .section-title {
      margin-bottom: 10px;
      text-align: center;
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.sustainability-page {
  .stickyBoundary {
    .sustainability-component {
      margin-bottom: 50px;

      > .container-cui-row {
        max-width: 1100px;

        .col-media {
          height: unset;
        }
      }
    }
  }
  > .container-cui > .sustainability-ingredients {
    max-width: 900px;
    margin: 0 auto;
  }

  > .container-cui > .sustainability-partners {
    margin: 0 auto;
    max-width: 900px;
    .icon-list {
      flex-wrap: wrap;
      justify-content: center;
      gap: 24px;

      > .icon-item > img {
        max-height: 70px;
        max-width: 250px;
      }
    }
  }
}

@import '../../../scss/variables';

.loading-indicator-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.loading-indicator {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
}
.loading-indicator div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 0;
  border: 3px solid;
  border-radius: 50%;
  animation: loading-indicator-frame 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $cookunity-black transparent transparent transparent;
}
.loading-indicator div:nth-child(1) {
  animation-delay: -0.45s;
}
.loading-indicator div:nth-child(2) {
  animation-delay: -0.3s;
}
.loading-indicator div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loading-indicator-frame {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

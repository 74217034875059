@import '../../../../scss/mixins';
@import '../../../../scss/variables';

#join-us-cui {
  padding: 0px 0 100px;
  background-color: $cookunity-white;
  background-size: 100% auto;
  overflow-x: hidden;

  @include mobile {
    padding: 38px 20px 76px;
    height: 100%;
  }
  .container-cui {
    .value-container {
      margin-top: 10px;
      justify-content: center;
      flex-wrap: wrap;

      .image-value-container {
        width: 180px;
        text-align: center;
        margin: 77px 40px 0;
      }
    }

    .jobs-container {
      padding: 0 20px;

      @include mobile {
        padding: 0;
      }
    }

    .container-cui-row {
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 0px auto 42px;
      gap: 25px;

      .Select:first-child {
        justify-self: flex-end;
      }
      .Select:last-child {
        justify-self: flex-start;
      }

      span {
        font-family: $cui-font-family-tt-norms;
        font-style: normal;
        font-weight: 800;
      }

      @include mobile {
        width: 100%;
        grid-template-columns: none;
        grid-template-rows: 1fr auto 1fr;
        grid-gap: 12px;
        justify-content: center;

        .Select:first-child {
          justify-self: center;
        }
        .Select:last-child {
          justify-self: center;
        }
      }
    }

    .orSeparator {
      font-size: 20px;
      font-weight: bold;
    }
  }

  .message {
    text-align: center;
    margin-top: 64px;
    font-family: $cui-font-family-tt-norms;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
  }
}

@import '../../../../scss/mixins';
@import '../../../../scss/variables';

.heroPortraitsContainer {
  display: flex;
  flex-direction: column;
  background-color: $cui-black-color-600;
  align-items: center;
  .contentContainer {
    display: flex;
    position: relative;
    flex-direction: row;
    max-width: 1320px;
    width: 100%;
    box-sizing: border-box;
    height: 464px;
    background-color: $cui-black-color;
    justify-content: space-between;
    gap: 1%;
    @include mobile {
      width: 100%;
      flex-direction: column;
      padding-top: 20px;
      height: 100%;
      .textContainer {
        order: 2;
        width: 100% !important;
      }
    }
    .textContainer {
      display: flex;
      width: 60%;
      flex-direction: column;
      justify-content: center;
      padding: 3% 5%;
      padding-left: 3%;
      @include mobile {
        align-items: center;
        justify-content: flex-end;
        margin: 0;
        padding-left: 5%;
        padding-bottom: 8%;
      }
      .heroTitle {
        font-family: $cui-font-family-rossanova;
        font-style: normal;
        color: $cookunity-white;
        font-weight: bold;
        font-size: 45px;
        line-height: 1em;
        span {
          font-family: $cui-font-family-avallon;
          color: $cui-yellow-color-500;
          display: inline;
          font-size: 85px;
          line-height: 100%;
          letter-spacing: -0.02em;
        }
        @include mobile {
          display: flex;
          flex-direction: column;
          text-align: center;
          font-size: 30px;
          width: 100%;
          span {
            font-size: 50px;
          }
        }
      }
      p {
        font-family: $cui-font-family-tt-norms;
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 24px;
        color: $cookunity-white;
        width: 460px;
        @include mobile {
          width: 100%;
          text-align: center;
        }
      }
      .container-input {
        background-color: $cookunity-white;
      }
      button {
        height: 48px;
        display: inline-block;
        color: $cui-black-color;
        background: $cui-yellow-color-500;
        font-family: $cui-font-family-tt-norms;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
      }
    }
    .mediaContainer {
      display: flex;
      width: 50%;
      flex-direction: row;
      align-items: center;
      padding-right: 4%;
      gap: 20px;
      white-space: nowrap;
      overflow: hidden;
      scroll-behavior: smooth;
      @include mobile {
        order: 1;
        margin: 0;
        width: 100%;
        align-items: center;
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 0 15%;
        div,
        video {
          flex: 0 0 auto;
          scroll-snap-align: start;
        }
        div {
          min-width: 256px !important;
          min-height: 373px !important;
          img {
            border-radius: 12px !important;
          }
        }
        video {
          width: 256px !important;
          height: 373px !important;
        }
      }
      div {
        min-width: 283px;
        min-height: 412px;
        position: relative;
        img {
          border-radius: 12px;
        }
      }
      video {
        display: flex;
        width: 283px;
        height: 412px;
        border-radius: 12px;
        object-fit: cover;
      }
    }
    .mediaContainer::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 200px; /* gradient width */
      background: linear-gradient(
        to right,
        rgba($cui-black-color, 0),
        rgba($cui-black-color, 1)
      );
      @include mobile {
        top: 20px;
        height: 375px;
        background: linear-gradient(
          to right,
          rgba($cui-black-color, 0),
          rgba($cui-black-color, 1)
        );
      }
    }
    .carouselButton {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: none;
      color: $cookunity-white;
      font-size: 20px;
      z-index: 2;
      padding: 10px;
      @include mobile {
        display: none;
      }
    }

    .carouselButton.left {
      left: 50%;
      visibility: hidden;
      img {
        transform: rotate(180deg);
      }
    }
    .carouselButton.right {
      right: 1rem;
      @include mobile {
        right: 0.5rem;
      }
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.row-tags {
    margin: 0 0 30px 0;
    flex-direction: row;
    .tags {
      display: flex;
      align-items: center;

      span {
        margin: 0 8px;
      }
    }
  }
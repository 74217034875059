@import '../../scss/mixins';
@import '../../scss/variables';

.mealcard-new-mini {
  cursor: pointer;
  width: 144px;
  height: 220px;
  border: 1px solid $cui-black-color-150;
  padding: 12px;
  border-radius: 8px;
  position: relative;
  box-sizing: border-box;
  background-color: $cookunity-white;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 0px 12px rgba(35, 31, 32, 0.1);
  }

  @include mobile {
    max-width: 100%;
    min-width: unset;
    margin: 0 auto;
  }

  &.first-meal-card {
    background-color: $cookunity-black;

    .mealcard-mini-description,
    .mealcard-mini-chef__name,
    .cui-top-tag {
      color: $cookunity-white;

      p,
      b {
        color: $cookunity-white;
      }
    }

    .cui-top-tag {
      background-color: $cookunity-black;
      padding: 2px 8px;
      border: none;

      img {
        width: 16px;
        height: 16px;
      }

      p {
        font-size: 18px;
      }
    }
  }

  .mealcard-mini-chef {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__image {
      width: 20px;
      height: 20px;
      margin-right: 4px;
      border-radius: 200px;
      object-fit: cover;
    }

    &__name {
      margin: 0;
      font-family: $cui-font-family-tt-norms;
      font-size: 10px;
      font-style: normal;
      font-weight: 450;
      line-height: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .mealcard-mini-description {
    height: 56px;
    text-align: left;
    color: $cui-black-color-300;
    font-family: $cui-font-family-tt-norms;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 4px;

    b {
      display: inline;
      color: $cookunity-black;
      font-family: $cui-font-family-tt-norms;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      height: 40px;
      overflow: auto;
      white-space: initial;
    }
  }

  &__image {
    position: relative;

    .cui-image.cui-meal-image {
      min-width: 120px;
      height: 100px;
      border-radius: 8px;
      margin-bottom: 13px;
    }

    .cui_meal_card_premium_tag_wrapper {
      position: absolute;
      top: -4px;
      left: -4px;
      z-index: 1;

      .cui_meal_card_premium_tag {
        background-color: $cookunity-black;
        color: $cookunity-white;
        z-index: 1;
        display: flex;
        height: 20px;
        padding: 2px 6px;
        align-items: center;
        gap: 5px;
        border-radius: 2px;
        width: fit-content;
        font-family: $cui-font-family-tt-norms;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        img {
          &:hover {
            transform: unset !important;
          }
        }
      }
    }

    .cui-top-tag {
      position: absolute;
      border-radius: 0px 8px 8px 0px;
      border-top: 0.5px solid $cui-black-color-200;
      border-right: 0.5px solid $cui-black-color-200;
      border-bottom: 0.5px solid $cui-black-color-200;
      background: $cookunity-white;
      width: fit-content;
      height: 24px;
      display: flex;
      align-items: center;
      padding: 4px 8px;
      gap: 4px;
      box-sizing: border-box;
      bottom: -8px;
      left: -12px;

      img {
        width: 12px;
        height: 12px;
      }

      p {
        font-family: $cui-font-family-tt-norms;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        margin: 0;
      }
    }
  }
}

@import '../../../../scss/mixins';
@import '../../../../scss/variables';

.heroRadiusImageContainer > .contentWrapper.with-video {
  margin-bottom: 50px;

  .textContainer {
    background: linear-gradient(
      to right,
      $cui-black-color -10%,
      transparent 95%
    );

    @include mobile {
      background: linear-gradient(
        to top,
        $cui-black-color 20%,
        transparent 80%
      );
    }
  }
}

@import '../../../../scss/mixins';
@import '../../../../scss/variables';

#banner-with-icons.rebrand.black {
  background-color: $cui-black-color-600;
  padding: 57px 20px 86px;
  height: 191px;

  @include mobile {
    height: 100%;
  }

  p {
    color: $cookunity-white;
    text-align: center;
  }

  .container-cui-row {
    max-width: 1200px;

    @include mobile {
      flex-direction: column;
      gap: 40px;
    }
  }
}

#banner-with-icons {
  padding: 0px 0 56px;

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 0 10px 56px;
  }

  .container-cui-row {
    @include mobile {
      flex-direction: column;
      gap: 35px;
    }

    div {
      @media (min-width: 768px) and (max-width: 1024px) {
        margin: 0 10px;
      }
    }

    a {
      cursor: pointer;
    }
  }

  &.small-mobile {
    .container-cui-row {
      @include mobile {
        padding: 0 90px;
        gap: 0;
      }
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.referral_page_chefs {
  background-color: $cui-black-color-100;
  padding: 17px 0;
  @media screen and (max-width: 1048px) {
    padding: 113px 0 19px;
  }
  .chefs_images {
    img {
      margin-left: -100px;
      min-width: 720px;
      @media screen and (max-width: 1048px) {
        width: 100%;
        min-width: 100%;
        margin-top: 70px;
        margin-left: 0px;
      }

      @media screen and (max-width: 1300px) {
        margin-left: 0px;
        min-width: 100%;
      }
    }
  }

  .referral_page_chefs_container {
    max-width: 1200px;
    width: 100%;
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;

    @media screen and (max-width: 1048px) {
      padding: 0;
    }

    .chefs_details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 20%;
      margin: 0 0 0 10%;
      @media screen and (max-width: 1048px) {
        width: 60%;
        margin: 0 auto;
      }
      .chefs_details_title {
        font-family: $cui-font-family-tt-norms;
        font-style: normal;
        font-weight: 800;
        font-size: 28px;
        line-height: 36px;
        color: $cookunity-black;
        margin: 0;
      }
      .chefs_details_description {
        font-family: $cui-font-family-tt-norms;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $cui-black-color-400;
      }
    }

    @media screen and (max-width: 1048px) {
      flex-direction: column;
      flex-flow: column-reverse;
    }
  }
}

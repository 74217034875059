@import '../../scss/variables';

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 74px;
  width: 100%;
  position: relative;

  max-width: 1162px;
  overflow: hidden;
  margin: 0 auto;

  @media (max-width: 1159px) {
    justify-content: center;
  }

  .a-brand {
    display: none;
  }

  img {
    cursor: pointer;
  }

  div.navbar-menu-cui.no-sticky {
    position: absolute;
    left: 0;
    background-color: transparent;
    margin-top: 15px;

    @media (min-width: 1160px) {
      display: none;
    }
  }

  .nav-icon.menu-icon {
    position: relative;
    display: flex;
    justify-content: center;

    &:before {
      content: '';
      position: absolute;
      top: 6px;
      border-radius: 4px;
      width: 20px;
      height: 3px;
      background-color: $cui-black-color-400;
    }

    &:after {
      content: '';
      position: absolute;
      top: 14px;
      border-radius: 4px;
      width: 20px;
      height: 3px;
      background-color: $cui-black-color-400;
    }
  }

  .navbar-container {
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      margin: 0;
      padding: 0;
      gap: 40px;

      @media (max-width: 1280px) {
        gap: 32px;
      }
    }
    li {
      font-size: $cui-font-sm;
      font-weight: 450;
      color: $cui-black-color-400;
      cursor: pointer;
    }

    @media (max-width: 1159px) {
      display: none;
    }
  }

  .login-link-cui {
    @media (max-width: 1159px) {
      display: none;
    }
  }
}

.trustpilot {
  width: 100%;
  .with-container {
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    background: $cui-black-color-50;
    max-width: 1280px;
    width: 90%;
    padding: 80px 40px;
    margin: 0 auto;

    @include mobile {
      max-width: 90%;
      padding: 32px 20px 24px 20px;
    }

    h2,
    p {
      font-style: normal;
      line-height: 150%;
    }

    h2 {
      margin: 0;
      color: $cui-black-color-850;
      font-family: $cui-font-family-tt-norms;
      font-size: $cui-font-2xl;
      font-weight: 500;
      letter-spacing: -0.48px;
      margin: 0 auto 10px;
      text-align: center;

      @include mobile {
        font-size: 20px;
      }
    }

    p {
      color: rgba(0, 0, 0, 0.6);
      text-align: center;
      font-family: $cui-font-family-tt-norms;
      font-size: $cui-font-sm;
      font-weight: 450;
      letter-spacing: -0.14px;
      margin: 0 auto 31px;
      max-width: 514px;
      width: 100%;

      @include mobile {
        max-width: 100%;
      }
    }
  }
}

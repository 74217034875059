@import '../../scss/mixins';
@import '../../scss/variables';

.promo-overlay {
  .ReactModal__Content {
    border-radius: 8px !important;
    width: 633px;
    height: 410px;
    padding: 0px !important;
    border: none !important;

    @include mobile {
      top: 15vh !important;
      width: 90%;
      height: fit-content;
    }

    .topContainer {
      width: 40px;
      right: 0;
      position: absolute;
      padding: 12px 12px 0 0;

      @include mobile {
        padding: 10px 10px 0px 0px;
        z-index: 99;
      }

      img {
        width: 24px;

        @include mobile {
          background: $cookunity-white;
          border-radius: 200px;
        }
      }
    }
  }
}

.promo-popup {
  width: 100%;
  height: 100%;
  display: flex;

  @include mobile {
    flex-direction: column;
  }

  > img {
    object-fit: cover;
    width: 200px;
    height: 100%;
    @include mobile {
      width: 100%;
      height: 200px;
    }
  }

  div.promo-popup-text {
    width: 100%;
    padding: 0 26px 0 36px;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;

    @include mobile {
      padding: 21px 16px 38px;
      border-radius: 10px 10px 0px 0px;
      background-color: $cookunity-white;
      z-index: 99;
      margin-top: -10px;
    }

    > div {
      > p {
        font-family: $cui-font-family-tt-norms;
      }

      .promo-popup-text-main {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        margin: 0;
        @include mobile {
          text-align: center;
          margin-bottom: 17px;
        }
      }

      .promo-popup-text-sub {
        font-size: 14px;
        font-style: normal;
        font-weight: 450;
        line-height: 20px;
        color: $cui-black-color-400;
        margin-top: 4px;
        @include mobile {
          text-align: center;
          margin: 0 auto 20px;
        }
      }
    }

    .promo-popup-text-form {
      display: flex;
      flex-direction: column;
      gap: 8px;

      > div > span > svg > path {
        fill: $cui-black-color-350;
      }

      input,
      button {
        font-size: 14px;

        @include mobile {
          width: 100%;
        }
      }

      > .promo-input-error {
        > input {
          border: 1px solid $cookunity-red;
        }

        > span > svg > path {
          fill: $cookunity-red;
        }
      }

      input::placeholder {
        color: $cui-black-color-350;
        font-style: italic;
      }
    }
  }
}

@import '../../scss/variables';

.subtitle-cui {
  font-family: $cui-font-family-rossanova;
  font-size: $cui-font-3xl;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 0;
  line-height: 36px;
}

@import '../../scss/mixins';
@import '../../scss/variables';

.mealcard-carrousel {
  min-height: 380px;
  max-width: 1180px;
  margin: 0 auto 50px;
  background: $cookunity-white;
  padding: 60px 0;

  .mealcard-carrousel-title {
    text-align: center;
    font-family: $cui-font-family-tt-norms;

    @include mobile {
      padding: 0 15px;
    }

    h2 {
      margin: 0;
      color: $cui-black-color;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
    }

    p {
      color: $cui-black-color-300;
      font-size: 16px;
      font-style: normal;
      font-weight: 450;
      line-height: 24px;
      margin: 4.5px 0 0;
    }
  }

  .slick-next {
    transform: rotate(180deg);
  }

  .slick-dots {
    bottom: -30px;
  }

  .slick-dots li.slick-active button:before {
    color: $cui-yellow-color-600;
  }

  .slick-dots li {
    margin: 0 0;
  }

  .slick-slide {
    padding: 0 8px;

    > div > div > div:nth-child(2) > div:nth-child(2) {
      line-height: 1.3;
    }
  }

  .slick-track {
    width: 20060px !important;
  }

  .slick-list {
    padding: 43px 0 20px;

    @media (max-width: 768px) {
      padding: 34px 50px 12px !important;
    }

    @media (min-width: 768px) and (max-width: 920px) {
      padding: 34px 0px 12px !important;
    }
  }

  .container-button-bottom {
    @include mobile {
      margin-top: 42px;
    }

    .cui-link {
      padding: 12px 32px;
      border: none;
    }
  }

  &.isBlackMode {
    background-color: $cui-black-color-600;
    padding: 0;

    .mealcard-new {
      background-color: $cookunity-white;
    }

    .mealcard-carrousel-title {
      h2 {
        color: $cookunity-white;
      }
    }

    .slick-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 2px 2px 0px rgba(35, 31, 32, 0.05),
        0px 0px 2px 0px rgba(35, 31, 32, 0.1);
      z-index: 9;

      &.slick-prev:before,
      &.slick-next:before {
        content: '';
      }

      &:hover {
        opacity: 1;
        color: $cookunity-white;
        background-color: $cookunity-white;
      }

      &.slick-next:focus,
      &.slick-next:hover,
      &.slick-prev:focus,
      &.slick-prev:hover {
        opacity: 1;
        color: $cookunity-white;
        background-color: $cookunity-white;
      }
    }

    .slick-disabled {
      display: none !important;
    }

    .slick-prev,
    .slick-next {
      width: 48px;
      height: 48px;
      border-radius: 200px;
      background: $cookunity-white;
      opacity: 0.75;
      border: $cookunity-white;

      img {
        width: 24px;
        height: 24px;
      }

      @include mobile {
        display: none;
      }
    }

    .slick-next {
      img {
        transform: rotate(180deg);
      }
    }

    ul.slick-dots {
      bottom: -20px;
      position: relative;
      width: 100%;
    }

    .slick-dots li button:before {
      color: $cookunity-white;
      font-size: 36px;
    }

    .slick-dots li.slick-active button:before {
      color: $cui-yellow-color-600;
      opacity: 0.75;
      font-size: 40px;
    }
  }
}

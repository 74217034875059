@import '../../../../scss/mixins';
@import '../../../../scss/variables';

#meal-basic-info-cui {
  img.meal-lp-hero-img {
    display: none;
    @include mobile {
      display: block !important;
      max-width: 100% !important;
    }
  }
  @include mobile {
    padding: 0;
  }

  h1 {
    font-size: 28px;
    line-height: 32px;
    margin: 0 0 16px;
    font-family: $cui-font-family-rossanova;

    @include mobile {
      margin: 0 0 12px;
    }
  }

  .chef-row {
    display: flex;
    align-items: center;
    color: $cui-black-color;
    font-family: $cui-font-family-tt-norms;

    a {
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      text-decoration-line: underline;
      margin-left: 10px;
    }

    .chef-img-small {
      background-image: linear-gradient(90deg, #ff891c 0%, #ffc84e 100%);
      background-origin: border-box;
      border: 2px solid transparent;
      box-sizing: border-box;
      border-radius: 300px;
      width: 40px;
      height: 40px;
      margin-right: 8px;
      object-fit: cover;
      border-radius: 24px;
    }

    .chef-name {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
    }
  }

  .info-meal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 76px;

    &.column-reverse {
      flex-direction: column-reverse;

      h1 {
        margin: 18px 0 16px;
      }
    }
  }

  .meal-story {
    margin: 20px 0 0 0;
  }

  form {
    margin-top: 32px;
  }

  .started-button {
    margin-top: 20px;

    .button {
      width: fit-content;

      @include mobile {
        width: 100%;
      }
    }
  }
}

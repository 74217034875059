@import '../../scss/mixins';
@import '../../scss/variables';

#black-banner-cui {
  padding: 56px 0;
  text-align: center;
  background: $cui-black-color;
  margin: 0 auto;

  .container-cui {
    h3.rebrand {
      color: $cookunity-white;
      font-weight: 700;
      margin: 0;

      span {
        color: $cui-yellow-color-500;
      }
    }

    @include mobile {
      padding: 0 20px;
    }
  }

  &.black-grey {
    background: $cookunity-black;
  }
}

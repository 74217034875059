@import '../../scss/variables';

#common-questions {
  max-width: 456px;
  width: 100%;

  .section-title {
    margin: 30px 0 20px;
    text-align: center;
  }

  @media (max-width: 768px) {
    .section-title {
      margin-bottom: 10px;
      text-align: center;
    }
  }

  .button-faqs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 32px;
    gap: 6px;
    width: 230px;
    height: 47px;
    border: 1px solid $cui-olive-green-color-500;
    border-radius: 66px;
    font-family: $cui-font-family-tt-norms;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 42px;
    color: $cui-olive-green-color-500;
    margin: 42px auto 0;
  }

  .button-faqs.rebrand {
    color: $cui-black-color;
    border: 1px solid $cui-black-color;
  }
}

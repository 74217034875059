@import '../../scss/mixins';
@import '../../scss/variables';

.navbar {
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  position: relative;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 60px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);

  @include mobile {
    background-color: $cookunity-white;
    max-height: unset;
  }
  img.brand {
    width: 150px;
    height: 27px;

    @include mobile {
      position: relative;
      left: -15px;
    }
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      flex-direction: column;
    }

    .row-mobile {
      display: flex;
      width: 100%;

      @media (min-width: 768px) {
        width: 30%;
      }

      .flex-fs {
        justify-content: flex-start;
        margin-left: 20px;
      }
    }

    a {
      @include mobile {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.btn {
        border: 1px solid;
        border-radius: 50px;
        padding: 5px 15px;
        @include mobile {
          position: relative;
          top: -5px;
        }
      }
    }

    .menu-icon {
      display: none;

      @include mobile {
        display: block;
        width: 24px;
      }
    }

    .close-navbar {
      @include mobile {
        display: none;
      }
    }

    .ul-navbar {
      list-style: none;
      margin: 0;

      width: 100%;

      @include mobile {
        position: relative;
        top: 13px;
      }

      ul {
        list-style: none;
        text-align: right;
        display: flex;

        li {
          position: relative;

          @include mobile {
            margin: 13px 10px;
            text-align: left;
          }

          .selected {
            opacity: 1;
            font-weight: 800;
            color: $cookunity-black;

            &:after {
              content: ' ';
              position: absolute;
              bottom: -22px;
              height: 3px;
              background: $cookunity-black;
              width: 100%;
              left: 0;

              @include mobile {
                width: 3px;
                height: 24px;
                bottom: 0;
                left: -25px;
              }
            }
          }

          a {
            font-family: $cui-font-family-tt-norms;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            margin: 0 12px;
            text-decoration: none;
            position: relative;
            opacity: 0.9;
            top: 0;
            transition: top 0.2s ease;
            color: $cui-black-color-400;

            &:hover,
            .selected {
              opacity: 1;
              font-weight: 800;
              color: $cookunity-black !important;

              &:after {
                content: ' ';
                position: absolute;
                bottom: -22px;
                height: 3px;
                background: $cookunity-black;
                width: 100%;
                left: 0;
                text-align: center;

                @include mobile {
                  display: none;
                }
              }
            }

            @include mobile {
              margin: 0;
            }
          }
        }
      }

      .aRight {
        display: flex;
        justify-content: flex-end;
        margin: 0;
        height: 100%;
        border-radius: 0 0 12px 12px;

        .login-link {
          width: 82px;
          height: 36px;
          margin: 26px auto 0;

          @include mobile {
            width: 152px;
          }
        }

        .line-divider {
          border: 1px solid $cui-black-color-200;
          flex: none;
          order: 1;
          align-self: stretch;
          flex-grow: 0;
          margin: 26px 0;
        }

        .only-mobile {
          @include mobile {
            width: 100%;
          }
        }

        li .nav-item {
          margin: 0 12px;
        }
      }
      .only-login {
        display: none;
      }

      .col-md-3 {
        width: 100%;
        padding: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @include mobile {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 8px;
          flex-direction: column;
          width: 100%;
          align-items: flex-start;
        }
      }
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.figure-image {
  min-width: 364px;
  width: 100%;
  padding: 0;
  border-radius: 12px;
  background-color: $cookunity-white;
  margin: 0;

  @media (min-width: 768px) and (max-width: 1090px) {
    max-width: 100%;
    min-width: unset;
  }

  @include mobile {
    max-width: 100%;
    min-width: unset;
    margin: 0 auto;
  }

  img {
    height: 210px;
    object-fit: cover;
    border-radius: 12px 12px 0px 0px;
  }

  &.with-padding {
    padding: 25.04px;

    img {
      border-radius: 6px;
      width: 100%;
      object-fit: cover;
    }

    figcaption {
      padding: 0;
    }
  }

  &.with-cursor {
    cursor: pointer;
  }

  &.black-card {
    max-width: 274px;
    min-width: 274px;
    background-color: $cui-black-color-600;

    @include mobile {
      margin: 0 auto;
    }

    figcaption {
      background-color: $cui-black-color-600;
      padding: 17px 0 0;
      margin: 0;
    }

    .name {
      color: $cookunity-white;
      font-weight: 700;
      margin: 0;
      text-align: left;
      font-family: $cui-font-family-tt-norms;
      font-size: 16px;
      font-style: normal;
      line-height: 24px;
    }

    img {
      border-radius: 6px;
      width: 100%;
      object-fit: cover;
      min-width: 274px;
      height: 274px;
    }
  }

  figcaption {
    padding: 25px 32px;
  }

  &.meal-delivery {
    min-height: 336px;
    height: auto;
    max-width: 385px;

    @include mobile {
      max-width: 100%;
      min-height: 100%;
      height: 320px;
    }

    @media (max-width: 720px) {
      height: 340px;
    }

    @media (max-width: 680px) {
      height: 410px;
    }

    @media (max-width: 420px) {
      height: 360px;
    }

    @media (max-width: 364px) {
      height: 420px;
    }

    @media (max-width: 340px) {
      height: 460px;
    }

    &.figure-image figcaption {
      padding: 24px;
    }

    .text-desktop {
      display: block;

      @include mobile {
        display: none;
      }
    }

    .text-mobile {
      display: none;

      @include mobile {
        display: block;
      }
    }
  }

  &.four-card {
    min-width: 254px;
    max-width: 254px;
    box-shadow: 0px 2px 2px 0px #0000000d;
    box-shadow: 0px 0px 2px 0px #231f201a;
    height: 468px;

    @include mobile {
      min-width: 100%;
    }

    img {
      max-height: 144px;
    }
  }

  .three-cards {
    max-width: 100%;
  }

  &.with-icon {
    img {
      object-fit: none;
      width: auto !important;
      height: auto;
      padding: 20px 0 0 30px;
    }
  }

  &.our-commitment {
    height: 368px;
  }

  &.green-page {
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05),
      0px 0px 2px 0px rgba(35, 31, 32, 0.1);

    &.fix-height {
      height: 530px;
    }

    &.green-ingredients {
      height: 450px;
    }

    &.green-lp {
      min-height: fit-content;
      height: 100%;
    }

    @include mobile {
      max-width: 100%;
    }
  }

  a:hover {
    text-decoration: none;
  }
}

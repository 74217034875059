@import '../../scss/variables';

.bright-footer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: fit-content;
  border-top: 1px solid $cui-black-color-150;
  display: flex;
  flex-direction: column;

  &::before {
    content: '';
    position: absolute;
    top: -130px;
    left: -130px;
    width: 800px;
    height: 800px;
    background: url('/assets/images/referral/logo.svg') no-repeat;

    @media (max-width: 720px) {
      top: -180px;
      left: 50%;
      width: 600px;
      height: 600px;
      transform: translateX(-42%);
    }
  }

  &-nav,
  &-info {
    display: flex;
    width: 100%;
    justify-content: space-around;

    .bright-footer-links {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  &-info {
    @media (max-width: 720px) {
      margin-top: 130px;
      flex-direction: column-reverse;
      align-items: center;
      gap: 24px;

      .bright-footer-links {
        text-align: center;
      }
    }
  }

  &-container {
    position: relative;
    max-width: 1600px;
    width: 100%;
    margin: 85px auto;
    display: flex;
    justify-content: space-between;

    @media (max-width: 720px) {
      flex-direction: column;
      align-items: center;
      margin-top: 100px;
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        font-size: $cui-font-sm;
        font-family: $cui-font-family-tt-norms;
        font-weight: $cui-font-bold;
        color: $cui-black-color-400;
        cursor: pointer;
        list-style: none;
      }
    }

    .social-list {
      display: flex;
      flex-direction: column;
      align-items: center;

      .footer-cui-logo {
        display: none;

        @media (max-width: 720px) {
          display: block;
          height: 50px;
          width: auto;
        }
      }

      .app-store {
        margin-bottom: 40px;
        align-self: flex-start;

        @media (max-width: 720px) {
          align-self: center;
          margin-top: 50px;
          margin-bottom: 35px;
          order: 1;
        }

        .cui-icon {
          width: 120px;
          height: auto;
        }
      }

      .social-icons-nav {
        display: flex;
        flex-direction: row;
        gap: 24px;
        margin: 0;

        @media (max-width: 720px) {
          margin-top: 24px;
        }

        .social-button {
          border-radius: 50%;
          border: 1px solid $cui-black-color-150;
          padding: 15px;
          height: fit-content;
          width: fit-content;
        }
      }
    }
  }

  &-copyright {
    position: relative;
    text-align: center;
    font-size: $cui-font-xs;
    color: $cui-black-color-300;
    margin: 50px 0;
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

section {
    padding: 50px 0;

    .content {
        max-width: 400px;
        margin: 10px;
    }
}
@import '../../scss/mixins';
@import '../../scss/variables';

#chef-filter-list-cui {
  padding: 36px 0 0;
  margin: 0 auto;
  background: $cookunity-white;
  text-align: center;

  @media (max-width: 1024px) {
    padding: 0 20px;
  }

  .container-cui {
    max-width: 1240px;
    margin-top: 20px;

    ul {
      display: flex;
      list-style-type: none;
      padding: 0;

      li {
        width: 45px;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: $cookunity-gray-2;
        padding-bottom: 8px;
        cursor: pointer;
        margin-right: 24px;

        @include mobile {
          margin-right: 10px;
        }

        &.selected {
          border-bottom: 3px solid $cookunity-black;
        }
      }

      li.selected,
      li:hover {
        color: $cookunity-black;
      }
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin: 37px 0 0;
    justify-content: left;
    gap: 30px;

    @media (min-width: 400px) and (max-width: 1200px) {
      justify-content: center;
    }

    @include mobile {
      flex-direction: row;
      justify-content: center;
      padding: 0;
    }

    .col-20 {
      width: 160px;
      margin-right: 31px;
      margin-bottom: 41px;

      @media (min-width: 920px) and (max-width: 1024px) {
        margin-left: 45px;
      }

      @include mobile {
        margin: 0 0px 41px;
      }

      .chef-item {
        width: 159px;
        height: 142px;
        object-fit: cover;
        margin-bottom: 16px;
      }

      p {
        display: flex;
        align-items: center;
        text-decoration-line: underline;
        color: $cookunity-black;
      }
    }
  }

  .skeleton-circle {
    background: url('/assets/images/skeleton.gif') no-repeat center center fixed;
    background-size: cover;
    height: 95px;
    width: 95px;
    border-radius: 200px;
    margin-right: 50px;

    @include mobile {
      margin-right: 14px;
      margin-bottom: 100px;
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

#how-it-works-video-cui {
  .step-col-image {
    width: 100%;
    height: 150px;
  }
  &.isBlackMode {
    background-color: $cui-black-color-600;

    .step-col-50 {
      p.rebrand.lead {
        color: $cookunity-white;
        margin: 24px 0 10px;
        text-align: center;
      }

      p.rebrand.step-text {
        color: $cui-black-color-300;
        text-align: center;
      }
    }
  }

  &.home-seo {
    background-color: $cui-black-color-600;

    video {
      width: 257.419px;
      flex-shrink: 0;
      border-radius: 6px;
    }

    .container-cui {
      align-items: flex-start;
      padding: 0 0 60px;
      background-color: $cui-black-color-600;
    }

    .container-hiw-step {
      background-color: $cui-black-color-600;

      @media (min-width: 768px) and (max-width: 1200px) {
        margin-left: 0;
      }
    }
  }

  &.withIcons {
    .container-cui {
      justify-content: space-around;
      flex-direction: column;
      max-width: 1200px;

      @include mobile {
        padding: 0 0 40px;
      }

      .hiw-item {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .step-col-image,
        .step-col-50 {
          max-width: 250px;
          width: 100%;
          text-align: center;

          @include mobile {
            width: 100%;
            margin: 0 auto 20px;
            max-width: 90%;
          }
        }

        @include mobile {
          flex-direction: column;
        }
      }

      .hiw-item-icon {
        @include mobile {
          display: none;
        }
      }
    }

    .hiw-mobile-slider {
      .step-col-50 {
        padding: 0 20px;
        text-align: center;
      }
    }
  }

  &.mobileSlider {
    .container-cui {
      display: flex;

      @include mobile {
        display: none;
      }
    }

    .hiw-mobile-slider {
      display: none;

      @include mobile {
        display: block;
        padding-bottom: 50px;
        margin-bottom: -1px;

        .slick-list {
          padding: 0 !important;
        }

        .slick-dots {
          position: relative;

          ul {
            padding: 0;
          }
        }

        .slick-dots li {
          margin: 0;
        }

        .slick-dots li button:before {
          color: $cookunity-white;
          font-size: 36px;
        }

        .slick-dots li.slick-active button:before {
          color: $cui-yellow-color-600;
          opacity: 0.75;
          font-size: 40px;
        }
      }
    }

    .container-hiw-step {
      max-width: 257px;
    }

    .slick-list {
      max-width: 100%;
    }
  }

  .container-cui {
    gap: 40px;
    max-width: 1200px;
    justify-content: space-around;

    .p-hiw {
      width: 420px;

      @include mobile {
        width: 100%;
      }
    }

    .container-hiw-step {
      width: 250px;

      @media (min-width: 1025px) and (max-width: 1366px) {
        width: 216px;
      }

      @media (min-width: 921px) and (max-width: 1024px) {
        width: 190px;
        overflow: hidden;
      }

      @media (min-width: 768px) and (max-width: 920px) {
        width: 174px;
      }

      @include mobile {
        width: 100%;
        padding: 0;
        margin: 0 0 20px 0;
      }

      #background-video {
        width: 250px;
        height: 197px;
        object-fit: cover;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        @include mobile {
          width: 100%;
          height: 225px;
        }

        @media (min-width: 1025px) and (max-width: 1366px) {
          width: 216px;
        }

        @media (min-width: 921px) and (max-width: 1024px) {
          width: 190px;
          overflow: hidden;
        }

        @media (min-width: 768px) and (max-width: 920px) {
          width: 174px;
        }
      }

      .step-col-50 {
        text-align: center;

        @include mobile {
          text-align: left;
          padding: 0 20px;
        }

        .step-number {
          margin: 29px 0 0px;

          @include mobile {
            margin: 12px 0 0px;
          }
        }

        .step-text {
          margin-top: 20px;

          @include mobile {
            margin: 0;
          }
        }
      }
    }
  }
}

.with-zip .row {
  margin-bottom: 0;
}

.with-zip .p-hiw {
  width: 298px;

  @include mobile {
    width: 100%;
  }
}

.zip-form-cui.white-button {
  flex-direction: column;
  width: 224.5px;
  margin: 0 28px;

  @include mobile {
    margin: 0 auto;
    width: 100%;
    margin-top: 32px;
  }
}

.zip-form-cui.white-button .container-input .input-zip {
  margin-right: 0;
  margin-bottom: 19px;
  width: 100%;
}

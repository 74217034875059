#text-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;

  @include mobile {
    padding: 0 16px;
  }

  .text-section-pretitle {
    margin: 0;
    color: $cui-black-color-600;
    font-size: $cui-font-base;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.32px;

    @include mobile {
      text-align: center;
    }
  }

  .text-section-title {
    margin: 20px 0 0 0;
    text-align: center;
    color: $cui-black-color-350;
    font-family: $cui-font-family-rossanova;
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -1.92px;

    @include mobile {
      width: 100%;
      text-align: center;
      letter-spacing: unset;
      font-size: 32px;
    }

    strong {
      color: $cui-black-color-600;
    }
  }

  p {
    color: $cui-black-color-350;
    font-family: $cui-font-family-tt-norms;
    text-align: center;
    font-style: normal;
    margin: 32px 0 0 0;
  }
}

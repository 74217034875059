@import '../../../../scss/mixins';
@import '../../../../scss/variables';

#chef-review-lp-cui {
  padding: 32px 20px 48px;

  @include mobile {
    padding: 0 20px;
  }

  .container-cui {
    justify-content: flex-start;
    gap: 40px;
    max-width: 1110px;

    @include mobile {
      padding: 0;
    }

    .review {
      max-width: 530px;
      width: 100%;
      @include mobile {
        max-width: 100%;
      }

      @media (min-width: 768px) and (max-width: 1200px) {
        max-width: 45%;
      }
    }

    .product-card {
      align-items: center;
      display: flex;

      img.product-image {
        width: 80px !important;
        height: 80px !important;
        border-radius: 4px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin-right: 16px;
        object-fit: cover;
      }

      .review-info {
        .stars {
          .ratingStar {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .product-review {
      max-width: 100%;
      margin: 0;

      p {
        margin: 8px 0 0;
      }

      p.signature.more-small {
        color: $cui-black-color-300;
      }
    }

    .link-cui {
      margin: 32px auto;

      @media (max-width: 1200px) {
        margin: 32px auto;
      }
    }
  }
}

@import '../../../scss/variables';

.cui-logo {
  width: 130px;

  &.light {
    color: $cookunity-white;
  }
  &.dark {
    color: $cui-black-color;
  }
}

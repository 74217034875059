@import '../../../../scss/mixins';
@import '../../../../scss/variables';

.tags-mobile-slider-cui {
  margin: 16px 0 4px 0;
  padding: 0;
  max-width: 500px;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    max-width: 100%;
  }

  .ul-list {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;

    .slick-slider {
      width: 100%;
    }

    .meals-tags-list {
      .slick-track {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        width: 600px !important;
        margin-left: -8px;

        @media (min-width: 768px) and (max-width: 1024px) {
          margin-left: 0;
        }

        @include mobile {
          margin-left: 0;
        }
      }

      .slick-slide {
        width: fit-content !important;
      }

      li.meal-tags {
        display: flex !important;
        padding: 5px 16px 5px 9px;
        align-items: center;
        gap: 2.756px;
        border-radius: 4.134px;
        border: 0.985px solid $cui-black-color-150;
        background: $cookunity-white;
        margin: 0 8px 0 0;
        height: 36px;

        img {
          width: 24.115px;
          height: 24.115px;
        }

        p.p-tag {
          color: $cui-black-color;
          font-family: $cui-font-family-tt-norms;
          font-size: 9.852px;
          font-style: normal;
          font-weight: 700;
          margin: 0;
        }
      }
    }
  }
  &.black-mode {
    .ul-list {
      li.meal-tags {
        padding: 5px 16px;
        p.p-tag {
          color: $cui-black-color;
          margin: 0;
        }
      }
    }
  }
}

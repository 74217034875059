@import '../../scss/mixins';
@import '../../scss/variables';

.asSeenAsContainer {
  padding-bottom: 90px;
  @include mobile {
    padding-bottom: 40px;
  }
}

#AsSeenAs .testimonial-content-short {
  z-index: 999;
  position: relative;
  img:hover {
    opacity: 0.6;
  }
}

#AsSeenAs {
  background-color: $cui-black-color-100;
  position: relative;

  section {
    min-height: 250px;
    display: flex;
    align-items: center;
    padding-bottom: 90px;
    @include mobile {
      padding-bottom: 40px;
    }
  }

  .container {
    padding: 0;
  }

  .short-testimonial {
    padding: 0;
    min-height: unset;
    max-height: 97px;

    @include mobile {
      max-height: 156px;
    }
  }

  .testimonial-border {
    @include mobile {
      border-top: 1px solid $cui-gray-color-border;
    }
  }

  .testimonial-text {
    margin: 0;
    padding-bottom: 20px;
    font-style: italic;
    font-size: 20px;
    font-weight: 700;
    @include mobile {
      font-size: 16px;
      max-width: 85%;
      margin: 0 auto;
    }
    a.read-article {
      position: relative;
      z-index: 1;
      display: block;
      margin-top: 10px;
      font-size: 13px;
      font-family: $cui-font-family-tt-norms;
      color: $cui-black-color;
      font-style: normal;

      img {
        display: inline-block;
        width: 7px;
        position: relative;
        top: -1px;
        margin-left: 5px;
        transition: margin 0.5s ease;
      }

      &:hover {
        text-decoration: none;
        img {
          margin-left: 10px;
        }
      }
    }
  }

  .testimonial-content {
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
  }

  .testimonial-content-short {
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 29px 0;
    height: 97px;

    @include mobile {
      flex-direction: column;
      padding: 25px 0;
      height: 156px;
    }

    p {
      font-family: $cui-font-family-tt-norms;
      font-style: italic;
      font-weight: normal;
      font-size: 16px;
      color: rgba(35, 31, 32, 0.6);
      width: 444px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @include mobile {
        margin-bottom: 12px;
      }
    }
  }

  .testimonial-logo {
    margin: 0 auto;
    height: 30px;
    display: none;
    @include mobile {
      display: initial;
    }
  }

  .customNav-logo {
    transition: all ease 0.2s;
    margin: 0 20px;
    height: 30px;
    display: inline-block;
    cursor: pointer;
    opacity: 0.3;
    &:hover {
      opacity: 0.6;
    }
    &.active {
      opacity: 1;
    }
  }

  .customNav {
    text-align: center;
    display: block;
    margin-top: -50px;
    position: relative;
    @include mobile {
      display: none;
    }
  }

  .slick-next {
    right: 25px;
    @include mobile {
      right: 8px;
    }
    &:before {
      color: $cui-black-color-600;
    }
  }
  .slick-prev {
    left: 25px;

    @include mobile {
      left: 8px;
    }
    &:before {
      color: $cui-black-color-600;
    }
  }
  .slick-dots {
    display: none !important;
    @include mobile {
      display: initial !important;
      bottom: 20px;
    }
    li {
      width: 5px;
      height: 5px;
      button {
        &:focus {
          &:before {
            color: $cui-black-color-350;
          }
        }
        &:before {
          width: auto;
        }
      }
    }
  }

  &.version2 {
    background: $cui-black-color-50;
    padding-bottom: 30px;
    padding-top: 65px;
    position: relative;

    @include mobile {
      padding-top: 40%;
      padding-bottom: 20%;
    }

    section {
      min-height: auto;
      padding-bottom: 0;
      padding-top: 0;
    }

    .customNav {
      margin-top: 0;
      text-align: left;

      @include mobile {
        text-align: center;
      }
    }

    .testimonial-content {
      margin: 0 15px;

      @include mobile {
        max-width: 100%;
      }
    }

    .testimonial-text {
      margin: 0;
      padding-bottom: 20px;
      font-style: italic;
      font-size: 18px;
      font-weight: 400;
      text-align: left;
      margin-top: 37px;

      @include mobile {
        text-align: center;
        margin: 0 auto;
        margin-top: 37px;
      }
    }
  }

  &.version3 {
    background: $cui-black-color-50;
    padding-bottom: 30px;
    padding-top: 65px;
    position: relative;

    @include mobile {
      padding-top: 80px;
      padding-bottom: 50px;
    }

    section {
      min-height: auto;
      padding-bottom: 0;
      padding-top: 0;
    }

    .customNav {
      margin-top: 0;
      text-align: center;
      @include mobile {
        text-align: center;
      }
    }

    .testimonial-content {
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      display: flex;

      @include mobile {
        max-width: 100%;
        flex-direction: column;
      }
    }

    .testimonial-text {
      margin: 0;
      padding-bottom: 20px;
      font-style: italic;
      font-size: 18px;
      font-weight: 400;
      text-align: left;
      margin-top: 37px;

      @include mobile {
        text-align: center;
        margin: 0 auto;
        margin-top: 37px;
      }
    }
  }
}

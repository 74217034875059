@import '../../../../scss/mixins';
@import '../../../../scss/variables';

.competitors-table {
  margin: 96px auto;

  .row-container {
    padding: 0 20px;
  }

  h2 {
    color: var(--Text-text-color-primary, $cui-black-color);
    text-align: center;
    font-family: $cui-font-family-rossanova;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 32px;
  }
  table,
  th,
  td {
    border: 1px solid $cui-black-color-150;
  }

  table {
    border-collapse: collapse;
    border-radius: 6px;
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
  }

  th,
  td {
    text-align: center;
    width: 200px;
    padding: 30px 23px;
    color: $cui-black-color-400;
    font-family: $cui-font-family-tt-norms;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    @include mobile {
      font-size: 14px;
      line-height: 20px;
      min-width: 88px;
      max-width: 88px;
      width: 100%;
    }

    img {
      margin: 0 auto;
    }

    img.cookunity-logo {
      width: 131px;
      height: 28px;
      flex-shrink: 0;
    }

    img.check {
      margin: 0 auto;
      background: $cui-green-color-100;
      border-radius: 200px;
      padding: 4px;
    }

    @include mobile {
      padding: 23px 6px;
    }
  }

  td.text {
    width: 500px;
    text-align: left;
  }

  td.small {
    @include mobile {
      font-size: 12px;
      line-height: 16px;
    }
  }
}

@import '../../scss/mixins';

#common-questions-cui {
  padding: 80px 0 80px;

  @include mobile {
    padding: 0 0 80px;
  }

  .container {
    max-width: 483px;
    margin: 0 auto;

    @media (max-width: 768px) {
      max-width: 100%;
    }

    h3 {
      margin: 30px 0 20px;
    }

    @media (max-width: 768px) {
      h3 {
        margin-bottom: 10px;
      }
    }

    .accordion {
      margin-bottom: 36px;
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.mealcard-list {
  padding: 0 0 60px 0;
  gap: 28px 20px;
  overflow: hidden;

  @include mobile {
    flex-direction: column;
  }

  @media (max-width: 1200px) {
    max-width: 100%;
    justify-content: space-around;
  }

  .tab-list {
    min-width: 1200px;
    max-width: 1200px;
    padding: 0 0 50px;
    margin: 0 auto;

    @media (max-width: 1200px) {
      padding-left: 20px;
      padding-bottom: 32px;
    }

    .hcw-list {
      .slick-track {
        display: flex;
        justify-content: center;
        gap: 20px;
      }
      .slick-list {
        overflow: visible;
        width: 100%;
      }

      .tab-list-element {
        list-style: none;
        display: flex !important;
        align-items: center;
        height: 54px;
        padding-right: 8px;
        cursor: pointer;
        font-family: $cui-font-family-tt-norms;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        @media (max-width: 1200px) {
          margin-right: 10px;
        }

        &.selected {
          border-bottom: 2px solid $cui-black-color;
        }
      }
    }

    &.with-sub-tab {
      padding-bottom: 24px;
    }
  }

  .sub-tab-list {
    list-style: none;
    width: 100%;
    margin: 0 0 34px;
    padding-left: 20px;

    .slick-track {
      display: flex;
      justify-content: center;
    }

    .slick-slide {
      margin-right: 20px;
    }

    .tab-list-element {
      border-radius: 200px;
      background-color: $cui-black-color-100;
      padding: 21px 16px;
      font-family: $cui-font-family-tt-norms;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 0;
      cursor: pointer;

      &.selected {
        border: 1px solid $cui-black-color;
        background-color: $cookunity-white;
      }

      img {
        display: none;
      }
    }
  }

  .container-meals {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 28px 20px;
    margin: 0 auto;
    max-width: 1164px;

    @media screen and (max-width: 1200px) {
      justify-content: center;
      padding: 0 20px;
      width: 100%;
    }

    .mealHighlight {
      width: 580px;
      height: 375px;
      border-radius: 12px;
      background: $cookunity-white;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05),
        0px 0px 2px 0px rgba(35, 31, 32, 0.1);
      display: flex;
      flex-direction: row-reverse;
      margin-right: -8px;
      padding: 11px 11px 11px 37px;
      gap: 30px;
      align-items: center;

      @include mobile {
        flex-direction: column;
        height: auto;
        width: 100%;
        padding: 24px;
      }

      &:hover {
        box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.07),
          0px 0px 12px rgba(35, 31, 32, 0.1);
      }

      .mealcard-new {
        width: 275px;
        height: 353px;
        @include mobile {
          width: 100%;
        }

        .mealcard-new-description {
          height: auto;
        }

        &:hover {
          box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05),
            0px 0px 2px 0px rgba(35, 31, 32, 0.1);
        }

        .cui-image {
          height: 250px;

          @include mobile {
            height: 230px;
          }
        }
      }

      .text {
        height: fit-content;
        font-family: $cui-font-family-tt-norms;
        font-style: normal;

        h3 {
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
        }

        p {
          font-size: 14px;
          font-weight: 450;
          line-height: 20px;
          margin: 0 0 48px;

          @include mobile {
            margin: 0 0 20px;
          }
        }

        a {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          text-decoration-line: underline;
        }
      }
    }

    .noMealHighlight {
      margin: 0 auto;

      @include mobile {
        width: 100%;
      }

      .mealcard-new {
        width: 275px;

        @include mobile {
          width: 100%;
        }

        .cui-image {
          height: 250px;
        }
      }
      .text {
        display: none;
      }
    }
  }

  .mealcard-new {
    width: 276px;
    height: 375px;

    @include mobile {
      height: 367px;
    }

    .cui-image {
      height: 250px;

      @include mobile {
        height: 230px;
      }
    }
  }

  .container-meals-slider {
    gap: 28px 20px;
    margin: 0 auto;
    max-width: 1178px;

    @include mobile {
      padding-left: 20px;
    }

    .mealcard-new.meal-card-slider {
      width: 276px;
      height: 400px;
      margin-right: 20px;

      @include mobile {
        height: 367px;
      }
    }

    .slick-dots li.slick-active button:before {
      color: $cui-yellow-color-600;
      opacity: 0.75;
      font-size: 40px;
    }
    .slick-dots li {
      display: none;
    }
    /* only displaying the active bullets and the 2 bullets next to it */
    .slick-dots li.slick-active,
    .slick-dots li.slick-active + li,
    .slick-dots li.slick-active + li + li {
      display: flex;
      margin: 0;
    }

    .slick-dots {
      display: flex !important;
      width: fit-content;
      margin: 0 auto 20px;
      position: relative;
    }

    .slick-list {
      padding: 10px 10px 20px;
    }
  }

  .container-button-bottom {
    @include mobile {
      margin-top: 42px;
    }

    .cui-link {
      padding: 12px 32px;
      border: none;
    }
  }

  form.zip-form-cui.rebrand {
    margin-top: 52px;
    .input-button {
      margin: 0 auto;

      @include mobile {
        width: 90%;
      }
      @media (min-width: 960px) and (max-width: 1200px) {
        margin: 0 auto;
      }
    }
  }

  &.isLoading {
    flex-direction: column;

    .meals-slider-loader-container {
      margin: 0 auto;
      height: 500px;
      display: flex;
      align-items: center;

      .circular-loader {
        margin: 200px auto;
      }
    }
  }

  .meals-list-slider-container {
    margin-bottom: 99px;

    h2 {
      color: $cui-black-color;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: $cui-font-family-rossanova;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 44px;
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

#enjoy4freemeals {
  .enjoy-promo {
    background-color: $cui-black-color-100;
    padding: 94px 0 117px;

    @include mobile {
      padding: 50px 10px;
    }

    form {
      margin: 50px 0 11px;

      @include mobile {
        margin: 20px 0 11px;
      }
    }

    .small {
      color: $cui-black-color-300;
    }

    h3,
    p {
      @include mobile {
        text-align: center;
      }
    }

    .col-media {
      max-width: 505px;
      height: 100%;

      @include mobile {
        display: flex;
        max-width: 100%;
      }
    }

    .col-text {
      @include mobile {
        padding: 0 10px;
      }
    }
  }
}

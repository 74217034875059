#banner-content {
  width: 75%;
  padding: 80px 110px;

  @media (max-width: 1162px) {
    width: 100%;
    padding: 204px 21px 34px 21px;
  }

  h1 {
    margin: 0 0 16px 0;
    color: $cookunity-white;
    font-family: $cui-font-family-rossanova;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    text-align: left;

    @media (max-width: 1162px) {
      font-size: 32px;
      line-height: 31px;
      margin-bottom: 16px;
      text-align: center;
    }

    span {
      color: $cui-yellow-color-500;
      display: inline;
    }
  }

  p {
    margin: 0 0 20px 0;
    color: $cookunity-white;
    text-align: left;

    @media (max-width: 1162px) {
      text-align: center;
    }

    span {
      color: $cui-yellow-color-500;
      display: inline;
    }
  }

  form {
    input {
      color: $cookunity-white;
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

#title-image-animated-cui {
  padding: 0;
  background: $cookunity-white;
  text-align: center;

  .container-cui {
    .marquee {
      display: flex;
      justify-content: center;

      @include mobile {
        height: 360px;
        width: 100%;
        overflow: hidden;
        position: relative;
        display: block;
      }
    }

    .marquee div {
      @include mobile {
        display: block;
        width: 1800px;

        position: absolute;
        overflow: hidden;

        animation: marquee 20s linear infinite;
        transform: translate(0, -10%);
      }

      img {
        max-width: 100%;
        width: 100%;
      }
    }

    @keyframes marquee {
      0% {
        left: 0;
      }

      100% {
        left: -100%;
      }
    }

    p.rebrand {
      @include mobile {
        max-width: 100%;
        text-align: center;
      }
    }
  }
}

#chefs-collective-animated.white-text {
  background-color: $cookunity-black;

  .container {
    h2 {
      color: $cookunity-white;
    }

    p {
      color: $cui-black-color-200;
    }
  }
}

@import '../../../scss/variables.scss';

.cui-meal-badge {
  position: absolute;
  z-index: $z-10;
  top: 0;
  left: 0;
  display: block;
  width: 68px;

  span {
    z-index: 1;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    font-size: $cui-font-xs;
    font-family: $cui-font-family-tt-norms;
    line-height: $cui-line-height-base;
    font-weight: $cui-font-bold;
  }
  .cui-icon {
    height: auto;
    width: 100%;
    display: block;
  }
}

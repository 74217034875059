@import '../../scss/mixins';
@import '../../scss/variables';

.promo-bar-countdown {
  background: $cui-olive-green-color-500;

  .container-cui {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 4px;

    @include mobile {
      flex-direction: column;
      padding: 12px 0 9px;
    }

    .text {
      color: $cookunity-white;
      font-family: $cui-font-family-tt-norms;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      margin: 0 16px 0 0;

      @include mobile {
        margin: 0 0 6px 0;

        span {
          display: none;
        }
      }

      &.text-default {
        padding: 10px 0 16px;
        margin: 0;
      }
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.cui-button-lp {
  appearance: none;
  cursor: pointer;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-family: $cui-font-family-tt-norms;
  font-size: $cui-font-sm;
  padding: 10px 20px;
  font-style: normal;
  font-weight: 700;
  text-align: center;

  &-small {
    font-size: $cui-font-xs;
    padding: 8px 12px;
    height: 32px;
  }

  &-medium {
    height: 40px;
  }

  &-large {
    height: 48px;
    font-size: $cui-font-base;
  }

  &-primary {
    background-color: $cui-yellow-color-500;
    border: 1px solid $cui-black-color-shadow;
    color: $cookunity-black;

    &:hover {
      background-color: $cui-yellow-color-600;
    }
  }

  &-secondary {
    border: 1px solid $cui-gray-color-border;
    background: $cui-black-color-50;
    color: $cui-black-color-400;
  }

  &-dark {
    border-radius: 200px;
    background: $cui-black-color;
    color: $cookunity-white;
  }

  &-underline {
    color: $cui-yellow-color-550;
    text-decoration-line: underline;
    border: none;
    padding: 0;
    background-color: transparent;

    &.grey {
      color: rgba(255, 255, 255, 0.6);
    }
  }

  &-no-style {
    background: transparent;
    color: $cookunity-black;
  }
  &-underline_black {
    color: $cui-black-color;
    text-decoration-line: underline;
    background-color: transparent;
    border: none;
    padding: 0;
    background-color: transparent;
  }
  &-no-style {
    background: transparent;
    color: $cookunity-black;
  }
}

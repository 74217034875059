.simple-mealcard {
  background-color: $cookunity-white;
  border-radius: 15px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05),
    0px 0px 2px 0px rgba(35, 31, 32, 0.1);
  overflow: hidden;
  max-width: 366px;
  margin-right: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;

  @include mobile {
    max-width: 272px;
    height: 380px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &.with-background {
    .simple-mealcard {
      &__content {
        width: 100%;
        border-top: 0.95px solid rgba(255, 255, 255, 0.12);
        background: rgba(255, 255, 255, 0.08);
        backdrop-filter: blur(9.5px);

        p {
          color: $cui-black-color-100;
        }

        span {
          color: rgba(255, 255, 255, 0.75);
        }
      }
      &__chef-image {
        border-radius: 130px;
        border: 1.463px solid $cui-yellow-color-500;
        background: $cui-black-color-50;
        object-fit: cover;
      }
    }

    .simple-mealcard__chef p {
      color: $cui-black-color-100;

      span {
        color: rgba(255, 255, 255, 0.6);
        margin-right: 4px;
      }
    }
  }

  &__image-container {
    width: 366px;
    height: 385px;
    overflow: hidden;

    @include mobile {
      max-width: 272px;
      height: auto;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
  }

  &__content {
    padding: 20px;
    text-align: center;
    font-family: $cui-font-family-tt-norms;
  }

  &__content p {
    margin: 0;
    color: $cui-black-color;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -0.6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include mobile {
      font-size: 16px;
    }
  }

  &__content span {
    margin: 0;
    color: $cui-black-color-300;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 150%;
    letter-spacing: -0.14px;
  }

  &__chef {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7.4px;

    @include mobile {
      margin-top: 12px;
    }
  }

  &__chef-image {
    border-radius: 50%;
    width: 34px;
    height: 34px;
  }

  &__chef p {
    color: $cui-black-color;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.28px;
  }
}

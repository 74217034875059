@import '../../scss/variables';

.hcw-container {
  .subtitle-cui {
    margin-bottom: 50px;
  }

  .hcw-list {
    .slick-track {
      display: flex;
      justify-content: center;

      @media (min-width: 1550px) {
        justify-content: space-between;
      }
    }
    .slick-list {
      overflow: visible;
      width: 100%;
    }

    .hcw-item {
      list-style: none;
      display: flex !important;
      max-width: 310px !important;

      &:not(:last-child) {
        margin-right: 14px;
      }

      img {
        width: 100px;
        height: 100px;
        object-fit: contain;
        transform: translateY(-22px);
      }
    }

    .step-description {
      position: relative;
      font-family: $cui-font-family-tt-norms;

      span {
        position: absolute;
        top: -30px;
        left: 0;
        z-index: 1;
        font-family: $cui-font-family-rossanova;

        color: $cui-yellow-color-500;
        font-size: $cui-font-6xl;
      }

      h3 {
        position: relative;
        font-size: $cui-font-base;
        z-index: 2;
      }

      p {
        font-size: $cui-font-sm;
        color: $cui-black-color-400;
      }
    }
  }
}

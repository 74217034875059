@import '../../scss/mixins';
@import '../../scss/variables';

.jobs-list-cui {
  margin: 24px auto;
  max-width: 1078px;
  width: 100%;
  border-bottom: 1px solid $cui-black-color-200;

  &:last-child {
    border-bottom: none;
  }

  p.rebrand.upper {
    text-align: left;
    color: $cui-black-color-350;
    margin-bottom: 35px;
  }

  p.rebrand.small {
    color: $cui-black-color-400;
    font-weight: 450;
  }

  p.rebrand.lead {
    @include mobile {
      max-width: 200px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;
      margin-bottom: 25px;

      @include mobile {
        height: 100%;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    h4 {
      font-size: 20px;
      margin: 0;
    }
  }

  .btn-rebrand-white {
    width: 154px;

    @include mobile {
      width: 123px;
      height: 46px;
    }
  }
}

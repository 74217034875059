@import '../../scss/variables';

.reviewCountContainer {
  display: flex;
  align-items: center;

  h2.rebrand {
    font-size: 72px;
    line-height: 72px;
    margin-right: 24px !important;
    margin-top: 8px;
  }

  .columnContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .stars {
      h4 {
        margin: 0 14px 0 0;
        text-align: left;
        line-height: 0;
      }
    }

    .small {
      color: $cookunity-gray-2;
      margin: 4px 0 0;
    }

    .reviews {
      margin-top: 11px;
    }

    .container-cui-row {
      flex-direction: row;
    }
  }

  .ratingStar {
    width: 19px;
    height: 19px;
  }
}

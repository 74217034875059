@import '../../scss/mixins';
@import '../../scss/variables';

.chefDescription {
  margin-bottom: 20px;

  p {
    line-height: 29px;
    color: $cui-black-color-400;
    text-align: justify;
    margin-top: 0px;
  }

  .compact {
    -webkit-box-orient: vertical !important;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 5;
  }

  .zip-btn {
    border: none;
    background: transparent;
    margin-top: 9px;
    outline: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;

    .btn-p {
      color: $cookunity-black;
    }
  }

  @include mobile {
    padding: 0;
    width: 100%;
    margin-left: 0;
  }
}

@import '../../../../scss/mixins';
@import '../../../../scss/variables';

.productChefReview {
  flex: 1;
  max-width: 517px;
  width: 100%;
  margin-left: 93px;

  @media screen and (max-width: 920px) {
    margin-top: 40px;
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }

  @media (min-width: 1024px) and (max-width: 1030px) {
    margin-top: 0;
  }

  p {
    margin: 18px 0 8px 0;
    color: $cui-black-color-400;
  }

  .signature {
    color: $cookunity-gray-2;
    margin: 0;
    h6 {
      display: inline;
      color: $cookunity-black;
    }

    span {
      font-weight: 800;
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.chefs-cards-slider {
  &__container {
    max-width: 1380px;
    width: 100%;
    margin: 96px auto;
    position: relative;

    @include mobile {
      max-width: 100%;
    }

    .slider__light {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 600px;
      height: 380px;
      background: radial-gradient(
        circle,
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0) 80%
      );
      transform: translate(-50%, -50%);
      pointer-events: none;
      filter: blur(50px);

      @media (max-width: 800px) {
        width: 600px;
        height: 280px;
      }

      @include mobile {
        width: 100%;
      }
    }

    .slick-slider {
      width: 100%;
      background-color: transparent;
      height: 400px;

      .slick-arrow {
        width: 32px;
        height: 32px;
        z-index: 1;
        border-radius: 100px;
        border: 1px solid rgba(255, 255, 255, 0.15);
        background: rgba(255, 255, 255, 0.15);
        backdrop-filter: blur(10px);

        img {
          padding: 4px;
        }
      }

      .slick-track {
        display: flex;
        align-items: center;
        margin-left: -30px;

        @media (max-width: 1300px) {
          margin-left: -70px;
        }

        @media (max-width: 1200px) {
          margin-left: -120px;
        }

        @media (max-width: 1100px) {
          margin-left: -175px;
        }

        @media (max-width: 1000px) {
          margin-left: -45px;
        }

        @media (max-width: 900px) {
          margin-left: -95px;
        }

        @media (max-width: 800px) {
          margin-left: -145px;
        }

        @media (max-width: 700px) {
          margin-left: -195px;
        }

        @media (max-width: 600px) {
          margin-left: -245px;
        }

        @media (max-width: 600px) {
          margin-left: -245px;
        }

        @media (max-width: 500px) {
          margin-left: -285px;
        }

        @media (max-width: 400px) {
          margin-left: -345px;
        }
      }

      .slick-slide {
        float: none;
        display: inline-block;
        vertical-align: middle;
        margin: 0px;
        padding: 0px 40px;
        text-align: center;
        border-radius: 12px;

        @include mobile {
          padding: 0 30px;
        }

        &.slick-slide {
          margin: 0 10px;
          padding: 0;

          .chef-card {
            position: relative;
            > h3 {
              opacity: 0;
              transition: opacity 0.7s ease-in-out;
            }
            > .chef-card-link {
              opacity: 0;
              transition: opacity 0.7s ease-in-out;
            }
          }
        }
      }
    }

    .chef-card {
      position: relative;
      transition: opacity 0.7s ease-in-out;

      &.active {
        > h3 {
          opacity: 1 !important;
        }
        > .chef-card-link {
          opacity: 1 !important;
        }
      }

      h3 {
        position: absolute;
        bottom: 42px;
        text-align: center;
        margin: 0 auto;
        right: 0;
        width: 100%;
        color: $cui-black-color-100;
        font-family: $cui-font-family-avallon;
        font-size: 72px;
        font-style: normal;
        font-weight: 400;
        line-height: 90%;
        letter-spacing: -1.44px;

        @media (max-width: 700px) {
          font-size: 56px;
          font-style: normal;
          font-weight: 400;
          line-height: 90%;
          letter-spacing: -1.12px;
        }

        span {
          color: $cui-yellow-color-500;
          display: block;
        }
      }

      .chef-card-link {
        position: absolute;
        z-index: 223;
        bottom: 16px;
        width: 100%;
        right: 0;
        color: $cui-black-color-100;
        font-family: $cui-font-family-tt-norms;
        font-size: 16px;
        font-style: normal;
        font-weight: 450;
        line-height: 150%;
        letter-spacing: -0.16px;
        margin: 0;
      }

      img {
        border-radius: 12px;
      }

      &-image-container {
        position: relative;
        height: 400px;
        width: 275px;
        border-radius: 12px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom center;
        transition: all 0.7s ease-in-out;

        &::before {
          content: '';
          position: absolute;
          right: 0;
          bottom: -5px;
          height: 80%;
          width: 100%;
          border-radius: 12px;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 20.37%,
            $cui-black-color 100%
          );
          transition: opacity 0.7s ease-in-out;
          opacity: 1;
        }
      }

      &.centered-far-left,
      &.centered-far-right {
        .chef-card-image-container {
          width: 140px !important;
          height: 370px !important;
        }
      }

      &:not(.active) {
        .chef-card-image-container {
          width: 100px;
          height: 340px;

          &::before {
            opacity: 0;
          }
        }
      }
    }

    .slick-arrow:before {
      content: '';
    }

    .slick-prev,
    .slick-next {
      z-index: 10;
      top: 0px;
      background-color: transparent;
      transition: all 0.2s ease-in-out;
    }

    .slick-next {
      left: 50%;
      top: 450px;

      @media (max-width: 1200px) {
        left: 51%;
      }

      @media (max-width: 600px) {
        left: 52%;
      }

      @media (max-width: 400px) {
        left: 53%;
      }
    }

    .slick-prev {
      top: 450px;
      left: 47%;

      @media (max-width: 1000px) {
        left: 45%;
      }

      @media (max-width: 600px) {
        left: 43%;
      }

      @media (max-width: 400px) {
        left: 41%;
      }
    }

    .slick-next:focus,
    .slick-next:hover,
    .slick-prev:focus,
    .slick-prev:hover {
      background-color: $cui-black-color-400;
    }
  }
}

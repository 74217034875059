@import '../../scss/mixins';
@import '../../scss/variables';

.menu-cui {
  background-color: $cui-black-color-100;

  @include mobile {
    width: 100%;
  }

  .container-grid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);

    @media (min-width: 768px) and (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1024px) and (max-width: 1030px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  ul {
    display: flex;
    padding: 0;

    @include mobile {
      margin-top: 50px;
    }

    li {
      list-style: none;
      margin-right: 47px;
      padding-bottom: 8px;
      color: $cookunity-gray-2;
      font-style: normal;
      font-size: 16px;
      line-height: 24px;

      @include mobile {
        margin-right: 24px;
      }
    }

    li:last-child {
      @include mobile {
        margin-right: 0px;
      }
    }

    .selected {
      font-weight: bolder;
      border-bottom: 3px solid $cookunity-black;
      color: $cookunity-black;
      font-weight: 800;
    }
  }

  button {
    margin: 64px auto 0;
  }

  &.new-version {
    background-color: $cookunity-white;

    #TabsAndGridCui {
      max-width: 1200px;
      padding-top: 30px;

      .containerGrid {
        .mealcard-new {
          max-width: 276px;
        }
        @media (min-width: 768px) and (max-width: 1200px) {
          max-width: 100%;
        }
      }
      .tabList {
        li {
          font-family: $cui-font-family-tt-norms;
          font-size: 14px;
          font-style: normal;
          font-weight: 450;
          padding: 0;
          height: auto;
        }

        .selected {
          border-bottom: none;
          font-weight: 700;
        }
      }

      .filters {
        @media (min-width: 768px) and (max-width: 1200px) {
          width: 30%;
        }

        @include mobile {
          margin-top: 24px;

          .filtersRow {
            width: 100%;

            .button-filters {
              margin-left: 0;
              width: 100%;
            }
          }
        }
      }
    }
  }

  &.with-tags {
    padding: 35px 0px;
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.container-row-cui  {
  flex-wrap: wrap;
}

.container-cui-list {
  max-width: 1018px;
  width: 100%;
  margin: 0 auto;

  @include mobile {
    padding: 0 20px;
  }

  @media (min-width: 768px) and (max-width: 920px) {
    justify-content: center;
    padding: 0 20px;
  }

  @media  (min-width: 920px) and (max-width: 1200px) {
    padding: 0 20px;
  }

}

.container-benefits {
  margin-bottom: 48px;
  width: 497px;

  h4 {
    margin-bottom: 8px;
    margin-top: 0;
  }

  @include mobile {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 920px) {
    width: 360px;
  }

  @media  (min-width: 920px) and (max-width: 1200px) {
    width: 50%;
  }
}

@import '../../../../scss/mixins';
@import '../../../../scss/variables';

#chef-profile-section {
  padding: 86px 0 104px;
  h2 {
    color: $cookunity-white;
    text-align: center;
    font-family: $cui-font-family-rossanova;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 38px;
  }

  .container-chef-profile {
    border-radius: 12px;
    background: $cui-black-color-800;
    max-width: 798px;
    width: 100%;
    padding: 34px 54px;
    margin: 0 auto;

    @include mobile {
      width: 90%;
      padding: 17px 20px 47px;
    }

    .row {
      display: flex;

      @include mobile {
        flex-direction: column;
        align-items: center;
      }

      img.chef-img {
        width: 142px;
        height: 142px;
        border-radius: 200px;
        object-fit: cover;
        background-image: linear-gradient(90deg, #ff891c 0%, #ffc84e 100%);
        background-origin: border-box;
        border: 7.1px solid transparent;
        box-sizing: border-box;
        margin-right: 47px;
      }

      .column {
        @include mobile {
          width: 100%;
        }
        .chefDescription {
          p {
            color: $cui-black-color-300;
            font-family: $cui-font-family-tt-norms;
            font-size: 16px;
            font-style: normal;
            font-weight: 450;
            line-height: 24px;
          }

          button {
            display: none;
          }
        }
        p.p-profile {
          color: $cookunity-white;
          font-family: $cui-font-family-tt-norms;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          margin: 24px 0 8px;
        }
      }
    }
  }
  .link-container {
    width: 100%;
    margin: 44px 0 0;
    text-align: center;
  }
}

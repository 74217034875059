@import '../../../../scss/mixins';
@import '../../../../scss/variables';

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.ReactModal__Content.modal-nav-menu-cui {
  width: 509px;
  margin: 0 !important;
  left: 0;
  height: 0;
  border-radius: 0;
  top: 0 !important;
  transform: none;
  padding: 0;

  @include mobile {
    height: 100vh;
    width: 100%;
    padding: 0 !important;
  }

  .topContainer {
    display: none;
  }

  .container {
    background: $cui-black-color-600;
    height: 100vh;
    flex-direction: column;
    overflow: auto;
    padding: 36px;
    max-width: 100%;

    @include mobile {
      padding: 32px;

      .a-brand {
        margin: 0;
      }
    }

    .col-md-3 {
      display: block;
      width: 100%;
      list-style: none;
      left: 0;
      padding: 0;
      margin-top: 80px;

      @include mobile {
        padding: 0px;
        text-align: left;
        margin-top: 60px;
      }

      li {
        color: $cookunity-white;
        margin: 0 0 49px;

        @include mobile {
          margin-bottom: 30px;
        }
      }

      .container-links {
        display: flex;
        gap: 10px;
        margin-bottom: 20px;

        @include mobile {
          flex-direction: row;
          margin-bottom: 30px;
          margin-top: 63px;
        }

        a.cui-link {
          width: 150px;
          display: flex;
          justify-content: center;
          height: 48px;
        }

        .login-link-cui {
          max-width: 155px;
          width: 100%;
          display: flex;
          justify-content: flex-end;

          .cui-link-tertiary {
            color: $cookunity-white;
          }

          a.cui-link {
            width: 100%;
          }
        }
      }
    }

    .container-menu-logo {
      display: flex;
      align-items: center;
      padding: 0px;

      @include mobile {
        margin-bottom: 28px;
      }

      .nav-icon {
        width: 24px;
        height: 24px;
        margin-right: 36px;
        background-repeat: no-repeat;
        cursor: pointer;
      }

      .menu-icon {
        background-image: url('/assets/icons/nav-icon.png');
      }

      .close-icon {
        background-image: url('/assets/icons/close.png');
        background-position: center;
        background-color: transparent;
        transform: translateX(-8px);
        margin-right: 20px;
      }
    }
  }
}

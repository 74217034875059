@import '../../scss/variables';

.cui-meal-card {
  cursor: pointer;
}
.cui-meal-card-tags {
  .cui-icon {
    align-items: center;
    height: 20px;
    width: 20px;
  }
}
.cui_meal_tag_icon {
  width: 34px;
  position: relative;

  .tooltip {
    display: none;
  }

  &:hover {
    .tooltip {
      display: flex;
    }
  }
}
.cui-card-content {
  border-top: 1px solid $cui-black-color-150;
}

.tooltip {
  position: absolute;
  background-color: $cui-black-color-600;
  color: $cookunity-white;
  text-align: center;
  padding: 2px 8px;
  border-radius: 4px;
  top: -30px;
  font-family: $cui-font-family-tt-norms;
  transform: translateX(-50%);
  left: 50%;
  font-size: 10px;
  align-items: center;
  min-height: 20px;

  &:before {
    content: ' ';
    width: 6px;
    height: 6px;
    background-color: inherit;
    position: absolute;
    bottom: -3px;
    left: calc(50% - 3px);
    transform: rotate(45deg);
  }
}

.meal-highlight-badge {
  position: absolute;
  margin: 16px 0 0 16px;
  width: fit-content;
  display: flex;
  padding: 2px 4px;
  align-items: center;
  gap: 2px;
  border-radius: 2px;
  background: $cui-gold-500;
  height: 20px;

  img.icon {
    width: 16px;
    height: 16px !important;
  }
  p {
    color: $cui-black-color;
    font-family: $cui-font-family-tt-norms;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 0px;
    text-transform: uppercase;
  }
}

@import '../../../scss/variables';

.accordion-item__container {
  padding: 24px 32px;
  margin-bottom: 8px;

  &.show_answer {
    background-color: $cui-black-color-50;
    border-radius: 12px;
    border: 1px solid $cui-black-color-200;

    &.black_mode {
      border-radius: 24px;
      border: 1px solid rgba(255, 255, 255, 0.08);
      background: $cui-black-color-800;
    }
  }

  &.black_mode {
    h3.trigger {
      color: $cui-gray-color-100;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    > .trigger-wrapper {
      .cui-icon {
        svg > path {
          fill: $cui-black-color-350;
        }
      }

      button.question {
        background-color: transparent;
        padding: 0;
      }
    }
  }

  > .trigger-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    box-sizing: border-box;
    padding: 0 !important;
    margin-bottom: 8px;

    .trigger {
      margin: 0 !important;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      text-align: flex-start;

      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }

    .trigger-arrow {
      width: 32px;
      height: 32px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      color: $cui-black-color-350;
    }

    button.question {
      background-color: transparent;
      padding: 0;
      color: black;
      text-align: left;
    }
  }

  .accordion-content {
    display: none;
    margin-top: 0;
    color: $cui-black-color-350;
  }

  .accordion-content.visible {
    display: block;
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

#chefsCarrousel-cui {
  @include mobile {
    background-color: $cui-black-color-600;
    text-align: center;
  }

  .container-cui-row {
    align-items: flex-start;
    height: 340px;

    @media (max-width: 878px) {
      flex-direction: column-reverse;
      height: 600px;
      justify-content: space-between;
    }

    .col {
      width: 100%;

      @media (max-width: 878px) {
        min-height: 190px;
      }
    }

    .col-text {
      @media (max-width: 878px) {
        padding: 0 20px;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .cui-link {
          width: 184px;
        }
      }
    }
  }

  section.chef-section {
    display: flex;
    align-items: center;
    color: $cookunity-black;
    position: relative;
    background: $cui-black-color;
    padding: 0;
    padding-bottom: 40px;
    min-height: 340px;

    @media (max-width: 878px) {
      padding: 0;
      min-height: 0;
    }

    @media (max-width: 878px) {
      min-height: 600px;
    }

    .container {
      position: relative;
      z-index: 1;

      @media (max-width: 878px) {
        padding: 0;
      }
    }

    img {
      display: inline-block;
    }

    .by-restaurant {
      display: flex;
      margin-bottom: 32px;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: $cookunity-white;

      @media (max-width: 878px) {
        display: inline-flex;
        margin: 0 auto;
      }

      img {
        margin-left: 10px;
        border-radius: 5px;
        max-width: 88px;
        max-height: 64px;
      }
    }

    p.rebrand.upper {
      margin-top: 40px;
      color: $cookunity-white;

      @media (max-width: 878px) {
        margin: 0 auto;
      }
    }

    h2 {
      margin: 0px 0px 22px;
      color: $cookunity-white;

      @media (max-width: 878px) {
        text-align: center;
      }

      @media screen and (max-width: 1060px) {
        font-size: 32px;
        line-height: 44px;
      }

      &.ourCreators {
        margin-bottom: 0px;

        @media (max-width: 878px) {
          margin-bottom: 10px;
        }
      }
    }

    .cui-link {
      color: $cookunity-white;

      @media (max-width: 878px) {
        width: 230px;
        margin: 30px auto 0;
        display: block;
      }
    }

    .quote {
      p {
        max-width: 260px;
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 40px;
        margin-top: 0;

        @media (max-width: 878px) {
          max-width: 100%;
        }
      }
    }

    .chef-photo-container {
      position: relative;

      .chef-photo {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        height: 300px;
        width: 600px;
        max-width: 100%;
        position: relative;

        @include mobile {
          background-position: right center;
          margin: 0;
        }

        @media (max-width: 878px) {
          width: 100%;
        }
      }
    }
  }

  .slick-dots {
    position: relative;
    top: -60px;
    text-align: left;
    margin-left: 6px;
    max-width: 600px;
    left: 46.5%;
    margin-bottom: 25px;
    display: flex !important;
    justify-content: center;

    @media (min-width: 878px) and (max-width: 1060px) {
      left: 30%;
      width: 100%;
      top: -60px;
    }

    @media (max-width: 878px) {
      width: 100%;
      left: 0;
      top: -280px;
      margin: 0 auto;
    }

    ul li {
      background: $cui-yellow-color-600;

      &:after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      button {
        width: 100%;
        position: relative;
        top: -5px;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    li.slick-active {
      position: relative;
      background: $cookunity-white;
      border-radius: 6px;

      &:after {
        animation: dotsTimer 4s linear;
        background: $cui-yellow-color-600;
      }
    }

    li.slick-active ~ li {
      background: $cookunity-white;
      border-radius: 6px;
    }

    ul {
      margin: 0px;
      padding: 0px;
      margin-top: 14px !important;
    }

    li {
      background: $cui-yellow-color-600;
      position: relative;
      display: inline-block;
      width: 40px;
      height: 2px;
      padding: 0;
      cursor: pointer;
      margin: 0 5px;
      border-radius: 6px;

      @media (max-width: 878px) {
        width: 30px;
      }
    }
  }
}

#chefsCarrousel .slick-dots li button:before {
  content: '';
}

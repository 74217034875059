@import '../../scss/mixins';
@import '../../scss/variables';

.navbar-menu-cui {
  position: relative;
  z-index: 4;
  top: 0;
  width: 100%;

  .container {
    padding: 13px;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include mobile {
      padding: 10px;
    }

    .container-a11y {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .login-link-cui {
        max-width: 180px;
        width: 100%;
        display: flex;
        justify-content: flex-end;

        @include mobile {
          display: none;
        }

        .cui-link {
          margin-right: 20px;
          color: $cookunity-white;
          border-color: $cookunity-white;
        }
      }
    }

    .container-menu-logo {
      display: flex;
      align-items: center;

      @include mobile {
        width: 100%;
        padding-right: 24px;
      }

      .nav-icon {
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        cursor: pointer;
        margin-right: 33px;

        @include mobile {
          margin-right: 0px;
        }
      }

      .menu-icon {
        background-image: url('/assets/icons/nav-icon.png');
      }

      .close-icon {
        background-image: url('/assets/icons/close.png');
        cursor: pointer;
      }
    }

    .container-inputzip {
      display: none;

      @include mobile {
        .promoText {
          padding-top: 12px;
        }
      }
    }

    .col-md-3 {
      display: none;
    }
  }

  &.with-children {
    .children-nav {
      display: none;
    }
  }
}
.home.navbarstickyEl-CUI {
  position: relative;
}

.whithTransparentBg.navbarstickyEl-CUI {
  background: transparent;
  position: absolute;
}

.no-sticky,
.navbarstickyEl-CUI {
  background: $cui-black-color-600;
  width: 100%;
  position: sticky;
  z-index: 99;

  .sticky {
    width: 100% !important;
    box-shadow: 0px 4px 6px #0000000f;
    background: $cui-black-color-600;
    top: 0;

    @include mobile {
      bottom: 0;
      top: unset !important;
      background: $cookunity-white;
      padding-bottom: 0px;
      padding-top: 0px;
      box-shadow: 0px -8px 59px #0000000d;
    }

    &.with-children {
      background: $cookunity-white;

      .children-nav {
        display: none;
      }
    }

    .navbar-menu-cui {
      .container {
        @include mobile {
          padding: 13px;
        }

        .menu-icon {
          background-image: url('/assets/icons/nav-icon.png');
        }

        .container-inputzip {
          display: block;

          .promoText {
            text-align: center;
            color: $cui-black-color;
            display: none;
            margin-bottom: 10px;

            @include mobile {
              display: block;
            }
          }

          .zip-form-cui {
            width: 360px;

            @include mobile {
              width: 100%;
            }

            .container-input input::-webkit-input-placeholder {
              color: $cookunity-white;

              @include mobile {
                color: $cui-black-color;
              }
            }

            .container-input input {
              color: $cookunity-white;

              @include mobile {
                color: $cui-black-color;
              }
            }

            .container-input input:-webkit-autofill,
            input:-webkit-autofill:focus {
              transition: background-color 600000s 0s, color 600000s 0s;
            }
          }
        }

        @include mobile {
          display: block;
          max-width: 100%;
          padding-top: 0;

          .container-menu-logo {
            display: none;
          }
        }
      }

      .container-inputzip {
        display: inline;

        @include mobile {
          padding-top: 12px;
        }
      }

      &.with-children {
        .children-nav {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          max-width: 1400px;
          margin: 0 auto;

          @include mobile {
            p {
              width: 60%;
            }
          }
        }

        .container {
          padding: 17px;
        }

        .container-inputzip,
        .container-a11y {
          display: none;
        }
      }
    }
  }
}

.no-sticky {
  position: relative;
  z-index: 1;
}

.navbarstickyEl.new-home {
  z-index: 1;
  background: transparent;

  @include mobile {
    z-index: 100;
    background: linear-gradient(
      90.39deg,
      $cui-black-color 7.66%,
      #05211c 82.1%
    );
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    background: linear-gradient(
      90.39deg,
      $cui-black-color 7.66%,
      #05211c 82.1%
    );
  }
}

.navbarstickyEl.sustainability-menu {
  background: linear-gradient(90.39deg, $cui-black-color 7.66%, #05211c 82.1%);
  position: relative;
}

.navbarstickyEl-CUI.with-children {
  .sticky {
    background-color: $cookunity-white;
  }
}

.withTransparentBg {
  background: transparent;
  position: absolute;

  &.from-cms {
    margin-top: 54px;
  }
}

@import '../../../../scss/mixins';
@import '../../../../scss/variables';

#most-loved-chefs-cui {
  padding: 0;
  margin: 0 auto 90px;
  width: 916px;
  border-radius: 12px;
  background: $cookunity-white;
  box-shadow: 0px 2px 6px 0px rgba(35, 31, 32, 0.05),
    0px 0px 6px 0px rgba(35, 31, 32, 0.1);

  @media (max-width: 920px) {
    width: 90%;
  }

  .chefs-container-cui {
    background-color: $cui-black-color-100;
    display: flex;
    align-items: center;

    @media (max-width: 920px) {
      width: 100%;
    }

    .separator {
      width: 1px;
      height: 98px;
      background: $cui-black-color-200;

      @include mobile {
        display: none;
      }
    }

    a:hover {
      text-decoration: none;
    }

    .container-cui {
      max-width: 1110px;
      padding: 30px 30px 22px;

      @include mobile {
        padding: 30px 20px 22px;
        max-width: 100%;
        flex-direction: row;
      }

      h3 {
        margin: 0 0 7px;

        @media (max-width: 920px) {
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
      }

      .chef-image {
        width: 80px;
        height: 80px;
        border-radius: 100%;
        object-fit: cover;
        margin-right: 16px;
      }

      .tag {
        margin: 0;
      }
    }

    .container-description {
      @include mobile {
        display: none;
      }
    }

    p.chef-description {
      font-size: 14px;
      font-style: normal;
      font-weight: 450;
      line-height: 16px;
      margin: 0;
    }

    p.compact {
      -webkit-box-orient: vertical !important;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 5;
    }
  }

  .meals-container {
    justify-content: space-between;
    padding: 50px 40px;

    .product-card {
      align-items: flex-start;
      display: flex;
      margin-bottom: 32px;

      img.product-image,
      .no-img-container {
        width: 100px;
        height: 100px;
        border-radius: 4px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin-right: 16px;
        object-fit: cover;
      }

      .meal-info {
        width: 274px;

        @include mobile {
          width: 100%;
        }

        p.bold {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          margin-bottom: 2px;
        }

        p {
          font-size: 12px;
          font-style: normal;
          font-weight: 450;
          line-height: 16px;
          margin: 0 0 9px;
        }

        p.rating {
          margin: 0 1px;
        }
      }
    }
  }
}

#most-loved-chefs-carroysel-cui {
  max-width: 1030px;
  width: 100%;
  margin: 40px auto;

  ul.slick-dots {
    bottom: 20px;
    position: relative;
  }

  .slick-dots li {
    margin: 0;
  }

  .slick-dots li button:before {
    font-size: 36px;
  }

  .slick-dots li.slick-active button:before {
    color: $cui-yellow-color-600;
    opacity: 0.75;
    font-size: 40px;
  }

  .slick-next,
  .slick-prev {
    top: auto;
    bottom: 50%;
    width: 39px;
    height: 39px;
    z-index: 1;
    background: $cookunity-white;
    border-radius: 200px;
  }

  .slick-next {
    right: 0;
  }

  .slick-prev {
    left: 0;
  }

  .slick-disabled {
    opacity: 0.5;
    pointer-events: none;
    background: $cookunity-white;
    border-radius: 200px;
  }

  .slick-list {
    @media (max-width: 920px) {
      padding: 0 !important;
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.section-title-cui {
  text-align: center;
  padding: 60px 0 28px 0;
  background: $cui-black-color-600;

  h2 {
    color: $cui-yellow-color-500;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    margin-top: 0;

    @include mobile {
      font-size: 28px;
    }

    &.font-rossanova {
      font-family: $cui-font-family-rossanova;
    }
    &.font-tt-norms {
      font-family: $cui-font-family-tt-norms;
    }
  }

  p.rebrand {
    color: $cui-black-color-300;
    max-width: 39%;
    width: 100%;
    margin: 0 auto;

    @include mobile {
      max-width: 90%;
    }

    @media (min-width: 768px) and (max-width: 1090px) {
      max-width: 80%;
    }

    @media (min-width: 1090px) and (max-width: 1290px) {
      max-width: 60%;
    }
  }

  &.black-background {
    h2 {
      color: $cookunity-white;

      @include mobile {
        line-height: 36px;
      }
    }

    p {
      color: $cookunity-white;
    }
  }
}

#icons-list {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  gap: 20px;

  @media (max-width: 1162px) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 42px;
  }

  @include mobile {
    flex-direction: column;
    align-items: center;
    gap: 42px;
  }

  .icon-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: 1;
    gap: 16px;

    .icon-wrapper {
      background-color: $cookunity-white;
      border-radius: 12px;
      padding: 8px;

      .icon {
        width: 64px;
        height: 64px;
        object-fit: contain;
      }
    }

    .icon-text {
      margin: 0;
      color: $cui-black-color-850;
      text-align: center;
      font-family: $cui-font-family-tt-norms;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: -0.32px;
    }
  }
}

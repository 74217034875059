@import '../../../scss/variables';

.gift-card-buying-hero-part-container {
  .buttons-wrapper {
    display: flex;
    justify-content: space-between;

    button {
      span {
        color: $cookunity-black;
      }
    }

    button:first-child {
      background-color: $cookunity-green;
    }

    button:last-child {
      background-color: $cookunity-white;
      border: 1px solid $cookunity-black;
      border-radius: 200px;
    }
  }

  @media (min-width: 1024px) {
    p {
      max-width: 300px;
      margin-bottom: 32px !important;
    }

    button {
      padding: 12px 28px;
    }
  }

  @media (max-width: 1023px) {
    h1 {
      font-size: 28px;
    }

    p {
      font-size: 14px;
    }

    button {
      span {
        font-size: 14px !important;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    button {
      padding: 12px 5%;
    }
  }

  @media (max-width: 767px) {
    h1,
    p {
      text-align: center;
    }

    p {
      max-width: 290px;
      margin-left: auto;
      margin-right: auto;
    }

    button {
      padding: 12px 24px;
    }
  }

  @media (max-width: 424px) {
    h1 {
      font-size: 23px;
    }

    p {
      font-size: 12px;
    }

    .buttons-wrapper {
      flex-direction: column;
      align-items: center;

      button {
        max-width: 222px;
        width: 100%;
      }

      button:first-child {
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 320px) {
    h1 {
      font-size: 20px;
    }
  }
}

@import '../../scss/variables';

.cui-snapshot-attributes {
  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 0;

    .slick-track {
      display: flex;

      &::after,
      &::before {
        display: none;
      }

      @media (min-width: 1330px) {
        justify-content: space-between;
        background-color: $cui-black-color-100;
        border-radius: 12px;
      }
    }
    .slick-list {
      overflow: visible;
      width: 100%;
    }

    .slick-slide:nth-child(1) {
      .cui-snapshot-attributes-item {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }
    }
    .slick-slide:nth-child(5) {
      .cui-snapshot-attributes-item {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
  }
  &-item {
    white-space: nowrap;
    background-color: $cui-black-color-100;
    display: flex !important;
    padding: 12px;
    align-items: center;
    gap: 4px;
    width: fit-content;

    p {
      font-size: $cui-font-sm;
      color: $cookunity-gray-1;
      margin: 0;
    }

    svg {
      height: 18px;
      fill: $cookunity-gray-1;
    }
  }
}

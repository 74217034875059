@import '../../../scss/variables';

.accordion-item-container {
  border-bottom: 1px solid $cui-black-color-200;

  .trigger-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 31px 0;
    box-sizing: border-box;

    .trigger {
      margin: 0;
    }

    .trigger-arrow {
      width: 24px;
      height: 24px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      color: $cui-black-color-800;
    }

    .trigger-arrow.hidden {
      background-image: url('/assets/icons/arrow-down.svg');
    }

    .trigger-arrow.visible {
      background-image: url('/assets/icons/arrow-up.svg');
    }
  }

  .accordion-content {
    display: none;
    margin-top: 0;
    margin-bottom: 35px;
  }

  .accordion-content.visible {
    display: block;
  }
}

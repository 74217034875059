@import '../../../../../scss/mixins';
@import '../../../../../scss/variables';

.plan-selection-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  > h3 {
    font-size: 16px;
    font-family: $cui-font-family-tt-norms;
    margin: 0;
    text-align: left;
  }

  > p {
    font-family: $cui-font-family-tt-norms;
    color: $cui-black-color-300;
    font-weight: 450;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .plan-selection-cards-container {
    display: flex;
    gap: 10px;
    margin-top: 5px;
  }

  .plan-selection-card-wrapper {
    display: flex;
    flex-direction: column;
    width: 20%;
    cursor: pointer;

    :hover {
      background-color: $cui-black-color-100;
    }

    img {
      display: none;
    }

    > .active {
      background-color: $cui-wine-color-500;
      color: white;
      img {
        display: block;
      }
      :hover {
        background-color: $cui-wine-color-500;
      }
    }

    &.with-price {
      > div {
        justify-content: flex-start;
        padding: 22px 5px 0;

        @include mobile {
          padding: 18px 5px 0;
        }
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 28px;
        }
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 85px;
      padding: 18px 5px;
      border-radius: 8px;
      box-shadow: 0px 0px 2px rgba(35, 31, 32, 0.1),
        0px 2px 2px rgba(0, 0, 0, 0.05);

      @include mobile {
        height: 65px;
      }

      > span {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}

@import '../../../../scss/mixins';
@import '../../../../scss/variables';

.heroFadedImageContainer {
  display: flex;
  flex-direction: column;
  background-color: $cui-black-color-600;
  align-items: center;
  .imageContainer {
    display: flex;
    background: linear-gradient(
        to right,
        rgba(0, 0, 0, 1) 15%,
        rgba(0, 0, 0, 0) 60%
      ),
      var(--backgroundImg) no-repeat center center / cover;
    width: 100%;
    height: 464px;
    @include mobile {
      width: 100%;
      background: linear-gradient(
          to top,
          rgba(0, 0, 0, 1) 25%,
          rgba(0, 0, 0, 0) 65%
        ),
        var(--backgroundImg) no-repeat center center / cover;
    }
    .contentContainer {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin-left: 5%;
      justify-content: center;
      @include mobile {
        align-items: center;
        justify-content: flex-end;
        margin: 0;
        padding-bottom: 8%;
      }
      .heroTitle {
        font-family: $cui-font-family-rossanova;
        font-style: normal;
        color: $cookunity-white;
        font-weight: bold;
        font-size: 50px;
        line-height: 1em;
        span {
          font-family: $cui-font-family-rossanova;
          color: $cui-yellow-color-500;
          display: inline;
          font-size: 50px;
          line-height: 100%;
          letter-spacing: -0.02em;
        }
        @include mobile {
          display: flex;
          flex-direction: column;
          text-align: center;
          font-size: 32px;
          width: 100%;
          span {
            font-size: 32px;
          }
        }
      }
      p {
        font-family: $cui-font-family-tt-norms;
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 24px;
        color: $cookunity-white;
        width: 460px;
        @include mobile {
          width: 100%;
          text-align: center;
        }
      }
      .container-input {
        background-color: $cookunity-white;
      }
      button {
        height: 48px;
        max-width: 200px;
        color: $cui-black-color;
        background: $cui-yellow-color-500;
        font-family: $cui-font-family-tt-norms;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
      }
    }
  }
}

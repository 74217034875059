@import '../../../../scss/mixins';
@import '../../../../scss/variables';

.for-you.only-desktop {
  display: flex;
  @include mobile {
    display: none;
  }
}

.for-you.only-mobile {
  display: none;

  @include mobile {
    display: block;
  }
}

.for-you {
  position: relative;
  display: flex;
  justify-content: center;

  @include mobile {
    width: 100%;
  }

  .container {
    background: $cookunity-white;
    box-shadow: 4px 0px 17px rgba(0, 0, 0, 0.11);
    border-radius: 9px;
  }

  .container.recommendation-review {
    position: absolute;
    top: -50px;
    padding: 25px 18px 25px 51px;
    max-width: 768px;
    width: 100%;
    margin: 0 auto;
    text-align: left;

    @include mobile {
      position: relative;
      padding: 34px 25px 25px 25px;
      max-width: 100%;
      top: 28px;
    }

    .productChefReview {
      flex: 1;
      width: 100%;
      margin: 0;
      max-width: 100%;

      p.compact {
        -webkit-box-orient: vertical !important;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        height: 51px;

        @include mobile {
          -webkit-line-clamp: 3;
          height: 72px;
        }
      }
    }
  }
}

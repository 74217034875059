@import '../../../../../scss/mixins';
@import '../../../../../scss/variables';

.plan-value-container {
  display: flex;
  border-radius: 8px;
  background-color: $cui-black-color-100;
  margin-top: 20px;
  width: 100%;

  .plan-value-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 20px 50px;
    align-items: center;
    justify-content: center;

    .offer-label-wrapper {
      display: flex;
      background-color: $cui-olive-green-color-500;
      align-items: center;
      justify-content: center;
      border-radius: 4px;

      > span {
        color: white;
        text-transform: uppercase;
        text-align: center;
        font-size: 8px;
        font-weight: 700;
        padding: 5px 10px;
      }
    }

    > p {
      font-size: 12px;
      font-weight: 450;
      color: $cui-black-color-300;
      text-decoration: line-through;
      margin: 15px 0 0;
    }

    > span {
      color: $cui-black-color;
      font-size: 18px;
      padding-bottom: 5px;
    }
  }
}

@import '../../scss/mixins';

.full-video-component {
  .container-video {
    max-width: 1080px;
    padding-bottom: 76px;
    margin: 0 auto;
    text-align: center;

    .video-background {
      max-width: 1013px;
      margin: 0 auto;
      width: 100%;

      video {
        max-width: 100%;
        margin: 0 auto 32px;

        @include mobile {
          max-width: 100%;
        }

        @media (min-width: 768px) and (max-width: 1040px) {
          max-width: 100%;
        }
      }
    }

    p.lead {
      margin: 28px auto 25px;
      text-align: center;

      @include mobile {
        max-width: 90%;
        width: 100%;
      }
    }

    p.subtitle {
      width: 700px;
      text-align: center;
      max-width: 80%;
      margin: 0 auto 24px;

      @include mobile {
        max-width: 90%;
        width: 100%;
      }
    }

    .cui-link {
      margin: 0 auto;
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

#info-with-icons {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 0 50px 0;

  .info-with-icons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 1435px;
    width: 100%;
    height: auto;
    padding: 31px 200px 41px;
    border-radius: 8px;
    background-color: $cui-black-color-250;

    @media (max-width: 1162px) {
      flex-direction: column;
      padding: 27px 21px;
      text-align: center;
      justify-content: center;
    }

    @include mobile {
      padding: 12px 0 32px;
    }

    .text-secion-wrapper {
      padding-bottom: 42px;
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.value-container-cui {
  display: flex;
  justify-content: space-around;
  display: flex;
  margin: 0 auto;
  gap: 35px;
  flex-wrap: wrap;
  text-decoration: none;

  &.padding {
    padding: 63px 0;

    @include mobile {
      padding: 52px 20px;
    }
  }

  &.padding-bottom {
    padding: 0 0 63px;

    @include mobile {
      padding: 0 20px 63px;
    }
  }

  @media (max-width: 920px) {
    flex-direction: row;
  }

  .image-value-container {
    width: 294px;
    text-align: center;

    @media (min-width: 768px) and (max-width: 1078px) {
      max-width: 240px;
      width: 100%;
    }

    @include mobile {
      width: 100%;
    }

    .container-img-icon {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin: 0 auto;
      margin-bottom: 29px;
      overflow: hidden;
    }

    p.rebrand.lead {
      color: $cui-black-color;
    }

    p.rebrand {
      color: $cui-black-color-400;
    }
  }

  .image-value-container.left {
    text-align: left;

    .container-img-icon {
      justify-content: flex-start;
    }

    &:first-child {
      margin-top: 8px;
    }

    p.rebrand.lead {
      margin-bottom: 8px;
    }
  }

  .image-value-container.column-mobile {
    @include mobile {
      flex-direction: column;
      padding: 0 20px;

      p.rebrand {
        text-align: center;
      }
    }
  }

  &.center {
    justify-content: center;
  }

  &.careers {
    gap: 0;
    justify-content: center;

    .image-value-container {
      margin-bottom: 97px;

      .container-img-icon.container-link {
        border-radius: 8px;
      }
    }
  }

  &.black-background {
    .image-value-container.left:first-child {
      margin-top: 0;
    }

    p.rebrand.lead {
      color: $cui-black-color-400;
    }
  }
}

.column-mobile {
  @include mobile {
    display: flex;
    flex-direction: column;

    .image-value-container {
      width: 100%;
      display: flex;
      margin-bottom: 38px;
      align-items: center;

      .icon-value-overflow {
        width: 60%;
        height: 100%;
        margin: 0 0 0 -55px;
      }
    }

    h3,
    p {
      text-align: left;
    }
  }
}

.container-cui.value-container-cui.enjoy-rebrand {
  justify-content: flex-start;
  margin-top: 0;
  display: flex;
  margin-bottom: 37px;
  align-items: baseline;
  padding: 0;

  @media (min-width: 600px) and (max-width: 920px) {
    padding: 0 20px;
    flex-wrap: nowrap;
  }

  .image-value-container {
    max-width: 299px;
    margin-right: 22px;

    @include mobile {
      flex-direction: column;
      max-width: 265px;
      margin: 0 auto 38px;
    }

    .container-img-icon {
      justify-content: flex-start;

      @include mobile {
        justify-content: center;
      }
    }

    @media (min-width: 768px) and (max-width: 1078px) {
      max-width: 265px;
    }

    img {
      margin: 0;
    }

    h3,
    p {
      text-align: left;

      @include mobile {
        text-align: center;
      }
    }

    p.rebrand.lead {
      color: $cui-black-color;
    }
  }

  .image-value-container:last-child {
    .icon-value {
      height: 69px;
    }
  }
}

.container-cui.value-container-cui.center {
  justify-content: center;
}

.container-cui.value-container-cui.padding-top {
  padding-top: 0;
}

.container-cui.value-container-cui.with-mobile {
  @include mobile {
    gap: 10px;

    .image-value-container {
      width: 105px;
    }

    .container-img-icon {
      width: 105px;
      height: 105px;
    }
  }
}

.hero-cui
  .half-container-row
  .container-cui.value-container-cui.hero-our-value {
  position: absolute;
  right: 0px;
  z-index: 1;
  bottom: 0;
  width: 100%;
  background: $cui-yellow-color-500;
  border-radius: 0px;
  height: 114px;
  padding: 30px 20px;
  display: flex;
  align-items: center;
  max-width: 100%;
  gap: 20px;
  flex-wrap: nowrap;
  flex-direction: row;

  @media (max-width: 920px) {
    position: absolute;
    right: 0px;
    bottom: 0;
    width: 640px;
    background: $cui-yellow-color-500;
    border-radius: 0px;
    width: 100%;
    text-align: center;
  }

  .image-value-container {
    display: flex;
    width: 33%;
    align-items: center;

    @include mobile {
      flex-direction: column;
      width: 29%;
    }

    .container-img-icon {
      margin-bottom: 0;
    }

    img {
      width: 35px;
      height: auto;
    }

    p.rebrand {
      color: $cui-black-color;
      text-align: center;
      margin-left: 8px;

      @include mobile {
        text-align: center;
        margin-left: 0;
        margin-top: 8px;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
      }
    }

    p.rebrand.small.normal {
      @include mobile {
        display: none;
      }
    }
  }
}

.width {
  @media (min-width: 768px) and (max-width: 1024px) {
    max-width: 80%;
  }

  .container-img-icon {
    width: 105px;
    height: 105px;
  }
}

.small-mobile {
  max-width: 980px;
  margin-bottom: 33px;

  @include mobile {
    gap: 44px;
  }

  .image-value-container {
    width: 294px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container-img-icon {
    margin-bottom: 0 !important;
  }
}

.carreers-values.value-container-cui
  .image-value-container:last-child
  .container-img-icon {
  margin-bottom: 4px;
}

.carreers-values.value-container-cui {
  justify-content: center;
}

.referral.value-container-cui .column-mobile .container-img-icon {
  .icon-value {
    @include mobile {
      width: 118px;
      height: 118px;
      object-fit: cover;
    }
  }
}

.icons-restaurants {
  .image-value-container {
    .container-img-icon {
      .icon-value {
        max-width: 104px;
        max-height: 104px;
      }
    }
  }
}

.our-menu-new.container-cui.value-container-cui {
  padding: 15px 0px 148px;

  @include mobile {
    padding: 15px 0 96px;
  }
}

@import '../../../../../scss/variables';

.cui-gift-card--input {
  padding: 0px;
  border-radius: 0px;
}

.input-error {
  color: $cookunity-red;
  border: 0;
  font-size: 14px;
  font-family: $cui-font-family-tt-norms;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
}

.gift-card-range-div {
  margin-top: 14px;
}

.gift-card-range-label {
  font-size: 16px;
  font-family: $cui-font-family-tt-norms;
  line-height: 24px;
  color: $cui-black-color;
}

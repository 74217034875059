@import '../../../../scss/mixins';
@import '../../../../scss/variables';

#nutritional-info-cui {
  padding: 0 0 66px;

  .nutritional_container {
    display: flex;
    gap: 20px;
    max-width: 1400px;
    justify-content: center;
    margin: 0 auto 40px;

    @media (max-width: 1124px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .cui-button-lp-underline_black {
    margin: 0 auto;
  }
}

.nutri-box {
  width: 402px;
  height: auto;
  border-radius: 12px;
  background: $cui-black-color-250;
  padding: 32px 30px;

  @media (max-width: 1124px) {
    height: auto;
    margin: 0 auto;
    width: 90%;
  }

  .button-underline {
    color: $cui-black-color;
    width: 100%;
    background-color: transparent;
    font-family: $cui-font-family-tt-norms;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    text-decoration-line: underline;
    padding: 16px 0px 0px 0px;
  }

  p.nutri-title {
    font-family: $cui-font-family-tt-norms;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    margin: 0 0 16px;
  }

  p {
    font-family: $cui-font-family-tt-norms;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 20px;
    margin: 0;
  }

  p.p-comment {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .stars-row {
    display: flex;

    img.ratingStar {
      width: 16px;
      height: 16px;
    }
  }

  .row-review-stars {
    border-radius: 12px 12px 0px 0px;
    background: $cookunity-white;
    margin-bottom: 1px;
    padding: 14px 20px;
    display: flex;
    align-items: center;
    margin-top: 6px;

    p.p-stars {
      font-family: $cui-font-family-tt-norms;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      margin: 0;
    }

    .stars-row {
      margin: 0 30px 0 8px;
    }
  }

  .reviews-container {
    border-radius: 0px 0px 12px 12px;
    background: $cookunity-white;
    padding: 12px 20px;
  }

  .product-review {
    p {
      margin: 3px 0 1px;
    }

    .p-customer {
      color: $cui-black-color-300;
    }
  }

  .row-item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 4px;

    p {
      color: $cui-black-color-600;
      margin: 8px 0 0;
    }

    .dots {
      border-top: 2px dotted $cui-black-color-200;
      width: 100%;
      margin: 17px 20px 0;
    }
  }

  .slick-disabled {
    display: none !important;
  }

  .slick-prev {
    left: -32px;
  }

  .slick-next {
    right: -32px;
  }

  .slick-prev,
  .slick-next {
    width: 24px;
    height: 24px;
    border-radius: 200px;
    background: $cookunity-white;
    opacity: 0.75;
    border: $cookunity-white;

    img {
      width: 16px;
      height: 16px;
    }

    @include mobile {
      display: none;
    }
  }

  .slick-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 2px 0px rgba(35, 31, 32, 0.05),
      0px 0px 2px 0px rgba(35, 31, 32, 0.1);
    z-index: 9;

    &.slick-prev:before,
    &.slick-next:before {
      content: '';
    }

    &.slick-next {
      img {
        transform: rotate(180deg);
      }
    }

    &:hover {
      opacity: 1;
      color: $cookunity-white;
      background-color: $cookunity-white;
    }

    &.slick-next:focus,
    &.slick-next:hover,
    &.slick-prev:focus,
    &.slick-prev:hover {
      opacity: 1;
      color: $cookunity-white;
      background-color: $cookunity-white;
    }
  }
}

.ReactModal__Content.nutrition-modal {
  width: 540px !important;
  height: 515px;
  top: 50% !important;
  position: absolute;
  overflow: hidden auto;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 20px !important;
  max-width: 915px;
  border-radius: 12px;

  @include mobile {
    width: 90% !important;
    top: 13% !important;
    padding: 20px 0px !important;
  }

  .topContainer {
    padding: 0 20px;
  }
  .nutrition-modal-container {
    .container-modal {
      height: 308px;
      overflow: scroll;
      padding: 0 32px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    h2 {
      margin: 0 0 32px;
      padding: 0 32px;
    }
    .product-review {
      max-width: 222px;
      width: 100%;
      margin-bottom: 30px;
      .stars-row {
        display: flex;
        margin-bottom: 3px;

        img.ratingStar {
          width: 16px;
          height: 16px;
        }
      }

      p {
        margin: 0;
      }

      p.p-comment {
        overflow: visible;
      }

      p.p-customer {
        color: $cui-black-color-300;
        font-weight: 450;
      }
    }

    .instructions_info {
      p {
        font-family: $cui-font-family-tt-norms;
        font-size: 14px;
        font-style: normal;
        font-weight: 450;
        line-height: 24px;
        color: $cui-black-color-400;
        margin: 0;
      }
      p.bold {
        font-size: 16px;
        font-weight: 700;
        color: $cui-black-color;
      }
    }

    .sticky-close {
      box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.07);
      padding: 24px;
      text-align: center;
      button {
        margin: 0 auto;
      }
    }
  }
}

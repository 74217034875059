@import '../../scss/mixins';
@import '../../scss/variables';

.footer-landings-seo {
  background-color: $cui-yellow-color-500;
  padding: 80px 0 48px 0;

  @media (max-width: 767px) {
    > button {
      display: none;
    }
  }

  .container-cui {
    display: flex;
    justify-content: space-between;
    padding: 0 100px;

    @include mobile {
      flex-direction: column;
      padding: 0 20px;
    }

    @media (min-width: 768px) and (max-width: 1090px) {
      padding: 0 20px;
    }

    .accordion-container {
      width: 24%;

      @include mobile {
        width: 100%;
      }

      @media (max-width: 767px) {
        margin-bottom: 16px;
      }

      ul.accordion-list {
        padding: 0;
        margin: 24px 0 40px;
        text-align: left;

        @include mobile {
          display: none;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          justify-content: space-between;

          &.opened {
            display: grid;
          }
        }

        &.desktop {
          display: block;
        }

        li {
          list-style: none;
          margin: 0 0 16px;

          @include mobile {
            width: 164px;
          }
        }
      }
    }
  }

  p,
  a {
    color: $cookunity-white;
  }

  p.title {
    margin: 0;
    color: #26272b;
    font-size: $cui-font-base;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.16px;
    cursor: pointer;

    @include mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    span {
      display: none;

      @media (max-width: 767px) {
        display: block;
      }
    }
  }

  a.link {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.6);
    font-size: $cui-font-sm;
    font-style: normal;
    font-weight: 450;
    line-height: 150%;
    letter-spacing: -0.14px;

    &:hover {
      font-weight: 600;
    }
  }

  .cui-button-lp-no-style {
    margin: 0 auto;
    font-weight: 500;
  }
}

@import '../../../../scss/mixins';
@import '../../../../scss/variables';

.hero-recommendation-share.without-review {
  @include mobile {
    padding: 50px 0 92px;
  }
}

.hero-recommendation-share {
  background-image: url('/assets/images/for-you/background-for-you.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  padding: 50px 0 24px;

  @include mobile {
    background-image: url('/assets/images/for-you/background-for-you-mobile.jpg');
    background-size: cover;
    background-position: center;
  }

  .container {
    display: flex;

    @include mobile {
      flex-direction: column;
      align-items: center;
      text-align: center;

      form {
        .container-input {
          input {
            width: 100%;
            margin-bottom: 16px;
          }
        }
      }

      .zip-btn {
        margin: 0 auto;
        width: 100%;
      }
    }

    h5 {
      font-weight: 400;
      margin: 0;
    }

    h2 {
      margin: 4px 0 25px;
    }

    .container-image-recommendation-meal {
      margin-right: 41px;
      position: relative;
      max-width: 197px;
      width: 100%;

      @include mobile {
        max-width: 191px;
        margin: 0 auto;
      }
      .recomendation-meal {
        background: $cookunity-white;
        box-shadow: 0px 0px 6px rgba(35, 31, 32, 0.1),
          0px 2px 6px rgba(35, 31, 32, 0.05);
        border-radius: 12px;
        max-width: 197px;
        width: 100%;
        height: 214px;
        margin-right: 41px;
        position: absolute;
        object-fit: cover;

        @include mobile {
          position: relative;
          height: 184px;
          margin-bottom: 23px;
          margin-right: 0;
        }
      }
    }

    .notransition .zip-form {
      &:hover {
        .arrow-icon {
          transform: none !important;
        }
      }

      .container-input {
        margin-right: 19px;

        @include mobile {
          margin-right: 0;
        }
      }
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

#terms-cui {
  padding: 50px 0;
  color: $cui-black-color;

  h2 {
    span {
      color: $cui-yellow-color-500;
    }
  }

  h1 {
    margin: 0;
    font-family: $cui-font-family-tt-norms;
    font-size: 64px;
    margin-bottom: 52px;

    @include mobile {
      font-size: $cui-font-4xl;
      line-height: 44px;
      margin-bottom: 30px;
    }
  }

  h2 {
    font-family: $cui-font-family-tt-norms;
    font-size: 36px;
    line-height: 44px;

    @include mobile {
      font-size: 28px;
      line-height: 32px;
    }
  }

  p {
    font-family: $cui-font-family-tt-norms;
    font-weight: 450;
    font-size: $cui-font-base;
    line-height: $cui-line-height-2xl;
    margin: 0;
  }

  .row,
  .col {
    margin: 0;
  }

  .row {
    justify-content: space-between;

    .col {
      max-width: 45%;
      width: 100%;

      @include mobile {
        max-width: 100%;
      }

      p {
        width: 100%;
      }
    }
  }

  .col {
    max-width: 100%;
    width: 100%;
  }

  h3 {
    padding-top: 48px;
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

#BlogSingleArticleLayout {
  font-family: $cui-font-family-tt-norms;

  blockquote {
    padding-left: 15px;
    margin-left: 0;
    border-left: 3px solid $cui-black-color-200;
  }

  .singleArticleTitle {
    margin: 28px 0 60px;
    text-align: left;

    .overTitle {
      color: $cui-black-color-300;

      a {
        color: $cui-black-color-300;
      }
    }

    h1 {
      margin: 15px 0;
      font-family: $cui-font-family-rossanova;
    }

    .articleMeta {
      a {
        color: $cui-wine-color-500;
      }
    }
  }

  .coverImage {
    width: 100%;
    border-radius: 8px;
  }

  .container-blocks {
    max-width: 900px;
    margin: 0 auto;

    @include mobile {
      max-width: 90%;
    }
  }

  .relatedArticles {
    h4 {
      font-family: $cui-font-family-rossanova;
      font-size: 20px;
    }

    h3 {
      font-size: 16px;
    }

    img.coverImage {
      width: 100%;
      height: 180px;
      object-fit: cover;
      margin-bottom: 10px;
      border: solid 1px $cui-black-color-150;
    }

    @include mobile {
      .row {
        .col {
          width: 100%;
        }
      }
    }
  }

  .zipbar-cui {
    margin-top: 30px;
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

#MeetOurChefsVideo {
  padding-top: 0;
  background-color: $cui-black-color;

  .content {
    color: $cookunity-white;
    margin-left: 80px;

    @include mobile {
      margin-left: 0;
      margin-bottom: 40px;
      text-align: center;
    }

    h2.title {
      position: relative;
      margin: 64px 0 30px;
      font-weight: 300;
      font-size: 60px;
      display: block;
      width: 300px;
      line-height: 0.8em;

      &:before {
        content: ' ';
        position: absolute;
        border: 2px solid $cui-olive-green-color-500;
        height: 94px;
        left: -50px;
        top: -50px;
        background-color: $cui-olive-green-color-500;
      }

      &:after {
        content: '';
        position: absolute;
        border: 2px solid $cui-olive-green-color-500;
        width: 94px;
        left: -50px;
        top: -50px;
        background-color: $cui-olive-green-color-500;
      }

      @include mobile {
        width: 220px;
        font-size: 30px;
        margin: 44px auto;

        &:before {
          height: 45px;
          top: -25px;
          left: -25px;
        }
        &:after {
          width: 45px;
          top: -25px;
          left: -25px;
        }

        > br {
          display: none;
        }
      }
    }

    .text {
      margin-bottom: 70px;
      color: $cookunity-white;
      font-size: 16px;
      font-weight: 300;
      max-width: 340px;
    }
  }

  .video-player {
    width: 120%;
    position: relative;
    left: -10%;

    @include mobile {
      width: 100%;
      left: 0;
    }

    @media screen and (max-width: 1200px) {
      width: 100%;
      left: 0;
    }

    video {
      width: 100%;
    }
  }

  .button {
    color: rgb(45, 170, 150);
    font-size: 16px;
    font-weight: 300;
    font-family: $cui-font-family-tt-norms;
    border-width: 1px;
    border-style: solid;
    border-color: initial;
    border-image: initial;
    padding: 15px 40px;
    border-radius: 50px;
    text-decoration: none;
  }

  .ctaContainer {
    margin: 60px 0 40px;
    text-align: center;

    @include mobile {
      display: block;
    }
  }
}

#MeetOurChefsVideo.with-padding-top {
  padding-top: 92px;
}

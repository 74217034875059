@import '../../scss/mixins';
@import '../../scss/variables';

.loader-holder {
  background: $cookunity-white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 999;
  width: 100%;

  @include mobile {
    width: 100vw;
  }

  @include tablet {
    height: 80vh;
  }

  @include desktop {
    height: 100vh;
  }
}

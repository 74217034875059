@import '../../scss/mixins';
@import '../../scss/variables';

#title-value-cui {
  background-color: $cookunity-white;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 72px;

  @include mobile {
    margin-bottom: 0;
  }

  .container {
    position: absolute;
    width: 600px;
    text-align: center;
    padding: 0;

    @include mobile {
      width: 100%;
      padding: 0 20px 0 0;
    }

    .value-container {
      justify-content: space-between;

      .icon-value {
        margin-bottom: 16px;
      }

      h5 {
        margin: 0px;
        line-height: 1;
      }

      p {
        font-size: 14px;
        line-height: 18px;
        width: 137px;
        margin: 0 auto;

        @include mobile {
          width: unset;
        }
      }
    }

    h3 {
      margin: 0 0 2px;
    }

    h1 {
      margin: 0;
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.no-matching-meal {
  padding: 96px 0;
  margin: 0 auto;
  width: 1200px;
  min-height: 440px;

  @include mobile {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    width: 760px;
  }

  @media (min-width: 10024px) and (max-width: 1300px) {
    width: 1000px;
  }

  .img-no-match {
    margin: 0 auto 36px;
  }

  p.lead {
    font-family: $cui-font-family-tt-norms;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: $cui-black-color-600;
  }

  p {
    font-family: $cui-font-family-tt-norms;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $cui-black-color-400;
    margin: 0;
  }

  button {
    margin-top: 34px;
  }
}

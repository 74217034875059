@import '../../scss/mixins';
@import '../../scss/variables';

.dynamic-map-chef {
  padding: 48px 0 64px;

  @media (max-width: 920px) {
    padding: 48px 0 32px;
  }

  @include mobile {
    padding: 62px 0 20px;
  }

  @media (max-width: 400px) {
    padding: 62px 0;
  }

  p,
  h2 {
    color: $cookunity-white;
  }

  h2 {
    margin: 0;
  }

  h5 {
    color: $cui-black-color-300;
    font-weight: 400;
  }

  .tags {
    font-family: $cui-font-family-tt-norms;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
    display: flex;
    align-items: center;

    & p {
      text-decoration: underline;
    }

    span {
      font-family: $cui-font-family-tt-norms;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $cui-black-color-400;
      margin: 0 8px;
    }
  }

  .container {
    max-width: 1400px;
    .row {
      justify-content: space-around;
      margin: 0;

      @media (max-width: 1090px) {
        justify-content: center;
      }
    }
  }

  .dynamic-text-map {
    width: 518px;

    @include mobile {
      width: 100%;
    }

    .row-tags {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .row {
      @media (max-width: 1090px) {
        justify-content: flex-start;
      }
    }

    .chefDescription {
      p {
        color: $cui-black-color-200;
        margin-top: 8px;
      }

      .zip-btn {
        .arrow-icon {
          display: none;
        }

        span {
          font-family: $cui-font-family-tt-norms;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          text-decoration-line: underline;
          color: $cookunity-white;
        }
      }
    }

    .kitchen-description {
      margin: 13px 0;
      color: $cui-black-color-200;
    }
  }

  .stores-map {
    width: 530px;
    position: relative;
    display: flex;
    align-items: center;

    @media (max-width: 1090px) {
      height: 400px;
    }

    @include mobile {
      width: 100%;
    }

    img {
      position: absolute;
      width: 100%;
    }

    img.store-outlines {
      width: 85%;
      right: 44px;
      height: 73%;
    }
  }
}

.hero-cui .content-left .dynamic-map-meet-chefs {
  height: 584px;
  width: 100%;

  h3 {
    color: $cookunity-white;
    text-align: left;

    @include mobile {
      font-size: 34px;
      text-align: center;
    }
  }

  p {
    color: $cui-black-color-200;
  }

  @include mobile {
    height: 100%;
    padding: 60px 0 0;
  }

  .stores-map {
    position: relative;
    display: flex;
    align-items: center;
    height: 76%;
    width: 100%;

    @include mobile {
      height: 300px;
    }

    img {
      width: 100%;
    }
  }
}

.chefs-marquee {
  &-list {
    margin-bottom: 10px;
  }
  &-image-container {
    position: relative;
    aspect-ratio: 275/350;
    border-radius: 12px;
    margin: 0 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
      border-radius: 12px;
      height: 95px;
      width: 100%;
    }
  }
  &-item {
    position: relative;

    &.center {
      transform: scale(1.3);
    }

    p {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      z-index: 11;
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 700;
    }
  }
}

@import '../../../../scss/mixins';
@import '../../../../scss/_variables';

#small-hero {
  padding: 18px 0;
  .container-cui {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1162px;
    width: 100%;
    height: auto;
    border-radius: 12px;
    margin: 0 auto;
    background-image: var(--backgroundImg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media (max-width: 1162px) {
      max-width: 90%;
      flex-direction: column;
      padding: 0;
      text-align: center;
      background-image: var(--backgroundImgMobile);
    }

    .column {
      padding: 77px 0 77px 59px;
      @media (max-width: 1162px) {
        padding: 204px 20px 45px;
      }
      h1 {
        color: $cookunity-white;
        font-family: $cui-font-family-rossanova;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        margin: 0;

        @media (max-width: 1162px) {
          font-size: 32px;
          line-height: 38px;
          margin-bottom: 21px;
        }
        span {
          display: inline;
          color: $cui-yellow-color-500;
        }
      }

      p {
        color: $cui-black-color-300;
        margin: 4px 0 24px;

        @media (max-width: 1162px) {
          margin: 0 0 26px;
        }
      }

      form {
        margin: 20px 0 12px;
      }
    }
  }

  @media (max-width: 1162px) {
    .column {
      padding: 204px 20px 45px;
    }
  }
}

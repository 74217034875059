@import '../../../../../scss/mixins';
@import '../../../../../scss/variables';

.plan-value-price-container {
  border-radius: 8px;
  background-color: $cui-black-color-100;
  margin-top: 20px;
  width: 100%;

  .wine-tag {
    box-shadow: none;
    border-radius: 4px 4px 0 0;
  }

  .plan-value-content {
    display: flex;
    flex-direction: row;
    padding: 20px 0px;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    border-bottom: 1px solid $cui-black-color-150;

    > p {
      color: $cui-black-color;
      font-family: $cui-font-family-tt-norms;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      margin: 0;
      width: 90%;
    }

    .price {
      font-size: 14px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      > span {
        color: $cui-black-color-300;
        text-decoration: line-through;
        font-weight: 450;
        line-height: 20px;
        margin-right: 8px;
      }
    }
  }

  .plan-value-content:last-child {
    border-bottom: none;
  }

  .with-banner {
    display: flex;
    width: 300px;
    padding: 3px 8px 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 19px;
  }
}

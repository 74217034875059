@import '../../../scss/variables';

.countdown .content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  gap: 14px;

  .box {
    text-align: center;
  }

  .value {
    display: flex;
    gap: 4px;

    span {
      width: 24px;
      height: 32px;
      flex-shrink: 0;
      border-radius: 3px;
      background: $cui-olive-green-color-700;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $cookunity-white;
      text-align: center;
      font-family: $cui-font-family-tt-norms;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
  }

  .label {
    color: $cookunity-white;
    text-align: center;
    font-family: $cui-font-family-tt-norms;
    font-size: 10px;
    font-style: normal;
    font-weight: 450;
    line-height: 12px;
  }
}

.countdown .value span::before {
  content: '';
  position: absolute;
  width: 56px;
  height: 1px;
  z-index: 2;
  background-color: $cui-olive-green-color-500;
}

@import '../../scss/mixins';
@import '../../scss/variables';

.modal-left-image {
  .ReactModal__Content {
    width: 774px;
    height: 300px;
    border-radius: 12px !important;
    padding: 0 !important;
    border: none !important;
    display: flex;

    @include mobile {
      flex-direction: column-reverse;
      width: 90%;
      height: 459px !important;
    }

    .image-content {
      background-image: url('/assets/images/home-seo/craving-modal.jpg');
      background-repeat: no-repeat;
      width: 320px;
      height: 300px;
      border-radius: 12px 0px 0px 12px;

      @include mobile {
        width: 100%;
        height: 216px;
        border-radius: 0px 0px 12px 12px;
        background-size: cover;
        background-image: url('/assets/images/home-seo/craving-modal-m.jpg');
      }
    }

    .modal-content {
      padding: 20px 0px 20px 30px;

      @include mobile {
        padding: 20px 20px 28px;
      }

      h3,
      h4,
      p {
        font-family: $cui-font-family-tt-norms;
        font-style: normal;

        @include mobile {
          text-align: center;
        }
      }

      h4 {
        font-size: 14px;
        font-weight: 450;
        line-height: 20px;
        margin: 52px 0 0;

        @include mobile {
          margin: 36px 0 0;
        }
      }

      h3 {
        font-size: 28px;
        font-weight: 700;
        line-height: 36px;

        @include mobile {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 29px;
        }
      }

      p {
        color: $cui-black-color-300;
        font-size: 14px;
        font-weight: 450;
        line-height: 20px;
        margin: 12px 0 0;
      }
    }
  }
  .ReactModal__Content .topContainer {
    position: absolute;
    right: 0;
    margin: 20px;

    @include mobile {
      top: 0;
      width: 18px;
      height: 18px;
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

form.zip-form-cui.rebrand {
  .input-button {
    display: flex;
    padding: 0;
    max-width: 375px;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    height: 48px;

    @include mobile {
      max-width: 100%;
      margin: 0 auto;
      width: 100%;
    }
  }

  .input-email {
    max-width: 375px;
    margin-bottom: 16px;
    background-color: transparent;
    height: 48px;
    width: 100%;
    border: 1px solid $cui-black-color-200;

    @include mobile {
      max-width: 100%;
    }
  }

  .input-email.input-error {
    border: 1px solid $cookunity-red;
    color: $cookunity-red;
  }

  input::-webkit-input-placeholder {
    font-style: italic;
    color: $cui-black-color-300;
  }

  input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  .container-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0px 10px 18px;
    gap: 4px;
    border: 1px solid $cui-black-color-200;
    background: $cui-black-color-50;
    border-right: none;
    border-radius: 66px 0px 0px 66px;
    background-color: unset;
    height: 48px;
    width: 100%;
    min-width: 50%;

    img {
      width: 16px;
      height: 16px;
    }

    input {
      background: transparent;
      border: none;
      padding: 0;
      border-radius: 0;

      &.input-address::placeholder {
        font-style: italic;
        color: $cui-black-color-300 !important;
      }
    }
  }

  .container-input.input-error {
    border: 1px solid $cookunity-red;
    input {
      color: $cookunity-red !important;
    }
  }

  .zip-btn-cui {
    background: $cui-yellow-color-500;
    border-radius: 0px 66px 66px 0px;
    min-width: 186px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: $cui-black-color;
    padding: 14px;
    cursor: pointer;

    @include mobile {
      max-width: 100%;
      min-width: 40%;
    }

    .button-icon {
      margin: 0 0 0 12px;
    }
  }

  .disabled {
    background-color: $cui-black-color-300 !important;
  }

  .button-icon-reverse {
    flex-direction: row-reverse;
    justify-content: center;

    .button-icon {
      margin: 0 12px 0 0;
    }
  }

  &.add-meal {
    .zip-btn-cui {
      cursor: pointer;

      img {
        margin-right: 8px;
      }
    }

    input {
      color: $cookunity-gray-2;
    }

    input::-webkit-input-placeholder {
      color: $cookunity-gray-2;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
  }
}

.form.zip-form-cui.with-email {
  display: flex;
  flex-direction: column;
}

form.zip-form-cui.white-background {
  .container-input {
    background: $cookunity-white;

    input {
      color: $cui-black-color;
    }
  }

  .input-email {
    background: $cookunity-white;
    color: $cui-black-color;
  }

  input,
  input::-webkit-input-placeholder {
    color: $cui-black-color;
  }

  &.referral {
    input {
      color: $cui-black-color;
    }
  }
}

.ReactModal__Content.ReactModal__Content--after-open.modal-zip-cui {
  max-width: 600px;
  width: 100%;
  padding: 13px 20px 69px !important;
  margin-top: 32%;

  @include mobile {
    border-radius: 0 !important;
    border: none !important;
  }

  .meal-zip-cui {
    h2 {
      margin: 24px auto 40px;
      max-width: 430px;
      width: 100%;
      text-align: center;
    }
    p.rebrand {
      margin-bottom: 20px;
      text-align: center;
      padding: 0 20px;
    }

    form {
      display: flex;
      flex-direction: column;

      input {
        max-width: 348px;
        width: 100%;
        height: 48px;
        margin: 0 auto 20px;
        padding: 8px 16px;
      }

      input::-webkit-input-placeholder {
        color: #a8a8a8;
        font-style: italic;
      }
      button {
        margin: 0 auto;
        background: $cui-yellow-color-500;
        padding: 14px;
        max-width: 348px;
        width: 100%;
        height: 48px;

        p.rebrand.small {
          color: $cui-black-color;
          font-weight: 700;
          margin: 0;
        }
      }
    }

    p.rebrand.bottom {
      margin-top: 40px;
    }
  }
}

form.zip-form-cui.rebrand.inside-button-white {
  width: 100%;
  height: 56px;
  box-shadow: -107px 163px 55px 0px rgba(0, 0, 0, 0),
    -69px 104px 50px 0px rgba(0, 0, 0, 0.01),
    -39px 59px 42px 0px rgba(0, 0, 0, 0.05),
    -17px 26px 31px 0px rgba(0, 0, 0, 0.09),
    -4px 7px 17px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(18px);
  border-radius: 100px;

  @include mobile {
    width: 100%;
    position: relative;
    z-index: 0;
  }
  .input-button {
    padding: 4px;
    align-items: center;
    border-radius: 100px;
    border: 1px solid $cui-black-color-150;
    background: $cookunity-white;
    max-width: 100%;
    height: 100%;

    .container-input {
      border: none;
      height: 100%;
      gap: 8px;
      padding: 0px 12px;

      input {
        width: 100%;
        color: $cui-black-color-350;
        font-family: $cui-font-family-tt-norms;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.28px;
        height: 100%;
      }

      input::-webkit-input-placeholder {
        color: $cui-black-color-350;
      }
    }
    button {
      padding: 8px 20px;
      color: $cui-black-color-600;
      text-align: center;
      font-family: $cui-font-family-tt-norms;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -0.14px;
      gap: 4px;
      align-self: stretch;
      border-radius: 200px;
      border: 1px solid rgba(0, 0, 0, 0.08);
      background: $cui-yellow-color-500;
      min-width: fit-content;
      display: flex;
      align-items: center;

      &:hover {
        background-color: $cui-yellow-color-600;
      }
    }
  }
}

form.zip-form-cui.rebrand.inside-button-hero {
  width: 404px;
  @include mobile {
    position: absolute;
    z-index: 2;
    width: 100%;
  }
}

form.zip-form-cui.rebrand.inside-button {
  width: 404px;
  height: 40px;

  @include mobile {
    width: 100%;
  }
  .input-button {
    padding: 4px;
    align-items: center;
    border-radius: 100px;
    border: 1px solid $cui-black-color-200;
    background: $cui-black-color-150;
    max-width: 100%;
    height: 100%;

    @include mobile {
      background: transparent;
      border: none;
      gap: 12px;
    }
    .container-input {
      border: none;
      height: 100%;
      gap: 8px;
      padding: 0px 12px;

      @include mobile {
        color: rgba(0, 0, 0, 0.6);
        display: flex;
        padding: 4px 0px;
        align-items: center;
        gap: 4px;
        flex: 1 0 0;
        align-self: stretch;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0px;
      }

      input {
        width: 100%;
        color: $cui-black-color-350;
        font-family: $cui-font-family-tt-norms;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.28px;
        height: 100%;
      }

      input::-webkit-input-placeholder {
        color: $cui-black-color-350;
      }
    }
    button {
      padding: 8px 12px;
      color: $cui-black-color-800;
      text-align: center;
      font-family: $cui-font-family-tt-norms;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: -0.12px;
      gap: 4px;
      align-self: stretch;
      border-radius: 200px;
      border: 1px solid rgba(0, 0, 0, 0.08);
      background: $cui-yellow-color-500;
      min-width: fit-content;
      display: flex;
      align-items: center;
    }
  }
}

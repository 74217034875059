@import '../../scss/mixins';
@import '../../scss/variables';

.ReactModal__Overlay {
  overflow: auto;
}

.ReactModal__Content.mealDetail-modal {
  top: 80px;
  position: absolute;
  overflow: hidden auto;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 35px 55px 82px 35px !important;
  max-width: 1040px;
  width: 95%;
  border-radius: none;

  p,
  h1,
  h2 {
    color: $cui-black-color;
  }

  @include mobile {
    padding: 20px !important;
    max-height: none;
    margin-top: 32px;
    transform: none;
    top: 0;
    overflow-y: initial;
    border-radius: 10px;
    height: auto !important;
    margin-bottom: 30px;
    position: relative;
  }

  .view-meal-button {
    width: 250px;
    align-self: flex-end;
    justify-self: center;

    @media (max-width: 930px) {
      width: 100%;
      grid-row-start: 4;

      > button {
        width: 100%;
      }
    }
  }

  .close_container {
    position: absolute;
    right: 25px;

    @include mobile {
      right: 20px;
    }

    .close {
      cursor: pointer;
    }
  }

  .product-modal {
    display: grid;
    grid-template-columns: 0.4fr 0.6fr;
    gap: 40px;

    @media (max-width: 930px) {
      grid-template-columns: 1fr;
      gap: 20px;
    }

    p {
      margin: 0;
    }

    h1 {
      font-size: 28px;
      line-height: 36px;
      margin: 0 0 16px;

      @include mobile {
        margin: 18px 0 0;
      }
    }

    .product-image {
      align-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 378px;
      position: relative;
      margin: auto;

      p.txt-noimage {
        position: absolute;
        top: 45%;
        width: 100%;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
      }

      .meal-img {
        border-radius: 10px;
        position: relative;
        @media (max-width: 550px) {
          max-height: 200px;
        }
      }
    }

    .meal-desktop {
      align-items: flex-start;
      justify-content: space-between;
      display: flex;
    }

    .product-info {
      .product-chef {
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .title-ingredients {
        color: $cookunity-gray-1;
        margin: 18px 0 4px;
        font-size: 16px;
        line-height: 24px;
      }

      .cookunity__specification_details {
        position: relative;

        > .tags-mobile-slider-cui {
          position: absolute;
          top: 0;
          max-width: 100%;
        }
        &--placeholder {
          height: 36px;
          margin: 16px 0;
        }

        .slick-slider {
          width: 100%;
        }

        .slick-track {
          margin: 0;
        }

        .specification_detail {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100px;

          .img-container {
            width: 45px;
            height: 45px;
            background: $cookunity-white;
            border: 1px solid $cui-black-color-200;
            box-sizing: border-box;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto 10px;

            > img {
              max-width: 28px;
              max-height: 28px;
              width: 100%;
              height: 100%;
            }
          }

          > p {
            color: $cui-black-color;
            margin: 0 auto;
            text-align: center;
          }
        }
      }

      .chef-details {
        width: 144px;

        @include mobile {
          margin-top: 20px;
        }

        .meal-review {
          display: flex;

          p {
            margin: 0px 104px 0 0;
          }

          .star-model {
            display: flex !important;
            flex-direction: row-reverse;
          }
        }

        p {
          margin: 0;
        }
      }

      .row-details {
        display: flex;
        align-items: center;

        .chef-img {
          height: 58px;
          width: 58px;
          border: 1px solid $cui-black-color-200;
          border-radius: 100px;
          margin-right: 20px;
          object-fit: cover;
        }
      }
    }

    .row-between {
      display: flex;
      flex-direction: column;

      > p {
        font-size: 14px !important;
      }

      > h2 {
        margin: 0 0 18px;

        @include mobile {
          margin: 40px auto 16px;
          font-size: 20px;
          line-height: 32px;
        }
      }
      > form {
        .input-button {
          @media (max-width: 930px) {
            max-width: 100%;
          }
        }
        input {
          color: $cookunity-gray-2;
        }

        input::-webkit-input-placeholder {
          color: $cookunity-gray-2;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:focus {
          transition: background-color 600000s 0s, color 600000s 0s;
        }
      }
    }
  }
}

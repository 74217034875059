@import '../../../scss/mixins';
@import '../../../scss/variables';

.gift-card-buying-section-wrapper {
  background-color: $cui-black-color-100;
  width: 100%;

  .gift-card-buying-section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &--title {
      padding: 0px;
    }

    h2 {
      margin-top: 50px;
    }

    .subtitle {
      max-width: 480px;
      margin: 0 0 50px;
      text-align: center;
    }

    .gift-card-buying-steps-container {
      width: 100%;
      display: flex;
    }
  }
}

@media (max-width: 1024px) {
  .gift-card-buying-section-wrapper
    .gift-card-buying-section-container
    .gift-card-buying-steps-container {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 425px) {
  .gift-card-buying-section-wrapper
    .gift-card-buying-section-container
    .subtitle {
    max-width: 290px;
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.star-model {

  .dv-star-rating-star {
    cursor: inherit !important;
  }

  @include mobile  {
    width: 16px;
    height: 16px;
  }

  width: 16px;
  height: 16px;
  margin: 0px 6px 0px 0px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  cursor: inherit !important;

  .star {
    background-image: url('/assets/images/stars/star.png');
  }

  .half-star {
    background-image: url('/assets/images/stars/half-star.png');
  }

  .empty-star {
    background-image: url('/assets/images/stars/empty-star.png');
  }
}
  

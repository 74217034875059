@import '../../scss/mixins';
@import '../../scss/variables';

.footer-copyright {
  padding: 48px 12px 42px;
  background-color: $cookunity-black;
  position: relative;
  color: $cookunity-white;
  display: block;

  @media screen and (max-width: 768px) {
    padding: 25px 0 74px;
  }

  .mobile-p {
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  .row-footer-copyright {
    max-width: 1230px;
    margin: 0 auto;
    padding: 0;
    flex-direction: row;

    .flex-start-footer-copyright {
      width: 80%;
      float: left;
      margin-bottom: 0;

      @media (max-width: 768px) {
        width: 100%;
        float: none;
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
      }

      .footer-cu-footer-copyright {
        font-family: $cui-font-family-tt-norms;
        display: inline;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 24px;
        color: $cookunity-white;
        margin: 0;
      }
    }
  }
}

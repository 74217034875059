@import '../../scss/mixins';
@import '../../scss/variables';

#deliveryMap {
  #map {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .container-delivery {
    display: flex;
    flex-direction: column;
  }

  .input-email-container {
    top: 196px;
    background: $cookunity-black;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 74px;
    box-sizing: border-box;
    padding-bottom: 60px;
  }

  .input-mail-b {
    width: 380px;
    height: 48px;
    margin-right: 20px;
  }

  .button-mail-b {
    width: 140px;
    height: 48px;
    border: 2px solid $cookunity-white;
    padding: 14px;
  }

  h4 {
    font-size: 36px;
    line-height: 42px;
    letter-spacing: -0.5px;
    color: $cookunity-white;
    margin: 0 0 10px;
  }

  .input-email-container,
  .info-container {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .black-info {
    h4,
    .p-delivery-map {
      color: $cui-black-color;
    }
  }

  .info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0;
    background: $cui-wine-color-500;
    text-align: center;
    padding: 0 20px;

    button {
      width: 190px;
      margin: 32px auto;
      height: 48px;
    }
  }

  .fadeInUp {
    animation-name: fadeInUp;
    padding-top: 74px;
    padding-bottom: 60px;
  }

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(200px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .search {
    display: flex;
    justify-content: center;
  }

  .input-container {
    width: 350px;
    height: 48px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    background: $cookunity-white;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 0 64px rgba(0, 0, 0, 0.08), 0 16px 32px rgba(0, 0, 0, 0.06),
      0 8px 16px rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.02);
    border-radius: 200px;
    z-index: 2;
    margin-top: -24px;
    padding: 0 8px;
    position: absolute;
    box-sizing: border-box;
  }

  .input-error {
    border: 1px solid $cookunity-red;
  }

  .text-container {
    margin: 0 auto;
    text-align: center;
  }

  .email-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }

  input {
    align-items: center;
    border: none;
    color: rgba(0, 0, 0, 0.32);
    padding: 14px;
    box-sizing: border-box;
    border-radius: 200px;
  }

  .delivery-title {
    width: 680px;
    margin: 0px auto 84px;
    box-sizing: border-box;

    h1 {
      margin-bottom: 30px;
      letter-spacing: -2px;
      color: $cookunity-black;
      padding-top: 90px;
      margin-top: 0;

      @include mobile {
        margin-bottom: 50px;
        padding-top: 0;
      }
    }
  }
}
@media screen and (max-width: 780px) {
  #deliveryMap {
    .delivery-title {
      width: 100%;
      padding: 90px 20px 0;

      .p-delivery-map {
        line-height: 28px;
      }
    }

    .input-email-container {
      padding: 74px 20px 60px;
    }

    h4 {
      font-size: 36px;
      line-height: 42px;
    }

    .email-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
    }

    .input-mail-b {
      margin-right: 0;
      width: 95%;
      margin-bottom: 29px;
    }

    .button-mail-b {
      width: 100%;
      margin-top: 20px;
      height: 48px;
      box-sizing: border-box;
      border-radius: 24px;
    }

    .input-email-container {
      padding: 74px 20px 85px;
    }

    .info-container {
      padding: 74px 28px 60px;
      p {
        text-align: center;
      }
    }

    .input-container {
      width: 85%;
      padding: 0 8px;
    }

    .text-container {
      width: 95%;
      margin-right: 0;
    }
  }
}

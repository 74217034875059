@import '../../scss/variables';

.collections-carrousel-container {
  .slick-list {
    overflow: visible;
    // gives room to the hover effect
    margin: -3px;
    padding: 3px;
  }

  .slick-track {
    display: flex;

    @media (min-width: 1440px) {
      justify-content: center;
    }
  }

  .collection-card {
    position: relative;
    margin-right: 12px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    width: 300px;
    max-width: 300px;
    height: 300px;
    border-radius: 12px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
      border-radius: 12px;
      height: 95px;
      width: 100%;
    }

    &:hover {
      transform: translateY(-2px);
    }

    h3 {
      font-size: $cui-font-xl;
      color: $cookunity-white;

      position: absolute;
      top: 230px;
      left: 16px;
      max-width: 50%;
    }

    @media (max-width: 720px) {
      width: 224px;

      h3 {
        max-width: 70%;
      }
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

#faces-wall-cui {
  margin: 0 auto;
  text-align: center;
  padding-bottom: 166px;

  @include mobile {
    padding-bottom: 51px;
  }

  .container-cui-row {
    gap: 41px;
    max-width: 1018px;
    width: 100%;
    margin: 0 auto;
    justify-content: flex-start;
    padding: 0 20px;

    @media (max-width: 1200px) {
      gap: 40px;
      max-width: 100%;
      flex-direction: row;
      justify-content: center;
    }
  }

  .col-20 {
    width: 85px;

    .chef-item {
      background-size: cover;
    }
  }

  p.lead {
    margin: 0 auto 117px;
    max-width: 525px;

    @include mobile {
      margin: 0 auto 61px;
    }
  }

  .skeleton-circle {
    background: url('/assets/images/skeleton.gif') no-repeat center center fixed;
    background-size: cover;
    height: 95px;
    width: 95px;
    border-radius: 200px;
    margin-right: 50px;

    @include mobile {
      margin-right: 14px;
      margin-bottom: 100px;
    }
  }

  .chef-item {
    position: relative;
    height: 85px;
    width: 85px;
    border-radius: 200px;
  }

  .avallon {
    font-size: 25px;
    line-height: 24px;
    margin-top: 10px;
    color: $cui-black-color;
  }

  .cui-link {
    margin-top: 74px;
  }
}

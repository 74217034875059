@import '../../../../scss/mixins';
@import '../../../../scss/variables';

#our-story {
  padding: 127px 0 146px;

  @include mobile {
    padding: 53px 0 29px;
  }
  .columns {
    justify-content: space-around;
    width: 100%;

    @media (max-width: 920px) {
      .only-desktop {
        display: none;
      }
      .only-mobile {
        display: block;
        width: 100%;
      }
    }

    .column {
      max-width: 482px;
      width: 100%;
      @media screen and (max-width: 1024px) {
        max-width: 40%;
      }

      @media screen and (max-width: 920px) {
        max-width: 60%;
      }

      @include mobile {
        max-width: 90%;
      }
    }

    .column1 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include mobile {
        margin: 0 auto;
      }

      h2 {
        margin-bottom: 10px;

        @include mobile {
          margin-bottom: 0;
        }
      }

      p {
        color: $cui-black-color-400;
        margin: 34px 0 0;

        @media (max-width: 920px) {
          max-width: 100%;
        }
      }

      img {
        margin-top: 86px;
      }
    }

    .column2 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media screen and (max-width: 920px) {
        display: none;
      }
    }
  }
}

@import '../../scss/variables';

.productPage > div:first-child {
  min-height: 138px !important;
}

@media (max-width: 768px) {
  .productPage > div:first-child {
    min-height: 0 !important;
  }
}

.meals-section-container {
  .meals-slider-cui {
    max-width: 1144px;

    .slick-arrow {
      background-color: $cookunity-white;
      border: 1px solid $cui-black-color-150;
      opacity: 1;

      &.slick-disabled {
        display: none;
      }
    }
    .mealcard-new {
      height: 395px;
    }
  }

  padding: 60px 0 0;

  h2 {
    text-align: center;
    font-family: $cui-font-family-rossanova;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin: 0 0 33px;
  }
}

.dark-section-container {
  border-radius: 20px;
  background: $cui-black-color-700;

  h2 {
    color: $cookunity-white;
  }

  .meals-slider-cui.meals-slider-cui__black-mode {
    max-width: 1160px;

    .slick-arrow {
      border: none;
      opacity: 0.75;
    }
  }
}

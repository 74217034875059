@import '../../../../../scss/variables';

.gift-card-buying-step-layout-container {
  width: 100%;
  max-width: 33%;

  h6 {
    font-family: $cui-font-family-tt-norms;
    margin: 10px 0 27px;
    font-size: 20px;
    line-height: 24px;
    color: $cui-black-color;
  }

  @media (max-width: 1024px) {
    & {
      margin: 0 auto 24px;
      max-width: 414px;
    }
  }

  @media (min-width: 1025px) {
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.dark-hero-new.rebrand {
  padding: 180px 0 74px;
  overflow-x: clip;
  position: relative;

  @include mobile {
    padding: 48px 0;
  }

  .container {
    display: flex;
    position: relative;
    z-index: 0;

    @include mobile {
      flex-direction: column;
      padding: 0;
    }
  }

  .column-left {
    width: 100%;
    max-width: 607px;
    text-align: left;

    @include mobile {
      width: 100%;
      padding: 0;
      margin: 52px auto 0;
    }

    form {
      padding: 0;
      max-width: 375px;
      width: 100%;

      @include mobile {
        max-width: 100%;
        margin: 0 auto;
        width: 100%;
        padding: 0 20px;
      }

      @media (max-width: 375px) {
        padding: 0 10px;
      }

      .container-input input {
        color: $cookunity-white;

        &::-webkit-input-placeholder {
          color: $cookunity-white;
        }
      }
    }
  }

  .img-column {
    margin-top: 0;
    margin-right: -88px;
    width: 100%;
    position: relative;

    @include mobile {
      overflow-x: clip;
      height: 360px;
      margin-right: 0;
      margin: 0 auto;
    }

    @media (max-width: 375px) {
      overflow: hidden;
      height: 360px;
    }

    @media (min-width: 768px) and (max-width: 1200px) {
      margin-right: 0;
    }

    img {
      position: absolute;
      right: 0px;
      top: -115px;
      max-width: 100%;

      @media (min-width: 768px) and (max-width: 1200px) {
        z-index: 0;
        top: -50px;
      }

      @media (min-width: 768px) and (max-width: 920px) {
        display: none;
      }

      @include mobile {
        top: 0;
        margin: 0 auto;
        position: relative;
        max-width: 375px;
      }
    }
  }

  .hero-title,
  p.hero-title,
  h1.rebrand {
    font-family: $cui-font-family-rossanova;
    font-size: 64px;
    line-height: 90px;
    color: $cookunity-white;
    font-style: normal;
    font-weight: 800;
    letter-spacing: -0.02em;
    width: 607px;
    margin: 0 0 76px;

    @include mobile {
      font-size: $cui-font-4xl;
      line-height: 44px;
      width: 100%;
      text-align: center;
      margin: 0 auto 30px;
      max-width: 440px;
    }

    span {
      font-family: $cui-font-family-avallon;
      color: $cui-yellow-color-500;
      display: inline;
      font-size: 90px;
      line-height: 100%;
      letter-spacing: -0.02em;

      @include mobile {
        font-size: $cui-line-height-6xl;
      }
    }
  }

  h4.rebrand {
    font-family: $cui-font-family-tt-norms;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: $cui-yellow-color-500;
    margin: 16px 0 0;

    @include mobile {
      width: 100%;
      text-align: center;
      font-size: 14px;
      line-height: 170%;
      padding: 0 20px;
    }
  }

  .image-background {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;

    img {
      width: 100%;
    }

    @include mobile {
      &:before {
        content: '';
        background: linear-gradient(
          0deg,
          rgb(0, 0, 0) 0%,
          rgba(0, 0, 0, 0) 30%,
          rgba(0, 0, 0, 0) 70%,
          rgb(0, 0, 0) 100%
        );
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
      }
    }
  }

  &.home-seo-back {
    padding: 0;
    background-color: $cui-black-color-700;

    @include mobile {
      padding: 0;
    }

    .container {
      background-image: url('/assets/images/home-seo/hero_desktop.webp');
      background-size: cover;
      background-repeat: no-repeat;
      padding: 112px 20px 125px;
      height: 674px;

      @include mobile {
        background-image: url('/assets/images/home-seo/hero_mobile.webp');
        padding: 0;
        height: 761px;
        background-position: center;
      }
    }
  }
}

.dark-hero {
  background-image: url('/assets/images/dark-home/hero-bg.jpg');
  background-size: cover;
  background-position: center;
  padding: 180px 0 174px;
  overflow-x: clip;
  z-index: 1;
  position: relative;

  @media (min-width: 768px) and (max-width: 1050px) {
    padding: 96px 0;
  }

  .container {
    display: flex;

    @include mobile {
      flex-direction: column;
      padding: 0;
    }
  }

  @include mobile {
    background-image: url('/assets/images/dark-home/back-mobile.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 660px;
    padding: 96px 0 0;
  }

  .column-left {
    width: 100%;
    max-width: 607px;
    text-align: left;

    @include mobile {
      width: 100%;
      padding: 0;
      margin: 0 auto;
    }
  }

  .img-column {
    margin-top: -30px;
    margin-right: -100px;
    width: 100%;
    position: relative;

    @include mobile {
      overflow-x: hidden;
      height: 600px;
    }

    @media (min-width: 500px) and (max-width: 1050px) {
      overflow: visible;
      height: unset;
    }

    img {
      position: absolute;
      right: -88px;
      top: -70px;
      max-width: 120%;

      @include mobile {
        top: 0;
        margin: 0 auto;
        right: -10%;
      }

      @media (min-width: 500px) and (max-width: 1050px) {
        max-width: 100%;
        right: 0%;
        top: 0;
      }
    }

    @include mobile {
      margin: 0 auto;
    }
  }

  .content {
    display: flex;
    max-width: 100%;

    @include mobile {
      max-width: 100%;
      flex-direction: column;
    }
  }

  h1 {
    font-family: $cui-font-family-tt-norms;
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 97.5%;
    letter-spacing: -0.02em;
    color: $cookunity-white;
    width: 607px;
    margin: 0;

    @media (min-width: 768px) and (max-width: 1050px) {
      width: 100%;
    }

    @include mobile {
      font-size: 32px;
      line-height: 110%;
      width: 100%;
      text-align: center;
    }

    span {
      display: block;
      font-family: $cui-font-family-reinata;
      font-style: normal;
      font-weight: 400;
      font-size: 120px;
      line-height: 97.5%;
      letter-spacing: -0.02em;
      color: $cookunity-white;
      transform: rotate(-4.91deg);
      margin: -34px 0 36px 0;

      @include mobile {
        font-size: 60px;
        line-height: 110.5%;
        margin-top: -18px;
      }
    }
  }

  h4 {
    font-family: $cui-font-family-tt-norms;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: $cui-olive-green-color-500;
    width: 447px;

    @include mobile {
      width: 100%;
      text-align: center;
      font-size: 14px;
      line-height: 170%;
    }
  }
}

@import '../../../../scss/mixins';
@import '../../../../scss/variables';

#chefs-color-carrousel {
  background-color: $cui-black-color-600;
  padding: 58px 0;

  ul {
    padding-top: 13px;
  }

  li {
    width: 72px !important;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: relative;
    cursor: pointer;

    p.icon {
      width: 31px;
      height: 31px;
      border-radius: 100%;
      background-color: $cookunity-white;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      margin: 0;
      top: -13px;
      right: 0;
    }

    p {
      color: $cookunity-white;
      font-family: $cui-font-family-tt-norms;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      width: 80%;
      margin: 8px auto 0;
      max-width: 697px;
    }
  }
  .slick-slide {
    margin: 13px 18px 0;
  }

  &.is-light-mode {
    background-color: transparent;
    h2,
    li p {
      color: $cui-black-color;
    }
  }
}

@import '../../scss/variables';

#BlogSectionTitle {
  height: 400px;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: $cui-black-color-400;
  color: $cookunity-white;
  background-image: url('/assets/images/topic_title_bg.jpg');
  background-size: cover;
  background-position: center;

  @media (max-width: 767px) {
    background-image: url('/assets/images/topic_title_bg_mobile.jpg');
  }

  h1 {
    font-size: 54px;
    font-family: $cui-font-family-rossanova;

    @media (max-width: 767px) {
      font-size: 32px;
    }
  }

  .titleImage {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin: 0 auto;
  }
}

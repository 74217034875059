@import '../../../../scss/mixins';
@import '../../../../scss/variables';

.cms-quiz-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $cui-black-color-100;

  > .content-wrapper {
    display: flex;
    flex-direction: column;
    margin: 120px auto;
    border-radius: 8px;
    background-color: $cookunity-white;
    padding: 50px 10vw;
    max-width: 1200px;

    @media (min-width: 769px) {
      width: 100%;
    }

    @media (max-width: 1000px) {
      padding: 50px 5vw;
    }

    @media (max-width: 800px) {
      margin: 30px 20px;
      padding: 30px 25px;
    }

    > h2 {
      font-weight: 700;
      font-size: 28px;
      text-align: center;
      padding-bottom: 50px;
      font-family: $cui-font-family-tt-norms;

      @include mobile {
        padding: 20px 0;
        margin: 0;
      }
    }

    > div {
      display: flex;

      @include mobile {
        flex-direction: column;
      }

      > div {
        width: 50%;

        @include mobile {
          width: 100%;
        }

        &:first-child {
          display: flex;
          flex-direction: column;
          border-right: 1px solid $cui-black-color-150;
          padding-right: 5%;
          gap: 40px;

          @include mobile {
            border-right: none;
            border-bottom: 1px solid $cui-black-color-150;
            padding: 30px 0;
          }
        }

        &:last-child {
          display: flex;
          flex-direction: column;
          padding-left: 5%;
          align-items: center;

          @include mobile {
            padding-left: 0;
            padding-top: 30px;
          }

          > .summary {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            height: 100%;

            @include mobile {
              margin-top: 60px;
            }

            > span {
              font-size: 12px;
              &:first-child {
                color: $cui-black-color-300;
                font-weight: 450;
              }
              &:nth-child(2) {
                color: $cui-black-color;
                font-weight: 700;
              }
            }

            > form {
              margin-top: 20px;
            }
          }
        }
      }
    }

    > form {
      display: flex;
      justify-content: center;
      margin-top: 47px;
    }
  }
}

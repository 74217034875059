@import '../../../scss/variables.scss';

.cui-meal-card {
  box-shadow: 0px 0px 12px rgba(35, 31, 32, 0.1),
    0px 12px 12px rgba(35, 31, 32, 0.07);
  border-radius: 8px;
  position: relative;
  z-index: $z-0;
  cursor: pointer;

  &-row {
    flex-direction: row;
    .cui-meal-image {
      .cui-image {
        width: 158px;
        height: 100%;
      }
    }
  }

  .cui-item {
    gap: $cui-gutter;
    margin-bottom: $cui-gutter-small;

    p {
      text-decoration: underline;
      font-weight: $cui-font-bold;
      font-size: $cui-font-base;
    }
  }

  & &-tags {
    display: flex;
    flex-wrap: wrap;
    gap: $cui-gutter-small;
    margin-bottom: $cui-gutter-medium;

    .cui-tag {
      font-family: $cui-font-family-tt-norms;
      font-size: $cui-font-xs;
      font-weight: $cui-font-bold;
      line-height: $cui-line-height-xs;

      .cui-icon {
        height: 20px;
        width: 20px;
      }
    }

    .reviews {
      color: $cui-black-color-300;
    }
    .calories {
      color: $cui-black-color-400;
    }
  }

  &-selected {
    outline: 2px solid $cui-wine-color-500;
  }
}

@import '../../../../../scss/variables';

.gift-card-third-step-container {
  .zip-btn .arrow-icon {
    top: 0;
  }

  button {
    width: 100%;
    justify-content: center;
    margin-top: 16px;

    span {
      color: $cookunity-black !important;
    }

    .btn-p {
      flex: unset;
    }
  }

  .confirmation-error-message {
    height: 14px;
    margin-top: 8px;
    font-family: $cui-font-family-tt-norms;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: $cookunity-red;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 20px;
    text-align: center;

    li {
      font-size: 16px;
      line-height: 24px;
      font-family: $cui-font-family-tt-norms;

      p {
        margin: 0;
      }

      a {
        font-weight: 700;
        text-decoration: none;
      }
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.ReactModal__Content {
  margin: 0 auto;
  width: 430px;
  height: fit-content;
  background: $cookunity-white;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  box-sizing: border-box;
  top: 50% !important;
  transform: translate(0, -50%);

  @include mobile {
    position: relative !important;
    padding: 20px !important;
    width: 100%;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    height: 100%;
    box-shadow: none;
    border-radius: 0;
    overflow: hidden !important;
    transform: translate(0) !important;
  }

  .topContainer {
    text-align: right;

    img.close {
      cursor: pointer;
      display: inline-block;
      width: 18px;
    }
  }
}

.tags-overlay {
  .ReactModal__Content {
    @include mobile {
      width: 90%;
      top: 20% !important;
      left: 0;
      right: 0;
      height: 200px;
    }
  }
}

@import '../../scss/variables';

.footer {
  background-color: $cui-black-color-700;
  padding: 56px 0px 32px;

  @media (max-width: 1300px) {
    padding: 56px 20px 32px;
  }

  &__container {
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
    text-align: left;
  }

  &__top {
    display: flex;
    gap: 32px;

    @media (max-width: 900px) {
      flex-direction: column;
      gap: 0;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    margin: 64px auto 0px;
    width: 100%;

    @media (max-width: 900px) {
      flex-direction: column;
    }

    li,
    p {
      color: $cui-black-color-350 !important;
    }

    ul {
      flex-direction: row;
      max-width: 70%;
      justify-content: flex-start;
      align-items: center;
      gap: 40px;

      @media (max-width: 900px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        margin-bottom: 40px;
      }
    }
  }

  li,
  p {
    color: $cui-gray-color-border;
    font-family: $cui-font-family-tt-norms;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.14px;
  }

  &__lists {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 900px) {
      margin: 64px 0 40px;
    }
  }

  &__headline {
    max-width: 296px;
    width: 100%;

    @media (max-width: 1200px) {
      max-width: 20%;
    }

    @media (max-width: 767px) {
      max-width: 100%;

      a.a-brand img.brand-logo {
        width: 150px;
        height: 32px;
      }
    }
  }

  &__nav {
    max-width: 296px;
    width: 100%;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__legals {
    max-width: 296px;
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    padding: 6px 0;

    @media (max-width: 1200px) {
      max-width: 30%;
    }

    @include mobile {
      max-width: 100%;
    }

    .stores-badges {
      display: flex;
      gap: 20px;

      a span {
        width: 109px;
      }
    }

    .social-icons-nav {
      @media (max-width: 900px) {
        margin-top: 48px;
      }
      ul {
        flex-direction: row;

        .social-button {
          border-radius: 24.615px;
          border: 1px solid $cui-black-color-100;
          padding: 8px;

          > a {
            > span {
              > svg {
                fill: $cui-black-color-100;
                max-width: 20px;
              }
            }
          }
        }
      }
    }
  }
}

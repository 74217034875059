@import '../../scss/mixins';
@import '../../scss/variables';

.sticky {
  z-index: 2;

  .zipbar-cui {
    background-color: $cookunity-white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding: 15px;
    justify-content: center;

    h2 {
      font-size: 20px;
      line-height: 28px;
      color: $cookunity-black;
      margin: 0;
      width: 100%;
    }

    p.rebrand.bold {
      display: block;

      @include mobile {
        margin-bottom: 8px;
      }
    }

    .promo-text {
      width: 100%;
    }

    .container-cui {
      align-items: center;
      max-width: 1300px;
    }

    form.rebrand.zip-form-cui {
      margin-top: 0;
      display: flex;
      flex-direction: row;
      width: 70%;

      @media (min-width: 768px) and (max-width: 1024px) {
        width: 56%;
      }

      @include mobile {
        width: 100%;
      }

      .container-input {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0px;
        width: 100%;

        @include mobile {
          margin-bottom: 15px;
        }

        input::-webkit-input-placeholder {
          color: $cui-black-color;
        }

        .input-zip {
          color: $cui-black-color;

          @include mobile {
            margin-right: 0;
            width: 100%;
          }
        }
      }

      .input-email {
        margin: 0 17px 0 0;
        max-width: 300px;

        @include mobile {
          margin: 0 0 17px 0;
          max-width: 100%;
        }

        @media (min-width: 768px) and (max-width: 1024px) {
          margin: 0 0 17px 0;
          max-width: 375px;
        }
      }

      @media (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-end;
      }

      &.width {
        width: 100%;
      }
    }

    &.white-zip-bar {
      .container-cui {
        h2 {
          font-size: 20px;

          &::first-letter {
            text-transform: uppercase;
          }
          text-transform: lowercase;
        }
      }
    }
  }

  .autocomplete-dropdown-container {
    position: absolute;
    width: 100%;
    z-index: 1;
    bottom: 64px;
  }
}

.zipbar-cui {
  padding: 70px 20px;
  background-color: $cui-wine-color-500;
  position: relative;
  width: 100%;
  bottom: 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: flex;

  @include mobile {
    padding: 20px 20px;
  }

  @media (max-width: 375px) {
    padding: 20px 10px;
  }

  h2 {
    font-size: 36px;
    line-height: 44px;
    color: $cookunity-white;
    margin: 0;
    width: 60%;

    .second-line {
      color: $cookunity-black;
      display: block;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      margin-right: 12px;
    }

    @include mobile {
      padding: 0px;
      width: 100%;
      font-size: 30px;
      line-height: 1.3em;
      margin-right: 0px;
      margin-bottom: 19px;
      text-align: center;
    }

    @media (min-width: 768px) and (max-width: 920px) {
      margin-right: 20px;
    }
  }

  p.rebrand.bold {
    display: none;
  }

  .container-cui {
    justify-content: space-between;
    align-items: center;
    display: flex;
    max-width: 1300px;

    @include mobile {
      flex-direction: column;
      padding: 0;
    }
  }

  form {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include mobile {
      width: 100%;
    }

    .container-input {
      .input-zip {
        margin-right: 0px;
        width: 100%;
      }
    }
  }

  &.white-zip-bar {
    background-color: $cookunity-white;
    margin-top: 92px;
    border-top: 1px solid $cui-black-color-150;
    box-shadow: none;
    .container-cui {
      h2 {
        font-family: $cui-font-family-tt-norms;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        color: $cui-black-color;
        display: inline;
        width: 50%;

        @include mobile {
          width: 100%;
        }

        &::first-letter {
          text-transform: uppercase;
        }
        text-transform: lowercase;
      }
    }
  }
}

.sticky .white-zip-bar.zipbar-cui form.rebrand.zip-form-cui.width {
  width: 44%;

  @include mobile {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    width: 50%;
  }
}

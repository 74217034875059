@import '../../scss/variables';

.header-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $cui-black-color-shadow;

  .navbar-sticky-menu {
    border-radius: 200px;
    border: 1px solid $cui-gray-color-border;
    background: $cui-black-color-50;
    width: 40px;
    height: 40px;
    padding: 8px;
    display: flex;
    align-items: center;
  }

  .header-full {
    width: 100%;
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    width: 100%;
    position: relative;
    padding: 0px 74px;
    max-width: 1441px;
    overflow: hidden;
    margin: 0 auto;

    @media (max-width: 1330px) {
      justify-content: space-between;
      padding: 14px 16px;
      max-width: 100%;
    }

    img {
      cursor: pointer;
    }

    .navbar-container {
      flex: 1;
      justify-content: flex-start;
      display: flex;
      margin-left: 48px;

      @media (max-width: 1280px) {
        margin-left: 32px;
      }
      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        list-style: none;
        margin: 0;
        padding: 0;
        gap: 40px;
      }
      li {
        font-size: $cui-font-sm;
        font-weight: 450;
        color: $cui-black-color-400;
        cursor: pointer;
        p {
          display: none;
        }
      }

      @media (max-width: 1200px) {
        display: none;
      }
    }

    .buttons-end {
      display: flex;
      align-items: center;
      gap: 12px;

      .navbar-sticky-menu {
        display: none;
        @media (max-width: 1200px) {
          display: inline;
          border: none;
        }
      }

      .started-button {
        button.cui-button-lp {
          height: 40px;
        }
      }
      .login-link-cui.homepage {
        .cui-link {
          color: $cui-black-color-850;
          text-align: center;
          font-family: $cui-font-family-tt-norms;
          font-size: $cui-font-sm;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
          letter-spacing: -0.14px;
          padding: 12px 20px;
          height: 100%;
          border: 1px solid $cookunity-white;
          max-height: 40px;

          &:hover {
            background-color: $cui-black-color-50;
            text-decoration: none;
            border: 1px solid $cui-gray-color-border;
          }

          img {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }

  &.black-mode {
    position: absolute;
    width: 100%;
    height: 80px;

    .black-mode-container {
      position: absolute;
      background: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(10px);
      z-index: 1;

      .header-container {
        .navbar-container {
          li {
            color: $cookunity-white;
            opacity: 0.5;
          }
        }

        .login-link-cui {
          .cui-link {
            border-radius: 200px;
            border: none;
            background: $cui-black-color-50;
            padding: 8px 20px;
            width: fit-content;
            height: 40px;
          }
        }
      }
    }
  }
}

.sticky-navbar {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $cookunity-white;
  z-index: 12;
  border-bottom: 1px solid $cui-black-color-shadow;

  .header-container {
    .buttons-end {
      display: flex;
      gap: 12px;

      .login-link-cui.homepage {
        .cui-link {
          border-radius: 200px;
          border: 1px solid $cui-gray-color-border;
          background: $cui-black-color-50;
          padding: 8px 20px;
          width: fit-content;
          height: 40px;
        }
      }

      .navbar-sticky-menu {
        display: flex;
      }

      .started-button {
        display: none;
        @include mobile {
          display: inline;
        }
      }
    }
  }

  &.open-menu {
    .header-container .buttons-end .started-button {
      display: inline;
    }

    .black-mode-container {
      @include mobile {
        background-color: $cui-black-color;
      }
    }
  }

  &.black-mode {
    background-color: transparent;
  }
}

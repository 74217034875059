@import '../../scss/mixins';
@import '../../scss/variables';

.mealcard-new {
  cursor: pointer;
  min-width: 276px;
  width: 100%;
  height: 367px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05),
    0px 0px 2px 0px rgba(35, 31, 32, 0.1);
  padding: 16px 0 0;
  border-radius: 12px;
  position: relative;
  transition: all 0.2s ease-in-out;

  @include mobile {
    max-width: 100%;
    min-width: unset;
    margin: 0 auto;
    height: 353px;
  }

  &:hover {
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.07),
      0px 0px 12px rgba(35, 31, 32, 0.1);
  }

  &.with_shadow {
    background-repeat: no-repeat;
    background-size: cover;
    cursor: default;

    @media (max-width: 1200px) {
      background-size: cover;
    }

    &:hover {
      box-shadow: none;
    }
  }

  .mealcard-new-chef {
    display: flex;
    align-items: center;
    justify-content: center;

    &__image {
      width: 24px;
      height: 24px;
      margin-right: 4px;
      border-radius: 200px;
      object-fit: cover;
    }

    &__name {
      margin: 0;
      font-family: $cui-font-family-tt-norms;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }
  }

  .cui-image {
    width: 100%;
    height: 239px;

    @include mobile {
      width: 100%;
      height: 226px;
    }
  }

  .mealcard-new-description {
    padding: 8px 14px 12px;
    text-align: center;
    color: $cui-black-color-300;
    font-family: $cui-font-family-tt-norms;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 88px;

    @media (max-width: 390px) {
      height: 108px;
    }

    @media (min-width: 590px) and (max-width: 1200px) {
      height: 108px;
    }

    b {
      display: block;
      color: $cui-black-color;
      font-family: $cui-font-family-tt-norms;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .mealcard-new-shadow {
    display: none;
  }

  &.with_shadow {
    .mealcard-new-chef {
      &__image {
        z-index: 1;
      }
      &__name {
        color: $cookunity-white;
        z-index: 1;
      }
    }
    .mealcard-new-description {
      color: $cui-black-color-300;
      z-index: 1;
      position: absolute;
      width: 100%;
      bottom: 0;

      b {
        color: $cookunity-white;
      }
    }
    .mealcard-new-shadow {
      display: block;
      flex-shrink: 0;
      position: absolute;
      width: 100%;

      &__bottom {
        height: 117px;
        bottom: 0;
        border-radius: 0px 0px 12px 12px;

        &__black {
          background: linear-gradient(
            180deg,
            rgba(28, 28, 28, 0) 0%,
            rgba(28, 28, 28, 0.79) 50.52%,
            $cui-black-color-700 100%
          );
        }

        &__salmon {
          background: linear-gradient(
            180deg,
            rgba(211, 131, 125, 0) 0%,
            #d4847e 66.22%
          );
        }

        &__green {
          background: linear-gradient(
            180deg,
            rgba(142, 166, 112, 0) 0%,
            #6d914a 77.08%
          );
        }

        &__brown {
          background: linear-gradient(
            181deg,
            rgba(30, 1, 47, 0) 0.44%,
            #1d002e 50.04%
          );
        }
      }

      &__top {
        height: 86px;
        top: 0;
        border-radius: 12px 12px 0px 0px;

        &__black {
          background: linear-gradient(
            360deg,
            rgba(28, 28, 28, 0) 0%,
            rgba(28, 28, 28, 0.88) 100%
          );
        }

        &__salmon {
          background: linear-gradient(
            360deg,
            rgba(211, 131, 125, 0) 0%,
            #d4847e 100%
          );
        }

        &__green {
          background: linear-gradient(
            360deg,
            rgba(142, 166, 112, 0) 0%,
            #6d914a 92.71%
          );
        }

        &__brown {
          background: linear-gradient(
            360deg,
            rgba(30, 1, 47, 0) 0%,
            #1d002e 100%
          );
        }
      }
    }

    &.shadow-salmon,
    &.shadow-green {
      .mealcard-new-description {
        color: $cookunity-white;
      }

      .mealcard-new-shadow {
        &__black {
          border-radius: 0px;
        }
      }
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

.container-cui.green-cards {
  max-width: 1110px;

  .card-with-children {
    width: 345px;
    margin: 20px 0;

    &.fix-height {
      height: 464px;
    }

    .title {
      text-align: left;
    }

    @include mobile {
      max-width: 90%;
      height: 100%;
    }
  }
}

.container-cui-row.container-cui-row-cards {
  gap: 21px;
  margin: 30px auto;
  align-items: flex-start;

  @include mobile {
    flex-direction: column;
  }

  &.partners-icons {
    justify-content: center;
    gap: 16px;

    .card-container {
      @include mobile {
        margin: 0 auto;
      }

      p {
        font-family: $cui-font-family-tt-norms;
        font-size: 12px;
        font-style: normal;
        font-weight: 450;
        line-height: 16px;
        margin: 11px 0 0;
        text-align: center;
      }
    }
  }
}

.card-with-children {
  min-width: 345px;
  width: 100%;
  height: auto;
  padding: 40px 32px;
  border-radius: 12px;
  background-color: $cookunity-white;
  border-radius: 12px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05),
    0px 0px 2px 0px rgba(35, 31, 32, 0.1);
  margin: 0;

  @media (min-width: 768px) and (max-width: 1090px) {
    max-width: 100%;
    min-width: unset;
  }

  @include mobile {
    min-width: unset;
    margin: 0 auto;
  }

  .icon {
    margin-bottom: 12px;
    width: 50px;
    height: 50px;
  }

  p,
  li {
    font-family: $cui-font-family-tt-norms;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
  }

  &.without-border {
    box-shadow: none;
    padding: 0 32px;
  }

  &.green-partners {
    width: 244px;
    min-width: unset;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-width {
      width: 68%;
    }
  }

  &.our-impact {
    height: 320px;
    h3 {
      text-align: left;
    }
    @include mobile {
      height: 100%;
      max-width: 90%;
    }

    @media (min-width: 768px) and (max-width: 945px) {
      height: 420px;
    }
  }
}

.enhanced_slider {
  .slick-list {
    overflow: visible;
  }

  &.arrows-bottom {
    margin-bottom: 98px;

    .slick-prev,
    .slick-next {
      width: 48px;
      height: 48px;
      border-radius: 200px;
      background: $cookunity-white;
      opacity: 1;
      border: $cookunity-white;

      @include mobile {
        display: none !important;
      }
    }

    .slick-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 2px 2px 0px rgba(35, 31, 32, 0.05),
        0px 0px 2px 0px rgba(35, 31, 32, 0.1);
      z-index: 9;

      &.slick-prev:before,
      &.slick-next:before {
        content: '';
      }

      &.slick-next {
        img {
          transform: rotate(180deg);
        }
      }

      &:hover {
        opacity: 1;
        color: $cookunity-white;
        background-color: $cookunity-white;
      }

      &.slick-next:focus,
      &.slick-next:hover,
      &.slick-prev:focus,
      &.slick-prev:hover {
        opacity: 1;
        color: $cookunity-white;
        background-color: $cookunity-white;
      }
    }

    .slick-arrow {
      border-radius: 100px;
      border: 1px solid rgba(0, 0, 0, 0.05);
      background: rgba(0, 0, 0, 0.05);
      backdrop-filter: blur(10px);
      width: 32px;
      height: 32px;
      padding: 4px;
      top: 580px;
    }

    .slick-arrow.slick-prev {
      left: 47%;

      @media (max-width: 1300px) {
        left: 46%;
      }
      @media (max-width: 1000px) {
        left: 45%;
      }
    }

    .slick-arrow.slick-next {
      right: 47%;

      @media (max-width: 1300px) {
        right: 46%;
      }
      @media (max-width: 1000px) {
        right: 45%;
      }
    }
  }

  &.dark-mode {
    .slick-arrow {
      top: 112%;
      width: 32px;
      height: 32px;
      border-radius: 100px;
      border: 1px solid rgba(255, 255, 255, 0.15);
      background: rgba(255, 255, 255, 0.15);
      padding: 4px;

      @include mobile {
        display: none;
      }
    }

    .slick-next {
      left: 48%;

      @media (max-width: 1300px) {
        left: 46%;
      }
      img {
        transform: rotate(180deg);
      }
    }
    .slick-prev {
      left: 44%;

      @media (max-width: 1300px) {
        left: 42%;
      }
    }

    .slick-disabled {
      opacity: 0.5;
    }

    .slick-prev:before,
    .slick-next:before {
      content: '';
    }
  }
}

@import '../../scss/variables';

#ArticlesListLayout {
  h1,
  h2 {
    font-family: $cui-font-family-rossanova;
  }

  padding: 40px 0;
  background-color: $cookunity-black;
  color: $cookunity-white;

  p {
    color: $cookunity-white;
  }

  hr {
    border: 1px solid;
    border-bottom: 0;
    margin: 40px 0;
  }

  .row {
    justify-content: space-between;
    column-gap: 10px;
    flex-wrap: nowrap;

    @media (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }

  .row > .col {
    flex: initial;
    @media (max-width: 767px) {
      margin: 0;
    }
  }

  .featuredImage,
  .listImage {
    border-radius: 8px;
    min-width: 230px;
  }

  .listImage {
    max-height: 160px;
    object-fit: cover;
    @media (max-width: 767px) {
      width: 100%;
      max-height: initial;
    }
  }

  article {
    &.featuredArticle {
      @media (min-width: 900px) {
        h2 {
          font-size: 36px;
        }
      }
    }

    &.listedArticle {
      @media (min-width: 768px) {
        h2 {
          margin-top: 0px;
        }
      }
    }

    p {
      font-family: $cui-font-family-tt-norms;
      font-size: 16px;
    }
  }

  .pagination {
    text-align: center;
    font-family: $cui-font-family-tt-norms;
    margin: 40px 0;

    a {
      margin: 0 10px;
    }
  }
}

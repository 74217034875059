@import '../../../scss/variables.scss';

.cui-card {
  background: $cookunity-white;
  box-shadow: 0px 0px 12px rgba(35, 31, 32, 0.1),
    0px 12px 12px rgba(0, 0, 0, 0.07);
  border-radius: $cui-card-border-radius;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: $cui-font-family-tt-norms;
  font-size: $cui-font-sm;
  line-height: $cui-line-height-xl;
  font-weight: $cui-font-normal;
}

@import '../../scss/mixins';
@import '../../scss/variables';

.hero-vertical-slider {
  padding: 0 20px;
  background-color: $cookunity-white;
  overflow: hidden;
  background: $cui-black-color-250;

  &__container {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      flex-direction: column;
      padding-top: 40px;
    }
  }

  &__text-column {
    flex: 1;
    position: relative;
    width: 100%;

    h1 {
      margin: 0 0 48px;
      color: $cui-black-color-850;
      font-family: $cui-font-family-rossanova;
      font-size: 56px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: -1.92px;
      width: 549px;

      @include mobile {
        width: 100%;
        font-size: 40px;
        letter-spacing: -1.2px;
        margin: 0 0 24px;
        text-align: center;
      }

      span {
        color: $cui-yellow-color-550;
      }
    }

    p.promo {
      margin: 0 0 12px;
      font-family: $cui-font-family-tt-norms;
      font-size: $cui-font-sm;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: -0.14px;
      color: $cui-olive-green-color-500;
      text-transform: lowercase;

      @include mobile {
        text-align: center;
      }
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }

  &__image-columns-container {
    display: flex;
    gap: 8px;
    position: relative;

    @include mobile {
      width: 100%;
      margin-top: 38px;
    }

    @media (min-width: 640px) and (max-width: 768px) {
      width: 80%;
      margin-top: 38px;
    }

    .image-column {
      display: flex;
      flex-direction: column;
      height: 542px;
      overflow: hidden;
      position: relative;
      width: 303px;

      @include mobile {
        height: 300px;
        width: 100%;
      }

      @include mobile {
        height: 300px;
        width: 100%;
      }

      .image-slider,
      .image-slider-reverse {
        display: flex;
        flex-direction: column;
        gap: 22px;
        position: absolute;
        top: 0;
        left: 0;
        margin: 8px;
        width: 94%;

        @include mobile {
          width: 92%;
        }

        img,
        video {
          width: 114%;
          height: 432px;
          object-fit: cover;
          border-radius: 12px;
          box-shadow: 0px 30px 8px 0px rgba(0, 0, 0, 0),
            0px 19px 8px 0px rgba(0, 0, 0, 0.02),
            0px 11px 6px 0px rgba(0, 0, 0, 0.08),
            0px 5px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 3px 0px rgba(0, 0, 0, 0.16);

          @include mobile {
            width: 100%;
            height: auto;
          }
        }
      }

      .image-slider {
        animation: scrollUp 15s linear infinite;
      }

      .image-slider-reverse {
        animation: scrollDown 15s linear infinite;
      }
    }

    .background {
      display: none;
      @include mobile {
        display: inline;
        background: linear-gradient(
          180deg,
          $cookunity-white 4.84%,
          rgba(252, 252, 252, 0) 36.47%
        );
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0px;
        z-index: 1;
        border-left: 6px solid #f8f8f8;
        border-right: 6px solid #f8f8f8;
      }
    }

    .background {
      display: none;
      @include mobile {
        display: inline;
        background: linear-gradient(
          180deg,
          $cookunity-white 4.84%,
          rgba(252, 252, 252, 0) 36.47%
        );
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0px;
        z-index: 1;
      }
    }
  }
}

@keyframes scrollUp {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-50%);
  }
}

@keyframes scrollDown {
  0% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(-0%);
  }
}

@import '../../../../../scss/mixins';
@import '../../../../../scss/variables';

.quiz-carousel-container {
  display: flex;
  flex-direction: column;

  > h3 {
    font-size: 16px;
    font-family: $cui-font-family-tt-norms;
    margin: 0;
    text-align: left;
  }

  > p {
    font-family: $cui-font-family-tt-norms;
    color: $cui-black-color-300;
    font-weight: 450;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .slick-list {
    margin: -5px;
    padding: 5px;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 30px;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }

  .slick-track {
    display: flex !important;
    gap: 8px;
  }

  .slick-slide {
    padding: 0;
  }

  .quiz-card-wrapper {
    position: relative;
    display: flex !important;
    cursor: pointer;
    width: max-content;
    min-width: 90px;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0px 0px 2px rgba(35, 31, 32, 0.1),
      0px 2px 2px rgba(0, 0, 0, 0.05);
    height: 92px;
    transition: background-color 0.2s;
    background-color: $cookunity-white;

    &.active {
      background-color: $cui-wine-color-500;

      &:hover {
        background-color: $cui-wine-color-700;
      }

      .quiz-cui__checkbox__option--img {
        svg > path {
          fill: $cookunity-white;
        }
      }
      > .quiz-cui__checkbox__option--text {
        color: $cookunity-white;
      }
    }

    &:hover {
      background-color: $cui-black-color-100;
    }

    .quiz-cui__checkbox__option--img {
      margin-bottom: 8px;
    }

    .cui-icon {
      position: absolute;
      top: 20px;
      width: 45px;
    }

    .quiz-cui__checkbox__option--text {
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      overflow-wrap: break-word;
      position: absolute;
      top: 60px;
    }

    &:not(:has(.cui-icon)) {
      .quiz-cui__checkbox__option--text {
        top: 30px;
      }
    }
  }

  &.dark-mode {
    > h3 {
      color: $cookunity-white;
    }

    .slick-list {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100px;
        transform: translateX(4px);
        background: linear-gradient(
          to right,
          rgba(35, 31, 32, 0) 0%,
          rgba(35, 31, 32, 1) 90%
        );
      }
    }

    .quiz-card-wrapper {
      &.active {
        background-color: $cui-yellow-color-500;

        &:hover {
          background-color: $cui-yellow-color-600;
        }

        .quiz-cui__checkbox__option--img {
          svg > path {
            fill: $cookunity-black;
          }
        }
        > .quiz-cui__checkbox__option--text {
          color: $cookunity-black;
        }
      }
    }
  }
}

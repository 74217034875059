@import '../../scss/mixins';
@import '../../scss/variables';

.zip-btn {
  background: $cui-black-color;
  border-radius: 200px;
  border: 0;
  height: 48px;
  padding: 14px 34px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .arrow-icon {
    position: relative;
    -webkit-transition: -webkit-transform 0.5s ease;
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.3s ease;
    vertical-align: middle;
    width: 16px;
    height: 14px;
    margin-left: 12px;
  }

  &:hover {
    .arrow-icon {
      transform: translate3d(10px, 0, 0);
    }
  }
}

.zip-btn .btn-p {
  font-family: $cui-font-family-tt-norms;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  flex: 1;
  margin: 0;
}

.zip-btn .btn-p.btn-p-white {
  color: $cookunity-white;
}

.zip-btn .btn-p.btn-p-black {
  color: $cookunity-black;
}

@import '../../scss/mixins';
@import '../../scss/variables';

.rebrand.black-background {
  background-color: $cui-black-color;

  h2.rebrand,
  p.rebrand,
  p {
    color: $cookunity-white;
  }

  .container-cui-row .col-media {
    height: unset;
  }

  .container-cui-row .col-text {
    padding: 0;

    .container-cui-row {
      align-items: flex-start;

      .col {
        max-width: 200px;
        width: 100%;
        margin-left: 10px;

        .number {
          font-family: $cui-font-family-rossanova;
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: 44px;
          margin: 0 0 8px;
        }

        p {
          font-family: $cui-font-family-tt-norms;
          font-size: 16px;
          font-style: normal;
          font-weight: 450;
          line-height: 24px;
          margin: 0px;
        }

        .bottom-text {
          display: inline-table;
          font-size: 12px;

          a {
            color: $cui-olive-green-color-500;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
          }
        }
      }
    }
  }
}

.rebrand.black-background.padding {
  padding: 42px 0;

  .container-cui-row {
    justify-content: center;

    .col-media {
      height: 100%;
    }
  }
}

.rebrand.black-background.green-page {
  margin-top: 50px;

  .container-cui-row {
    flex-direction: row-reverse;

    .col-text {
      margin: 0 auto;
    }

    @media (min-width: 921px) {
      .col-media {
        max-width: 50%;
      }
    }

    @media (max-width: 920px) {
      flex-direction: column-reverse;

      .col-text {
        margin: 0;
      }
    }
  }
}

.rebrand.black-background.green-page
  .container-cui-row
  .col-text
  .container-cui-row {
  @media (max-width: 920px) {
    flex-direction: column;
    text-align: center;
    margin: 20px 0px;
  }

  .col {
    @media (max-width: 920px) {
      margin: 0 auto;
      text-align: center;
    }

    .bottom-text {
      display: inline-table;
      font-size: 12px;
      margin-top: 40px;

      @media (max-width: 920px) {
        margin-top: 18px;
        margin-bottom: 24px;
      }

      a {
        color: $cui-olive-green-color-500;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
      }
    }
  }
}

.rebrand.black-background.padding-space {
  padding: 57px 0;

  .container-cui-row {
    justify-content: space-between;
  }
}

.container-cui-row {
  align-items: center;
  justify-content: space-between;
  max-width: 1110px;

  @include mobile {
    flex-direction: column-reverse;
  }

  .col-text,
  .col-media {
    max-width: 500px;
    width: 100%;
  }

  .col-text {
    @media (min-width: 767px) and (max-width: 1200px) {
      max-width: 40%;
      margin-right: 10px;
    }

    @media (max-width: 1200px) {
      padding: 0 20px;
    }
  }

  .col-media {
    height: 267px;

    @include mobile {
      margin-bottom: 32px;
    }

    img {
      height: 100%;

      @media (min-width: 767px) and (max-width: 1200px) {
        object-fit: cover;
      }

      @include mobile {
        margin: 0 auto;
        object-fit: cover;
      }
    }

    .video-background {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      max-width: 653px;

      @media (max-width: 920px) {
        position: relative;
        padding: 0;
        width: 100%;
        max-width: 100%;
        height: 303px;
      }

      .video-mask {
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
      }

      video {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media (max-width: 920px) {
          width: 100%;
        }
      }

      .video-controller {
        position: relative;
        top: calc(100% - 70px);
        z-index: 1;
        margin: 0 auto;
        width: 100%;
        max-width: 1200px;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;

        @media (max-width: 920px) {
          position: absolute;
          text-align: left;
        }

        img {
          width: 42px;
          cursor: pointer;
          display: inline-block;
        }
      }
    }

    &.full-image {
      height: 100%;
      margin: 0;

      @include mobile {
        margin-top: 32px;
      }
    }
  }
}

.container-cui-row.reverse {
  flex-direction: row-reverse;

  @include mobile {
    flex-direction: column-reverse;
  }

  .col-text {
    @media (min-width: 767px) and (max-width: 1200px) {
      margin-left: 10px;
    }
  }
}

.container-cui-row.reverse-mobile {
  @include mobile {
    flex-direction: column;
  }

  .col-media {
    .video-background {
      @include mobile {
        margin-top: 32px;
      }
    }
  }
}

.rebrand.carreers-video {
  padding: 0 97px;
  max-width: 1306px;
  margin: 63px auto;

  @include mobile {
    padding: 63px 0 0;

    .col-text {
      margin-bottom: 32px;
    }

    .col-media {
      margin-bottom: 0;
      height: 100%;

      .video-background {
        margin-top: 0;

        video {
          object-fit: contain;
        }
      }
    }
  }
}

.b2b-home {
  background-color: $cui-black-color-600;
  padding: 97px 0;

  @include mobile {
    padding: 0 0 127px;
    .container-cui-row {
      flex-direction: column-reverse !important;
    }
  }

  .container-cui-row {
    max-width: 1110px !important;
  }

  .small {
    color: $cui-black-color-300;
  }

  h3 {
    margin: 4px 0;
    color: $cookunity-white;
    text-align: left;
  }

  p {
    color: $cookunity-white;
  }

  .cui-link {
    margin-top: 27px;
  }
}

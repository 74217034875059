@import '../../scss/mixins';
@import '../../scss/variables';

.cards-slider-cui {
  min-width: 1200px;
  max-width: 1200px;

  @include mobile {
    max-width: 100%;
    min-width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    max-width: 100%;
    min-width: unset;
  }

  p.tag {
    color: $cui-wine-color-500;
    font-family: $cui-font-family-tt-norms;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
  }

  .title {
    font-family: $cui-font-family-tt-norms;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
  }

  p.text {
    /* Common/Body M/Regular */
    font-family: $cui-font-family-tt-norms;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 24px;
    text-align: left;
    margin: 0;
    color: $cui-black-color-400;
  }

  p.text-2 {
    margin-top: 12px;
  }

  .list {
    li {
      color: $cui-black-color-400;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: $cui-font-family-tt-norms;
      font-size: 14px;
      font-style: normal;
      font-weight: 450;
      line-height: 20px;
      text-align: left;
    }
  }

  padding: 0 0 50px;
  margin: 0 auto;

  .slick-prev,
  .slick-next {
    width: 48px;
    height: 48px;
    border-radius: 200px;
    background: $cookunity-white;
    opacity: 0.75;
    border: $cookunity-white;

    @include mobile {
      display: none;
    }
  }

  .slick-arrow {
    display: flex !important;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 2px 0px rgba(35, 31, 32, 0.05),
      0px 0px 2px 0px rgba(35, 31, 32, 0.1);
    z-index: 9;

    &.slick-prev:before,
    &.slick-next:before {
      content: '';
    }

    &.slick-next {
      img {
        transform: rotate(180deg);
      }
    }

    &:hover {
      opacity: 1;
      color: $cookunity-white;
      background-color: $cookunity-white;
    }

    &.slick-next:focus,
    &.slick-next:hover,
    &.slick-prev:focus,
    &.slick-prev:hover {
      opacity: 1;
      color: $cookunity-white;
      background-color: $cookunity-white;
    }
  }

  .slick-list {
    padding: 0 0 36px 0;
  }

  &__black-mode {
    background-color: $cui-black-color-600;

    ul.slick-dots {
      bottom: 20px;
      position: relative;
      width: 100%;
    }

    .slick-dots li {
      margin: 0;
    }

    .slick-dots li button:before {
      color: $cookunity-white;
      font-size: 36px;
    }

    .slick-dots li.slick-active button:before {
      color: $cui-yellow-color-600;
      opacity: 0.75;
      font-size: 40px;
    }
  }

  .meal-card {
    cursor: pointer;
  }

  .slick-slide {
    margin-bottom: 20px;
    padding: 0 8px;

    > div > div > div:nth-child(2) > div:nth-child(2) {
      line-height: 1.3;
    }
  }

  .slick-dots {
    bottom: -20px;
  }

  .slick-dots li.slick-active button:before {
    color: $cui-yellow-color-600;
  }

  .slick-dots li {
    margin: 0 0;
  }

  .slick-track {
    width: 9000px !important;
  }

  .container-button-bottom {
    margin-top: 0;
  }

  &.slim-cards {
    width: 100%;
    min-height: 380px;
    max-width: 1080px;
    min-width: unset;
  }

  &.last-articles {
    .figure-image {
      max-width: 100%;
      min-width: 100%;
      margin: 0;
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';

@keyframes delayDisplay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.hero-background-cui {
  background-color: $cui-black-color-600;
  background-position: center;
  background-size: cover;
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  .container-cui {
    padding: 124px 0 124px;
    @media (max-width: 920px) {
      padding: 82px 20px;
    }
  }

  .content-left {
    max-width: 730px;
    margin: 0;
    text-align: left;
    margin-left: 98px;
    position: relative;

    @media (max-width: 920px) {
      max-width: 100%;
      margin: 0 auto;
      text-align: center;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media (min-width: 920px) and (max-width: 1200px) {
      margin-left: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    h1.rebrand,
    h2.rebrand,
    h3.rebrand {
      margin: 0;
      color: $cookunity-white;

      span {
        color: $cui-yellow-color-500;
      }
    }

    h1.rebrand span.rossanova {
      font-family: $cui-font-family-rossanova;
      font-size: 64px;

      @include mobile {
        font-size: $cui-font-4xl;
        line-height: 44px;
      }
    }

    p.rebrand {
      color: $cookunity-white;
    }

    p.rebrand.small.normal {
      margin-top: 6px;
    }

    .value-container {
      margin-top: 32px;

      p.rebrand,
      p.rebrand.lead {
        color: $cookunity-white;
        text-align: left;
      }
    }

    h2.rebrand.avallon {
      font-family: $cui-font-family-avallon;
      font-style: normal;
      font-weight: 400;
      font-size: 90px;
      line-height: 50%;
      display: flex;
      align-items: center;
      letter-spacing: 1px;
      color: $cui-black-color-100;
      margin-bottom: 25px;

      @include mobile {
        font-size: 50px;
        line-height: 25px;
      }

      p {
        margin-right: 20px;
      }
    }

    form {
      margin-top: 20px;

      @media (max-width: 920px) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }

      input {
        color: $cookunity-white;
      }

      input::-webkit-input-placeholder {
        color: $cookunity-white;
      }
    }

    .chefDescription p {
      margin-right: 12px;

      @include mobile {
        margin-right: 0;
      }
    }
  }
}

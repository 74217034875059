@import '../../../../scss/mixins';
@import '../../../../scss/variables';

.chef-review-lp.without-review {
  @include mobile {
    padding: 56px 0 28px;
  }
}

.chef-review-lp {
  padding: 25px 0;

  @include mobile {
    padding: 86px 0 28px;
    margin-top: -68px;
  }

  .reviewCountContainer .columnContainer .small {
    margin: 12px 0 0;
  }

  h4 {
    font-family: $cui-font-family-reinata;
    font-style: normal;
    font-weight: normal;
    letter-spacing: -0.764178px;
    color: $cookunity-white;
    font-weight: 400;
    font-size: 82px;
    line-height: 70%;
    margin-bottom: 24px;
    margin-top: 0;

    @include mobile {
      font-size: 78px;
    }
  }

  .by-chef {
    font-family: $cui-font-family-tt-norms;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $cui-black-color-200;
  }

  .lead {
    color: $cookunity-white;
  }

  .reviewCountContainer {
    h4 {
      font-family: $cui-font-family-tt-norms;
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 44px;
    }
  }

  .container .row .multiple-images-container {
    display: flex;
    justify-content: space-between;
    min-width: 720px;
    height: 409px;
    width: 100%;

    @media (max-width: 1400px) {
      min-width: 100%;
    }

    @include mobile {
      min-width: 100%;
      height: 255px;
    }

    .multiple-images {
      background-repeat: no-repeat;
      background-position: center;
      width: 22%;

      @include mobile {
        background-size: cover;
        height: 255px;
      }
    }
  }

  .container .row > .col {
    width: 50%;

    @include mobile {
      width: 100%;
    }
  }

  .container .row {
    margin: 0;
    align-items: center;

    .col-image {
      @include mobile {
        display: flex;
        justify-content: center;
      }
    }
  }

  .container {
    padding: 0;

    .content {
      margin: 0;
      padding: 0 23px;
    }
  }
}
